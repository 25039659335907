import { ReactNode } from 'react'
import { Duty } from 'types/HazardClassCase'
import { ColorPalettes, EventBase, Palette, PaletteType } from 'types/interfaces'
import Scenario from 'types/Scenario'
import Schedule from 'types/Schedule'

export interface PalettedValue {
    backColor: string
    foreColor: string
    node: ReactNode
}

const getBlankNode = (): PalettedValue => {
    return {
        backColor: 'transparent',
        foreColor: 'black',
        node: <span />,
    }
}

const getColorFromPalette = (metricsContainer: any, metricName: string, palettes: ColorPalettes): PalettedValue => {
    let paletteType: PaletteType = 'none'

    // Pick the right palette here based on the metric name.
    // This is somewhat brittle.
    const metricNameLower = metricName.toLowerCase()
    if (metricNameLower.startsWith('effectiveness')) {
        paletteType = 'effectiveness'
    } else if (metricNameLower.startsWith('reservoir')) {
        paletteType = 'reservoir'
    } else if (metricNameLower.startsWith('percent')) {
        paletteType = 'percentage'
    } else if (metricNameLower.includes('excessive')) {
        paletteType = 'excessive'
    }

    return getColoredNode(metricsContainer, metricName, paletteType, palettes)
}

const getColoredNode = (
    metricsContainer: any,
    metricName: string,
    paletteType: PaletteType,
    palettes: ColorPalettes,
): PalettedValue => {
    const metricValue = metricsContainer[metricName] as number
    const backColor = metricValue ? getColorFromPaletteForMetric(paletteType, metricValue, palettes) : ''
    const foreColor = metricValue && metricValue > 0 ? getContrastingForeColor(backColor) : '#aaa'
    return {
        backColor,
        foreColor,
        node: (
            <span>
                {metricValue?.toLocaleString(undefined, {
                    minimumFractionDigits: 1, // sets the number of decimal places
                    maximumFractionDigits: 1,
                })}
            </span>
        ),
    }
}

export const getColorFromPaletteForMetric = (
    type: PaletteType,
    metricValue: number,
    colorPalettes: ColorPalettes,
): string => {
    if (!colorPalettes.hasParameterSet) {
        throw new Error('Color palettes not set with parameter values')
    }
    let palette: Palette[] = []

    if (type === 'effectiveness') {
        palette = colorPalettes.effectiveness
    } else if (type === 'reservoir') {
        palette = colorPalettes.reservoir
    } else if (type === 'percentage') {
        palette = colorPalettes.percentage
    } else if (type === 'insights') {
        palette = colorPalettes.insights
    } else if (type === 'excessive') {
        palette = colorPalettes.excessive
    } else if (type === 'none') {
        return 'transparent'
    }

    let color = ''
    for (let idx = 0; idx < palette.length; idx++) {
        const threshold = palette[idx].threshold
        // This will make sure the default is caught and used
        color = palette[idx].color
        if (metricValue > threshold) {
            break
        }
    }

    return color
}

const getReportDrilldownMetricField = (
    scenario: Scenario,
    palettes: ColorPalettes,
    metricName: string,
    metricType: PaletteType,
    reportDrilldownRecord: any,
): PalettedValue => {
    if (scenario.hasAllFailedSchedules || scenario.allNonCrewing) {
        return getBlankNode()
    }

    return getColoredNode(reportDrilldownRecord, metricName, metricType, palettes)
}

const getScenarioMetricField = (scenario: Scenario, metricName: string, palettes: ColorPalettes): PalettedValue => {
    if (!scenario.metrics || scenario.hasAllFailedSchedules || scenario.allNonCrewing) {
        return getBlankNode()
    }

    return getColorFromPalette(scenario.metrics, metricName, palettes)
}

const getScheduleMetricField = (schedule: Schedule, metricName: string, palettes: ColorPalettes): PalettedValue => {
    if (!schedule.metrics || schedule.allNonCrewing || schedule.isFailedSchedule) {
        return getBlankNode()
    }
    return getColorFromPalette(schedule.metrics, metricName, palettes)
}
const getScheduleEventMetricField = (
    palettes: ColorPalettes,
    scheduleEvent: EventBase,
    metricName: string,
): PalettedValue => {
    return getColorFromPalette(scheduleEvent, metricName, palettes)
}

const getHazardClassPatternDutyMetricField = (
    duty: Duty,
    metricName: string,
    metricType: PaletteType,
    palettes: ColorPalettes,
): PalettedValue => {
    return getColoredNode(duty, metricName, metricType, palettes)
}

const getContrastingForeColor = (backColor: string): string => {
    if (!backColor || backColor === '' || backColor === 'transparent') {
        return 'black'
    }

    const back = backColor.slice(0, 1) === '#' ? backColor.slice(1) : backColor

    // Convert to RGB value
    const r = parseInt(back.substring(0, 2), 16)
    const g = parseInt(back.substring(2, 4), 16)
    const b = parseInt(back.substring(4, 6), 16)

    if (r === undefined || g === undefined || b === undefined) {
        return 'black'
    }

    // Get YIQ ratio
    const yiq = (r * 299 + g * 587 + b * 114) / 1000

    // Check contrast
    return yiq >= 128 ? 'black' : 'white'
}

export {
    getBlankNode,
    getReportDrilldownMetricField,
    getScenarioMetricField,
    getScheduleMetricField,
    getScheduleEventMetricField,
    getHazardClassPatternDutyMetricField,
}
