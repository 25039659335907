import { Dispatch, ThunkAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import globals from 'services/global/globals'
import { palettesActions } from 'store/palettesStore'
import { RootState } from 'store/store'
import { ColorPalettes } from 'types/interfaces'
import { SystemSetting } from 'types/SystemSetting'

const findSettingByNameEnding = (settingName: string, colorSettings: SystemSetting[]): SystemSetting => {
    const setting = colorSettings.find((s) => s.name.endsWith(settingName))
    if (!setting) {
        throw new Error(`Setting '${settingName}' not found`)
    }
    return setting
}

const findColorValue = (settingName: string, colorSettings: SystemSetting[]): string => {
    return findSettingByNameEnding(settingName, colorSettings)?.value
}

const findThresholdValue = (settingName: string, colorSettings: SystemSetting[]): number => {
    return parseFloat(findSettingByNameEnding(settingName, colorSettings).value)
}

const settingsToPalette = (colorPaletteSettings: SystemSetting[]): ColorPalettes => {
    const palettes: ColorPalettes = {
        effectiveness: [
            {
                threshold: findThresholdValue('EffectivenessPercent2', colorPaletteSettings),
                color: findColorValue('EffectivenessAbove2', colorPaletteSettings),
            },
            {
                threshold: 77, // default, but needs to be overridden per scenario
                color: findColorValue('EffectivenessAboveThreshold', colorPaletteSettings),
            },
            {
                threshold: findThresholdValue('EffectivenessPercent1', colorPaletteSettings),
                color: findColorValue('EffectivenessAbove1', colorPaletteSettings),
            },
            {
                threshold: 0,
                color: findColorValue('EffectivenessAbove0', colorPaletteSettings),
            },
        ],
        reservoir: [
            {
                threshold: findThresholdValue('ReservoirPercent2', colorPaletteSettings),
                color: findColorValue('ReservoirAbove3', colorPaletteSettings),
            },
            {
                threshold: 75,
                color: findColorValue('ReservoirAbove2', colorPaletteSettings),
            },
            {
                threshold: findThresholdValue('ReservoirPercent1', colorPaletteSettings),
                color: findColorValue('ReservoirAbove1', colorPaletteSettings),
            },
            {
                threshold: 0,
                color: findColorValue('ReservoirAbove0', colorPaletteSettings),
            },
        ],
        percentage: [
            {
                threshold: 20, // default, but needs to be overridden per scenario
                color: findColorValue('PercentAbove3', colorPaletteSettings),
            },
            {
                threshold: findThresholdValue('PercentBreak2', colorPaletteSettings),
                color: findColorValue('PercentAbove2', colorPaletteSettings),
            },
            {
                threshold: findThresholdValue('PercentBreak1', colorPaletteSettings),
                color: findColorValue('PercentAbove1', colorPaletteSettings),
            },
            {
                threshold: 0,
                color: findColorValue('PercentAbove0', colorPaletteSettings),
            },
        ],
        insights: [
            {
                threshold: 80,
                color: '#92d050',
            },
            {
                threshold: 75,
                color: '#ffff00',
            },
            {
                threshold: 70,
                color: '#ffc000',
            },
            {
                threshold: 0,
                color: '#ee2a2a',
            },
        ],
        excessive: [
            {
                threshold: 0,
                color: 'red',
            },
        ],
    }
    return palettes
}

const fetchPalettes = (): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        if (state.palettes.palettes || state.palettes.isFetching) {
            // already loaded into redux, or is fetching from api right now
            // so don't kick off another fetch
            return
        }

        dispatch(palettesActions.setIsFetching())
        const colorPaletteSettings = await globals.getApi().getSystemSettingApi().getSystemSettings('Palettes')
        dispatch(palettesActions.setPalettes(settingsToPalette(colorPaletteSettings)))
    }
}

export default {
    fetchPalettes,
}
