import { Col, Form, Row } from 'react-bootstrap'
import { ProfileWork } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { MultiColumnFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface IWorkParametersProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileWork | undefined
    validatedForm: boolean
}
const BufferTypeEventsParameters = (props: IWorkParametersProps) => {
    const preparationMultiColumn = {
        labelText: 'Preparation',
        subText: 'An event that occurs before other duty and buffer events',
        labelId: 'prepareTime',
        invalidText: '',
        columns: [
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.prepTimeAtHome,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'prepTimeAtHome',
                    name: 'prepTimeAtHome',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.prepTimeAtHotel,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'prepTimeAtHotel',
                    name: 'prepTimeAtHotel',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.prepTimeAtRestFacility,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'prepTimeAtRestFacility',
                    name: 'prepTimeAtRestFacility',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
        ],
    }
    const commuteMultiColumn = {
        labelText: 'Commute',
        subText: 'Events that wrap duty events',
        labelId: 'commuteTime',
        invalidText: '',
        columns: [
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.commuteTimeAtHome,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'commuteTimeAtHome',
                    name: 'commuteTimeAtHome',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.commuteTimeAtHotel,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'commuteTimeAtHotel',
                    name: 'commuteTimeAtHotel',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.commuteTimeAtRestFacility,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'commuteTimeAtRestFacility',
                    name: 'commuteTimeAtRestFacility',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
        ],
    }

    const unwindMultiColumn = {
        labelText: 'Unwind',
        subText: 'An event that occurs after other duty and buffer events',
        labelId: 'prepareTime',
        invalidText: '',
        columns: [
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.unwindTimeAtHome,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'unwindTimeAtHome',
                    name: 'unwindTimeAtHome',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.unwindTimeAtHotel,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'unwindTimeAtHotel',
                    name: 'unwindTimeAtHotel',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: props.profile?.unwindTimeAtRestFacility,
                    onChange: props.onChange,
                    isInvalid: props.validatedForm,
                    id: 'unwindTimeAtRestFacility',
                    name: 'unwindTimeAtRestFacility',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                },
            },
        ],
    }

    return (
        <>
            <Form.Group>
                <Row>
                    <Col xs="5"> </Col>
                    <Col>
                        <h6 style={{ textAlign: 'center' }}>Home</h6>
                    </Col>
                    <Col>
                        <h6 style={{ textAlign: 'center' }}>Hotel</h6>
                    </Col>
                    <Col>
                        <h6 style={{ textAlign: 'center' }}>Rest Facility</h6>
                    </Col>
                </Row>
            </Form.Group>
            <MultiColumnFormRow {...preparationMultiColumn} />
            <MultiColumnFormRow {...commuteMultiColumn} />
            <MultiColumnFormRow {...unwindMultiColumn} />
        </>
    )
}

export default BufferTypeEventsParameters
