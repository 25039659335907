import { GridCellProps } from '@progress/kendo-react-grid'
import { Station } from 'types/interfaces'
import TransparentButton from 'views/Common/Buttons/TransparentButton'

export interface StationsNavigationLinkProps extends GridCellProps {
    stationClicked: (station: Station, dataIndex: number) => void
}

const StationsNavigationLink = (props: StationsNavigationLinkProps) => {
    const { stationClicked, dataItem: station } = props
    return (
        <td>
            <TransparentButton
                onClick={() => {
                    stationClicked(station, props.dataIndex)
                }}
            >
                {station[props.field!]}
            </TransparentButton>
        </td>
    )
}

export default StationsNavigationLink
