import { BaseDeterminationOnImportTypeNames, getRecordKeyValues } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow, InputSelectFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FilterParameters from './FilterParameters'

interface DataSourceFosPairingsProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceFosPairings = (props: DataSourceFosPairingsProps) => {
    return (
        <>
            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <FilterParameters profile={props.profile} onChange={props.onChange} validatedForm={props.validatedForm} />

            <InputFileFormRow
                labelText="FOS Pairings File"
                fieldId="dataFieldPath1"
                subText="Local path to a FOS file (.csv, .xls, .xlsx)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceFosPairings
