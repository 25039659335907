import { ProfileWork } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputNumericFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface IWorkParametersProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileWork | undefined
}
const BufferTypeFixedParameters = (props: IWorkParametersProps) => {
    return (
        <InputNumericFormRow
            labelText="Duration"
            fieldId="duration"
            subText="The minimum duration between sleep and work"
            value={props.profile?.bufferDuration}
            onChange={props.onChange}
            minValue={0}
            invalidText="Enter a value of 0 or greater"
            required={true}
        />
    )
}

export default BufferTypeFixedParameters
