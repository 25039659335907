import { ChangeEvent, FormEvent, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { TimeModeEnum } from 'types/interfaces'
import { DutyAutoWorkType, WorkTypes } from 'types/Metadata'
import { DutyWorkEventSelection } from 'types/Schedule'
import ScheduleEvent from 'types/ScheduleEvent'
import { DutyItemState } from 'types/UpdateDutyRequest'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

export type DutyDialogProps = {
    timeMode: TimeModeEnum
    dutyEvent: ScheduleEvent
    dutySelection: DutyWorkEventSelection
    dutyWorkTypes: DutyAutoWorkType[]
    closeCallback: (dialogResult: DialogResultEnum, updatedDutyItemsState?: DutyItemState[]) => void
}

const DutyDialogContent = (props: DutyDialogProps) => {
    const [dutySelection, setDutySelection] = useState({ ...props.dutySelection })

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        // send up the updated object
        const updatedDutyState = Object.keys(dutySelection).map<DutyItemState>((key: string) => {
            const workType = key as WorkTypes
            return {
                workType,
                state: dutySelection[workType].included ? 'NotCreatedForceCreate' : 'Deleted',
            }
        })

        props.closeCallback(DialogResultEnum.Completed, updatedDutyState)
    }

    const dutyStartMs = props.dutyEvent.getStartMsForDisplay(props.timeMode)
    const formattedDutyStartTime = dateTimeFormatting.formatDateTimeLongLocal(dutyStartMs, true)
    return (
        <Form noValidate onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Select Events to Include in this Duty</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>
                        {props.dutyEvent.label} ({formattedDutyStartTime})
                    </strong>
                </p>

                {props.dutyWorkTypes.map((workType) => {
                    return (
                        <Form.Check
                            id={`chk${workType.workType}`}
                            label={workType.label}
                            key={workType.label}
                            type="checkbox"
                            checked={dutySelection[workType.workType].included}
                            disabled={dutySelection[workType.workType].disabled}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setDutySelection((previous: DutyWorkEventSelection): DutyWorkEventSelection => {
                                    const updated = { ...previous }
                                    updated[workType.workType].included = e.target.checked
                                    return updated
                                })
                            }
                        />
                    )
                })}
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom isLarge type="submit" variant="primary">
                    OK
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const DutyDialog = (props: DutyDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <DutyDialogContent {...props} />
        </ModalWrapper>
    )
}

export default DutyDialog
