import { getRecordKeyValues, ZoneTypeNames } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow, InputSelectFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import CommonDataSourceParameters from './CommonDataSourceParameters'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface DataSourceCrewControlPairingsProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceCrewControlPairings = (props: DataSourceCrewControlPairingsProps) => {
    return (
        <>
            <CommonDataSourceParameters profile={props.profile} onChange={props.onChange} />

            <InputSelectFormRow
                labelText="Time Reference"
                subText="How the times in the data file should be interpreted"
                value={props.profile.timeReference}
                onChange={props.onChange}
                fieldId="timeReference"
                options={getRecordKeyValues(ZoneTypeNames)}
            />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />

            <InputFileFormRow
                labelText="Crew Control File"
                fieldId="dataFieldPath1"
                subText="Local path to a crew control file (.xls, .xlsx, .csv)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceCrewControlPairings
