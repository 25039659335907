import { SvgIcon } from '@progress/kendo-react-common'
import { Drawer, DrawerContent, DrawerItem, DrawerItemProps, DrawerSelectEvent } from '@progress/kendo-react-layout'
import { chevronDownIcon, chevronRightIcon, menuIcon, SVGIcon } from '@progress/kendo-svg-icons'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
    items: DrawerItemProps[]
    testId?: string
}
const SidePanel = ({ items, testId }: Props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [drawerExpanded, setDrawerExpanded] = useState<boolean>(true)
    const [drawerItems, setDrawerItems] = useState<DrawerItemProps[]>(items)
    const [content, setContent] = useState<JSX.Element>()
    const [selectedId, setSelectedId] = useState()

    useEffect(() => {
        const findItem = items.find((item) => item.route === location.pathname.replace(/\/$/, '')) // remove the end forward slash if exist
        if (findItem?.element) {
            setContent(findItem.element)
            setSelectedId(findItem.id)
        }
    }, [items, location])

    const onSelect = (e: DrawerSelectEvent) => {
        const currentItem = e.itemTarget.props
        if (currentItem.id === 0) return // Not handle title select event
        const isParent = currentItem.expanded !== undefined // Has child and expandable
        const newData = drawerItems.map((item) => {
            const isCurrentItem = currentItem.id === item.id
            return {
                ...item,
                expanded: isCurrentItem && isParent ? !currentItem.expanded : item.expanded,
            }
        })
        setDrawerItems(newData)
        if (currentItem.route) navigate(currentItem.route)
        else if (currentItem.element) setContent(currentItem.element)
        setSelectedId(currentItem.id)
    }

    const data = drawerItems.map((item) => {
        if (item.parentId !== undefined) {
            const parent = drawerItems.find((p) => p.id === item.parentId)
            if (item.id === selectedId && parent && parent.expanded === false) {
                parent.expanded = true
            }
            return {
                ...item,
                selected: item.id === selectedId,
                visible: parent?.expanded,
                style: { paddingLeft: '30px' },
            }
        }
        return {
            ...item,
            selected: item.id === selectedId,
        }
    })

    const CustomItem = (props: DrawerItemProps) => {
        if (props.visible === false) return <></>
        let endIcon: SVGIcon | undefined
        if (props.id === 0) endIcon = menuIcon
        else if (props.expanded === true) endIcon = chevronDownIcon
        else if (props.expanded !== undefined) endIcon = chevronRightIcon
        return (
            <DrawerItem {...props} data-testid={testId ? testId + props.id : undefined}>
                <SvgIcon icon={props.svgIcon} size="medium" />
                <span className="k-item-text">{props.text}</span>
                {endIcon && (
                    <SvgIcon
                        icon={endIcon}
                        size="medium"
                        style={{ position: 'absolute', right: 10 }}
                        onClick={() => {
                            if (props.id === 0) setDrawerExpanded(!drawerExpanded)
                        }}
                    />
                )}
            </DrawerItem>
        )
    }
    return (
        <div id="SidePanel" data-testid={testId ? testId + 'Container' : undefined}>
            <Drawer
                expanded={drawerExpanded}
                mode="push"
                mini
                position="start"
                items={data}
                item={CustomItem}
                onSelect={onSelect}
            >
                <DrawerContent>{content}</DrawerContent>
            </Drawer>
        </div>
    )
}

export default SidePanel
