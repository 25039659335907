import React from 'react'

interface FormContentProps {
    configHeight: number
    children: React.ReactNode
}

const FormContent = (props: FormContentProps) => {
    return (
        <div
            style={{
                marginBottom: 10,
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                height: props.configHeight - 40,
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <div
                style={{
                    maxWidth: '1200px',
                    margin: 'auto',
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

export default FormContent
