import { getRecordKeyValues, SplitDutyLocationTypeNames } from 'types/EnumTypes'
import { ProfileWork } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputNumericFormRow, InputSelectFormRow, MultiColumnFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'

interface IWorkParametersProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileWork | undefined
    validatedForm: boolean
}
const BreakWithSleepParameters = (props: IWorkParametersProps) => {
    const sleepDuringMultiColumn = {
        labelText: 'Sleep During',
        subText: 'Add sleep only if between the local time range',
        labelId: 'sleepDuring',
        labelColWidth: 5,
        invalidText: '',
        columns: [
            {
                colWidth: 3,
                colType: 'timePickerInput',
                timePickerProps: {
                    value: props.profile?.sleepDuringStart,
                    isInvalid: props.validatedForm,
                    id: 'sleepDuringStart',
                    name: 'sleepDuringStart',
                    onChange: props.onChange,
                },
            },
            {
                colWidth: 1,
                colType: 'text',
                value: 'to',
            },
            {
                colWidth: 3,
                colType: 'timePickerInput',
                timePickerProps: {
                    value: props.profile?.sleepDuringEnd,
                    isInvalid: props.validatedForm,
                    id: 'sleepDuringEnd',
                    name: 'sleepDuringEnd',
                    onChange: props.onChange,
                },
            },
        ],
    }

    return (
        <IndentedBlock headingText="">
            <InputNumericFormRow
                labelText="Min. Time at Hotel"
                subText="Minimum time before adding sleep"
                value={props.profile?.minBreakTimeAtHotel}
                onChange={props.onChange}
                fieldId="minBreakTimeAtHotel"
                minValue={0}
                invalidText="Enter a value of 0 or greater"
                required={true}
            />

            <InputNumericFormRow
                labelText="Min. Time at Rest Facility"
                subText="Minimum time before adding sleep"
                value={props.profile?.minBreakTimeAtRestFacility}
                onChange={props.onChange}
                fieldId="minBreakTimeAtRestFacility"
                minValue={0}
                invalidText="Enter a value of 0 or greater"
                required={true}
            />

            <InputSelectFormRow
                labelText="Sleep Preference"
                subText="If sleep at Hotel and Rest Facility both possible"
                value={props.profile?.sleepPreference}
                onChange={props.onChange}
                fieldId="sleepPreference"
                options={getRecordKeyValues(SplitDutyLocationTypeNames)}
            />

            <MultiColumnFormRow {...sleepDuringMultiColumn} />

            <InputNumericFormRow
                labelText="Min. Time during break"
                subText="Add sleep only if longer than"
                value={props.profile?.minSleepDuringBreak}
                onChange={props.onChange}
                fieldId="minSleepDuringBreak"
                minValue={0}
                invalidText="Enter a value of 0 or greater"
                required={true}
            />
        </IndentedBlock>
    )
}

export default BreakWithSleepParameters
