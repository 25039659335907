import { BaseDeterminationOnImportTypeNames, getRecordKeyValues } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    InputFileFormRow,
    InputSelectFormRow,
    InputTextFormRow,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface CrewTracPRGProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceCrewTracPRG = (props: CrewTracPRGProps) => {
    return (
        <>
            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <SwitchFormRow
                labelText="Combine Schedules With Operating Dates"
                subText="Schedules with identical events but operating on different dates will be combined"
                value={props.profile.combineSchedulesWithOperatingDates}
                onChange={props.onChange}
                fieldId="combineSchedulesWithOperatingDates"
            />

            <InputTextFormRow
                labelText="Complement Codes"
                fieldId="complementCodes"
                subText="Position Codes used when totalling complement"
                value={props.profile.complementCodes}
                onChange={props.onChange}
            />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />

            <InputFileFormRow
                labelText="Crew Trac PRG File"
                fieldId="dataFieldPath1"
                subText="Local path to a pairings file (.prg, .txt)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceCrewTracPRG
