import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { BaseInputProps } from './FormControlBase'

export interface FormControlSelectInputProps extends BaseInputProps {
    value?: string
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
    options?: string[] | { key: string; value: string }[]
}

const FormControlSelectInput = (props: FormControlSelectInputProps) => {
    const opt = props.options
    const options = opt?.map((item) => {
        if (typeof item === 'string') {
            return (
                <option key={item} value={item}>
                    {item}
                </option>
            )
        }
        return (
            <option key={item.key} value={item.key}>
                {item.value}
            </option>
        )
    })
    return (
        <Form.Select value={props.value} onChange={props.onChange} id={props.id} name={props.id}>
            {options}
        </Form.Select>
    )
}

export default FormControlSelectInput
