import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { Profile } from 'types/ProfileInterfaces'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import PageLayout from 'views/Common/Layout/PageLayout'
import DeleteConfirmationDialog from '../Components/DeleteConfirmationDialog'
import GridToolbarButton from '../Components/GridToolbarButton'
import getProfileListGridColumns from './ProfileGridConfig'

interface Props {
    displayName: string
    profileType: ProfileModuleTypes
}

const ProfileList = ({ displayName, profileType }: Props) => {
    const navigate = useNavigate()
    const [configHeight, setConfigHeight] = React.useState(780)
    const [selectedRowsState, setSelectedRowsState] = React.useState<SelectionState>({})
    const [showDialog, setShowDialog] = React.useState(<></>)
    const [gridData, setGridData] = React.useState<Profile[]>([])

    const profileApi = useCallback(() => globals.getApi().getProfileApi(), [])
    const baseUrl = useMemo(() => `/settings/profiles/${profileType}`, [profileType])
    useEffect(() => {
        const getDataAndSetLinks = async () => {
            try {
                const data = await profileApi().getProfileList(profileType)
                setGridData(data)
            } catch (err: any) {
                handleApiError(err)
            }
        }
        getDataAndSetLinks()
    }, [profileApi, profileType])

    const handleAddClick = () => {
        navigate(`${baseUrl}/new`)
    }
    const deleteData = async () => {
        await profileApi().deleteProfile(profileType, selectedIds)
        const data = await profileApi().getProfileList(profileType)
        setGridData(data)
        setSelectedRowsState({})
    }

    const handleCopyClick = () => {
        navigate(`${baseUrl}/${selectedIds[0]}/copy`)
    }

    const handleDeleteClick = () => {
        setShowDialog(
            <DeleteConfirmationDialog
                topic={displayName}
                numRows={selectedIds.length}
                onCloseDialog={async (dialogResult: DialogResultEnum) => {
                    if (dialogResult === DialogResultEnum.Completed) {
                        await deleteData()
                    }
                    setShowDialog(<></>)
                }}
            />,
        )
    }
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    return (
        <>
            <PageLayout
                headingContent={`Profiles - ${displayName}`}
                buttons={
                    <GridToolbarButton
                        items={gridData}
                        selectedIds={selectedIds}
                        onAddClick={handleAddClick}
                        onCopyClick={handleCopyClick}
                        onDeleteClick={handleDeleteClick}
                    />
                }
                onMainContentHeightChange={setConfigHeight}
            >
                <KendoGridCustom
                    localStorageKeyForColumnState="ProfilesGrid"
                    localStorageKeyForGridDataState="ProfilesGridDataState"
                    height={`${configHeight}px`}
                    data={gridData}
                    columns={getProfileListGridColumns(baseUrl)}
                    setColumnVisibility={(newColumnState: KendoGridColumn[]) => newColumnState}
                    selectedRowsState={selectedRowsState}
                    onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                />
            </PageLayout>
            {showDialog}
        </>
    )
}

export default ProfileList
