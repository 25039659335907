import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import RefreshButton, { RefreshButtonProps } from './RefreshButton'

const ReportRefreshButton = (props: Omit<RefreshButtonProps, 'recalculationState' | 'useSynchronousRefreshOnly'>) => {
    const synchronousOnly = useSelector<RootState, boolean | undefined>((x) => x.app.user?.refreshReportsSynchronously)
    return <RefreshButton {...props} useSynchronousRefreshOnly={synchronousOnly === true} />
}

export default ReportRefreshButton
