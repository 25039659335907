import { cloneDeep } from 'lodash'

export type MoveDirection = 'up' | 'down'

export const sortItemsByStringField = <T, K extends keyof T>(items: T[], sortField: K) => {
    items.sort((item1, item2) => {
        const item1Lower = (item1[sortField] as unknown as string).toLowerCase()
        const item2Lower = (item2[sortField] as unknown as string).toLowerCase()
        if (item1Lower < item2Lower) return -1
        if (item1Lower > item2Lower) return 1
        return 0
    })
}
export const sortItems = <T, K extends keyof T>(items: T[], sortField: K, descending?: boolean) => {
    const isLessVal = descending === true ? 1 : -1
    const isGreaterVal = descending === true ? -1 : 1
    items.sort((item1, item2) => {
        const item1Field = item1[sortField]
        const item2Field = item2[sortField]
        if (item1Field < item2Field) return isLessVal
        if (item1Field > item2Field) return isGreaterVal
        return 0
    })
}

export const getNewItemName = <T, K extends keyof T>(items: T[], itemNameField: K, defaultNewItemText: string) => {
    const itemNames = items.map((x) => x[itemNameField] as unknown as string)
    let newItemText = defaultNewItemText
    let counter = 1
    while (itemNames.includes(newItemText)) {
        newItemText = `${defaultNewItemText} ${counter++}`
    }
    return newItemText
}

export const reorderItemsList = <T extends { id: string; order: number }>(
    selectedItemIds: string[],
    items: T[],
    direction: MoveDirection,
) => {
    if (selectedItemIds.length <= 0) return items

    return reorderItems(selectedItemIds[0], items, direction)
}
export const reorderItems = <T extends { id: string; order: number }>(
    selectedItemId: string,
    items: T[],
    direction: MoveDirection,
) => {
    const itemsCopy = cloneDeep(items)
    const selectedItemIndex = itemsCopy.findIndex((r) => r.id === selectedItemId)

    // silently do nothing if we are trying to move the first item up or the last item down
    if (selectedItemIndex === 0 && direction === 'up') return itemsCopy
    if (selectedItemIndex === itemsCopy.length - 1 && direction === 'down') return itemsCopy

    if (selectedItemIndex === -1) throw new Error('Selected item not found')
    const step = direction === 'up' ? -1 : 1
    const temp = itemsCopy[selectedItemIndex + step].order
    itemsCopy[selectedItemIndex + step].order = itemsCopy[selectedItemIndex].order
    itemsCopy[selectedItemIndex].order = temp
    itemsCopy.sort((x, y) => x.order - y.order)
    return itemsCopy
}

export const canReorderUp = <T extends { id: string }>(selectedItemIds: string[], items: T[]) => {
    return selectedItemIds.length === 1 && items.findIndex((x) => x.id === selectedItemIds[0]) !== 0
}

export const canReorderDown = <T extends { id: string }>(selectedItemIds: string[], items: T[]) => {
    return selectedItemIds.length === 1 && items.findIndex((x) => x.id === selectedItemIds[0]) !== items.length - 1
}

export const filterOutItems = <T extends { id: string }>(items: T[], selectedItemIds: string[]) => {
    return items.filter((x) => !selectedItemIds.includes(x.id))
}

// can we make this generic to find the highest of any numeric property?
export const getHighestId = (items: { id: number }[]) => Math.max(...items.map((x) => x.id))
export const getLowestId = (items: { id: number }[]) => Math.min(0, Math.min(...items.map((x) => x.id)))
