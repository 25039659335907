import { GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { KendoGridColumn } from 'types/GridLayout'
import { EventFilter, WorkloadRule } from 'types/ProfileInterfaces'
import { getDefaultWorkloadRule } from 'types/ProfileInterfacesDefaults'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'
import GridCheckCell from 'views/Common/Kendo/GridCellComponents/GridCheckCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'
import EventFilterDialog from '../../../Common/EventFilter/EventFilterDialog'

interface WorkloadRulesDialogProps {
    profileId: string
    onClose: () => void
}

const WorkloadRulesDialog = (props: WorkloadRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const profile = useAppSelector((s) => s.profile.profileWorkload)
    const [tempRules, setTempRules] = useState<WorkloadRule[]>(profile?.workloadRules || [])
    const [tempRule, setTempRule] = useState<WorkloadRule | undefined>()
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    const [showEventFilter, setShowEventFilter] = useState(false)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    useEffect(() => {
        if (!profile) {
            return
        }
        const newList = profile.workloadRules.map((item) => createDynamicColumns(item))
        setTempRules(newList)
    }, [profile])

    // operations
    const addNewRecord = (existRecord?: WorkloadRule) => {
        const newRecord = getDefaultWorkloadRule(existRecord, tempRules)
        setTempRule(newRecord)
        setShowEventFilter(true)
        setSelectedRowsState({ [newRecord.id]: true })
    }
    function handleCopyClick(): void {
        if (selectedIds.length !== 1) return
        const item = tempRules.find((i) => i.id === selectedIds[0])
        if (item) {
            addNewRecord(item)
        }
    }

    const handleDeleteClick = () => {
        setShowDialog(deleteDialog)
    }

    function changeOrder(direction: MoveDirection) {
        setTempRules(reorderItemsList(selectedIds, tempRules, direction))
    }

    // grid
    const EDIT_FIELD = 'inEdit'
    const enterEditHandler = useCallback(
        (dataItem: WorkloadRule, field: string | undefined) => {
            const newList = tempRules.map((item) => ({
                ...item,
                [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
            }))
            setTempRules(newList)
            setSelectedRowsState({ [dataItem.id]: true })
        },
        [tempRules],
    )
    const exitEditHandler = () => {
        setTempRules(tempRules.map((item) => ({ ...item, [EDIT_FIELD]: undefined })))
    }
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field || ''
        const newList = tempRules.map((item) => {
            if (item.id === event.dataItem.id) {
                return {
                    ...item,
                    [field]: event.value,
                    workloadFactors: item.workloadFactors.map((factor) => {
                        return factor.worloadFactorId === field ? { ...factor, enabled: event.value } : factor
                    }),
                }
            }
            return item
        })
        setTempRules(newList)
    }
    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender originalProps={propsThe} td={td} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
    )
    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitEditHandler} editField={EDIT_FIELD} />
    )

    const getColumns = useMemo((): KendoGridColumn[] => {
        if (!profile) return []
        const getCheckboxCell = (gridCellProps: GridCellProps) => (
            <GridCheckCell {...gridCellProps} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
        )
        const columns: KendoGridColumn[] = [
            {
                title: 'Name',
                field: 'eventFilter.name',
                cell: (cellProps: GridCellProps) => {
                    const rule = cellProps.dataItem as WorkloadRule
                    const cellContent = (
                        <TransparentButton
                            onClick={() => {
                                setTempRule(rule)
                                setShowEventFilter(true)
                            }}
                        >
                            {rule.eventFilter.name}
                        </TransparentButton>
                    )
                    return FormattedNameCell(cellProps, cellContent)
                },
            },
            {
                title: 'Triggers',
                headerClassName: 'MultiLineCellHeader',
                children: [
                    {
                        title: 'Start',
                        field: 'triggerOnEventStart',
                        cell: getCheckboxCell,
                    },
                    {
                        title: 'Periodic',
                        field: 'triggerInEventPeriodicallyDuration',
                        width: '100px',
                        editor: 'numeric',
                    },
                    { title: 'End', field: 'triggerOnEventEnd', cell: getCheckboxCell },
                ],
            },
        ]
        if (profile.workloadFactors.length > 0) {
            columns.push({
                title: 'Factors',
                headerClassName: 'MultiLineCellHeader',
                children: profile.workloadFactors.map((factor) => {
                    return { title: factor.name, field: factor.id, cell: getCheckboxCell }
                }),
            })
        }
        return columns
    }, [enterEditHandler, profile])

    const createDynamicColumns = (rule: WorkloadRule): WorkloadRule => {
        let newItem = rule
        const factors = rule.workloadFactors
        factors.forEach((factor) => {
            newItem = {
                ...newItem,
                [factor.worloadFactorId]: factor.enabled,
            }
        })
        return newItem
    }

    // event filter
    const handleEventFilterClose = (result: DialogResultEnum, eventFilter?: EventFilter) => {
        if (result === DialogResultEnum.Completed && eventFilter && tempRule) {
            const itemIndex = tempRules.findIndex((x) => x.id === tempRule.id)
            if (itemIndex >= 0) {
                const newList = tempRules.map((s) => {
                    if (s.id === tempRule.id) return { ...s, eventFilter }
                    return s
                })
                setTempRules(newList)
            } else {
                const newItem = createDynamicColumns({ ...tempRule, eventFilter })
                setTempRules([...tempRules, newItem])
            }
        }
        setShowEventFilter(false)
    }

    // header
    const headerContent = 'Workload Rules'

    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={tempRules}
                                selectedIds={selectedIds}
                                onAddClick={addNewRecord}
                                onCopyClick={handleCopyClick}
                                onDeleteClick={handleDeleteClick}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={tempRules}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        onItemChange={itemEditedHandler}
                    />
                </Col>
            </Row>
        </Container>
    )

    // dialogs
    const deleteDialog = useMemo(
        () => (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Workload Rule"
                setTempItems={setTempRules}
                tempItems={tempRules}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        ),
        [selectedIds, tempRules],
    )

    if (!profile) {
        return <></>
    }
    const footerContent = (
        <FormFooter
            onCancel={props.onClose}
            onOk={() => {
                const newList = tempRules.map((item) => ({
                    ...item,
                    workloadFactors: item.workloadFactors.map((factor) => ({
                        ...factor,
                        enabled: item[factor.worloadFactorId] !== undefined && item[factor.worloadFactorId] === true,
                    })),
                }))
                const updated = { ...profile, workloadRules: newList }
                dispatch(profileActions.setProfileByType({ profile: updated, type: 'Workload' }))
                props.onClose()
            }}
        />
    )

    return (
        <>
            {showEventFilter ? (
                <EventFilterDialog eventFilter={tempRule?.eventFilter} onClose={handleEventFilterClose} />
            ) : (
                <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={props.onClose}>
                    {gridContent}
                </DialogLayout>
            )}
            {showDialog}
        </>
    )
}

export default WorkloadRulesDialog
