import usePalettes, { getPaletteWithParameters } from 'hooks/usePalettes'
import usePrintPreviewGlobalState from 'hooks/usePrintPreviewGlobalState'
import useSettingValue from 'hooks/useSettingValue'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { showResponseMessage } from 'services/utilities/toastrUtils'
import ScheduleDetailsContext, { ScheduleDetailsState } from 'store/scheduleDetailsContext'
import { RootState } from 'store/store'
import { DetailData } from 'types/DetailItem'
import {
    ColorPalettes,
    GraphRightAxisSelections,
    parseScheduleDetail,
    ScheduleDetailsViewMode,
    TableOptionsFilterSelections,
    TimeModeEnum,
    ViewSettings,
} from 'types/interfaces'
import MetaData from 'types/Metadata'
import Schedule, {
    createEmptySchedule,
    filterTableEvents,
    getDutyEventSelection,
    ScheduleWithEvents,
} from 'types/Schedule'
import ScheduleEvent, { parseScheduleEvent, ScheduleEventRecord } from 'types/ScheduleEvent'
import { SettingConsts } from 'types/SystemSetting'
import { DutyItemState, UpdateDutyRequest } from 'types/UpdateDutyRequest'
import EllipsisDropdown, {
    EllipsisDropdownHeader,
    EllipsisDropdownItem,
    IndentedDropdownItem,
    ItemWithIcon,
} from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import ToolbarToggleDropdown from 'views/Common/Buttons/ToolbarToggleDropdown'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ErrorDialog from 'views/Common/GenericDialogs/ErrorDialog'
import LoadingSpinner from 'views/Common/GenericDialogs/LoadingSpinner'
import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'
import GridPageLayout from 'views/Common/Layout/PageLayout'
import RowBreadcrumbs from 'views/Common/Layout/RowBreadcrumbs'
import SharingDialog from 'views/Common/SharingDialog/SharingDialog'
import DutyDialog from 'views/Schedules/DutyDialog/DutyDialog'
import ScheduleDetailsHeading from 'views/Schedules/ScheduleDetails/Components/ScheduleDetailsHeading'
import ScheduleEventsTable from 'views/Schedules/ScheduleDetails/Components/ScheduleEventsTable'
import EventEditingDialog from 'views/Schedules/ScheduleDetails/Dialogs/EventEditingDialog'
import GraphOptionsMenu, { GraphSettingsDialog } from 'views/Schedules/ScheduleDetails/Dialogs/GraphOptionsMenu'
import SleepSettingsDialog from 'views/Schedules/ScheduleDetails/Dialogs/SleepSettingsDialog'
import EffectivenessGraph, {
    prepDataForGraph,
} from 'views/Schedules/ScheduleDetails/EffectivenessGraph/EffectivenessGraph'
import EventTimelineTooltip from 'views/Schedules/ScheduleDetails/EffectivenessGraph/EventTimelineTooltip'
import OverlappingEventsDialog, { UpdatedDates } from '../../../OverlappingEvents/Dialogs/OverlappingEventsDialog'
import ShiftScheduleDialog from '../../ShiftsDialog/ShiftScheduleDialog'
import EventOverlapsBanner from '../Components/EventOverlapsBanner'
import PrintableScheduleDetailsHeader from '../Components/PrintableScheduleDetailsHeader'
import { ClickEditTimeParams } from '../Components/ScheduleEventsTableColumns'
import EditDateTimeDialog from '../Dialogs/EditDateTimeDialog'
import EditScheduleDialog from '../Dialogs/EditScheduleDialog'
import ScheduleSaveAsDialog from '../Dialogs/ScheduleSaveAsDialog'
import TableOptionsMenu, { TableSettingsDialog } from '../Dialogs/TableOptionsMenu'
import {
    DashboardInfo,
    EventEditCallback,
    EventHoverArgs,
    EventHoverCallback,
    ZoomDetails,
} from '../EffectivenessGraph/EffectivenessGraphTypes'
import GraphDashboard from '../GraphDashboard/GraphDashboard'

// default GraphSchedule allows the graph to render with just default background colors, no lines
const defaultEmptySchedule = createEmptySchedule('Loading...')
type ShownDialogType =
    | 'None'
    | 'SleepSettings'
    | 'SaveAs'
    | 'EditEvent'
    | 'EditShift'
    | 'EditSchedule'
    | 'EditDateTime'
    | 'CannotDeleteEvent'
    | 'ConfirmDeleteEvent'
    | 'ConfirmDiscardDraft'
    | 'EventOverlaps'
    | 'ResetSleep'
    | 'Share'
    | 'GraphSettings'
    | 'TableSettings'

const ScheduleDetailsPage = () => {
    const api = globals.getApi()
    const navigate = useNavigate()
    const palettes = usePalettes()
    const [scenarioPalette, setScenarioPalette] = useState<ColorPalettes | null>(null)
    const [overlappingEvents, setOverlappingEvents] = useState<ScheduleEvent[]>([])
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [zoomPosition, setZoomPosition] = useState<{ left: number; right: number; zoomStart?: Date }>({
        left: 0,
        right: 1,
    })
    const [graphDashboards, setGraphDashboards] = useState<DashboardInfo[]>([])
    const [graphInspectorLines, setGraphInspectorLines] = useState<DashboardInfo[]>([])

    const graphContainerEl: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const pinnedDashboardContainerEl: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const [eventTooltipArgs, setEventTooltipArgs] = useState<EventHoverArgs | null>(null)
    const [editEvent, setEditEvent] = useState<ScheduleEvent | null>(null)
    const [rowIndex, setRowIndex] = useState<number | 0>(0)
    const [deleteEvents, setDeleteEvents] = useState<ScheduleEvent[]>([])
    const [editEventFormState, setEditEventFormState] = useState<ScheduleEventRecord | null>(null)
    const [showDialog, setShowDialog] = useState<ShownDialogType>('EditDateTime')
    const [isPrintPreview, setIsPrintPreview] = useState(false)
    const [isManualSleepMode, unusedsetIsManualSleepMode] = useState(false)
    const [pageContentHeight, setPageContentHeight] = useState(0)
    const [pageContentWidth, setPageContentWidth] = useState(0)
    const [isSaving, setIsSaving] = useState(false)
    const [schedule, setSchedule] = useState<Schedule>(defaultEmptySchedule)
    const [editDateTimeValue, setEditDateTimeValue] = useState<ClickEditTimeParams | null>(null)
    const params = useParams() as any
    const scheduleId = Number(params.id) || 0 // SFC will have no scheduleid passed
    const isDraftSchedule = schedule.sourceScheduleId !== null

    const bannerRef = useRef<HTMLElement>(null)
    const bannerCSS = bannerRef && bannerRef.current ? window.getComputedStyle(bannerRef.current, null) : null
    const bannerHeight = bannerRef.current
        ? bannerRef.current.offsetHeight + parseInt(bannerCSS!.marginTop, 10) + parseInt(bannerCSS!.marginBottom, 10)
        : 0

    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const graphDashboardHorizontalOrientation =
        useSettingValue(SettingConsts.general.schedules_Dashboard_Orientation) === 'Horizontal'
    const readonlyView = isSaving || schedule.userCanEdit === false || isPrintPreview

    const setScheduleWithNewData = useCallback(
        (newData: Schedule) => {
            if (!palettes) return
            newData.events = newData.events.map((x) => parseScheduleEvent(x, newData.modified))
            const detailData: DetailData[] = JSON.parse(newData.detailItemsJson ?? '[]').map(parseScheduleDetail)
            newData.detailDataWithTimelineTicks = prepDataForGraph(detailData)
            setSchedule(newData)
            setScenarioPalette(getPaletteWithParameters(newData.scenarioParameters, palettes))

            // schedule data has changed, so update any dashboards' data
            // to match the time that the dashboard inspector lines are set to.
            setGraphDashboards((prev) => {
                const cpy = [...prev]
                const detailItems = newData.detailDataWithTimelineTicks!.detailData
                cpy.forEach((dashboard) => {
                    for (let i = 0; i < detailItems.length; i++) {
                        if (detailItems[i].utcTime >= dashboard.detailItem.utcTime) {
                            // found the detail data point at the time that the dashboard is pointed at
                            dashboard.detailItem = detailItems[i]
                            break
                        }
                    }
                })
                return cpy
            })
        },
        [setSchedule, palettes],
    )

    const loadData = useCallback(
        async (loadDataScheduleId: number) => {
            const [loadedSchedule, message] = await api.getScheduleWithDetails(loadDataScheduleId)
            if (loadedSchedule.errors.length > 0) {
                loadedSchedule.viewSettings.viewMode = ScheduleDetailsViewMode.Table
                // overlaps exist so save in Table View
                // save it again as table view so that when the overlaps are resolved, it doesn't automatically switch to the previously selected view (which is graph on imported schedules)
                // schedule.viewSettings.viewMode = ScheduleDetailsViewMode.Table
                api.saveScheduleViewSettings(loadedSchedule.id, {
                    ...loadedSchedule.viewSettings,
                    viewMode: ScheduleDetailsViewMode.Table,
                })
            }

            window.localStorage.setItem('lastViewedScenarioId', loadedSchedule.scenarioId.toString())

            showResponseMessage(message)
            // Call update last viewed to set the last viewed by user
            await api.updateLastViewed(loadedSchedule.id)
            document.title = `Schedule View - "${loadedSchedule.name}"`
            setScheduleWithNewData(loadedSchedule)
        },
        [setScheduleWithNewData, api],
    )

    const deleteEventCallback = useCallback(() => {
        setDeleteEvents([editEvent!])
        setShowDialog('ConfirmDeleteEvent')
    }, [setDeleteEvents, setShowDialog, editEvent])

    const closeCalback = useCallback(
        (status: DialogResultEnum, mode?: 'Add' | 'Edit', updatedSchedule?: Schedule) => {
            setEditEvent(null)
            setEditEventFormState(null)
            setShowDialog('None')
            if (status === DialogResultEnum.Completed && updatedSchedule) {
                // retain original duty ids
                const dutyIds = schedule.events.map((ev) => ev.dutyUuid).filter((x, i, a) => a.indexOf(x) === i)

                setScheduleWithNewData(updatedSchedule)

                const newDuty = updatedSchedule.events.find((ev) => !dutyIds.includes(ev.dutyUuid))
                if (newDuty?.isDutyRollup()) {
                    setEditEvent(newDuty)
                    setShowDialog('EditEvent')
                }

                if (mode === 'Add') {
                    // This handles new Work dnd action to scroll to the new
                    // "Work" in the grid
                    if (newDuty === undefined) {
                        const rowIndexToFind = '[data-grid-row-index="' + rowIndex + '"]'
                        const gridTableRowWork = document.querySelector(`${rowIndexToFind}`)!
                        if (gridTableRowWork) {
                            gridTableRowWork.scrollIntoView({ behavior: 'smooth' })
                        }
                    }
                    if (newDuty !== undefined && newDuty.uuid) {
                        // This handles both click to add duty and dnd add duty
                        // that will scroll to new duty
                        const gridTableRowDuty = document.querySelector(`[data-uuid="${newDuty.uuid}"]`)!
                        if (gridTableRowDuty) {
                            gridTableRowDuty.scrollIntoView({ behavior: 'smooth' })
                        }
                    }
                }
            }
        },
        [schedule.events, setScheduleWithNewData, rowIndex],
    )

    useEffect(() => {
        loadData(scheduleId)
    }, [loadData, scheduleId, isManualSleepMode])

    usePrintPreviewGlobalState(isPrintPreview)

    const changeUrlToNewScheduleId = (id: number) => {
        navigate('/schedule/' + id.toString(), { replace: true })
    }

    const saveScheduleViewSettings = async (scheduleIdToSave: number, viewSettings: ViewSettings) => {
        try {
            await api.saveScheduleViewSettings(scheduleIdToSave, viewSettings)
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    const saveDraftSchedule = async () => {
        setIsSaving(true)
        try {
            const [loadedSchedule, message] = await api.saveDraftSchedule(schedule.id, schedule.modified)
            showResponseMessage(message)
            setScheduleWithNewData(loadedSchedule)
            toast.success('Your changes have been saved')
        } catch (error: any) {
            setErrorMessage(error.message)
        } finally {
            setIsSaving(false)
        }
    }

    const discardDraftSchedule = async () => {
        try {
            await api.discardDraftSchedule(schedule.id, schedule.modified)
            loadData(schedule.sourceScheduleId!)
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    const deleteEvent = async () => {
        try {
            let userEvents = 0
            schedule.events.forEach((event) => {
                if (event.isDefaultWorkEvent()) userEvents++
            })

            let userEventsToDelete = 0
            deleteEvents.forEach((event) => {
                if (event.isDefaultWorkEvent()) userEventsToDelete++
            })

            if (userEventsToDelete === userEvents) {
                setShowDialog('CannotDeleteEvent')
            } else {
                const [updatedSchedule, message] = await api.deleteEvent(schedule, deleteEvents)
                showResponseMessage(message)
                setScheduleWithNewData(updatedSchedule)
            }
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    const setViewMode = async (viewMode: ScheduleDetailsViewMode) => {
        setSchedule((previous) => {
            const updatedSchedule = {
                ...previous,
                viewSettings: { ...previous.viewSettings, viewMode },
            }
            saveScheduleViewSettings(updatedSchedule.id, updatedSchedule.viewSettings)
            return updatedSchedule
        })
    }

    const setTimeMode = async (timeMode: TimeModeEnum) => {
        setSchedule((previous) => {
            const updatedSchedule = {
                ...previous,
                viewSettings: { ...previous.viewSettings, timeMode },
            }
            saveScheduleViewSettings(updatedSchedule.id, updatedSchedule.viewSettings)
            return updatedSchedule
        })
    }

    const setGraphOverlays = (overlays: GraphRightAxisSelections) => {
        setSchedule((previous) => {
            const updatedSchedule = {
                ...previous,
                viewSettings: { ...previous.viewSettings, overlays },
            }
            saveScheduleViewSettings(updatedSchedule.id, updatedSchedule.viewSettings)
            return updatedSchedule
        })
    }

    const setTableFilterOptions = async (tableFilters: TableOptionsFilterSelections) => {
        setSchedule((previous) => {
            const updatedSchedule = {
                ...previous,
                viewSettings: { ...previous.viewSettings, tableFilters },
            }
            saveScheduleViewSettings(schedule.id, updatedSchedule.viewSettings)
            return updatedSchedule
        })
    }
    const handlePrintPreviewClick = () => {
        setIsPrintPreview(true)
        const Msg = () => (
            <div>
                <h5>Dashboard Position</h5>
                <p>You may reposition your unpinned Dashboard(s) to best suit your final print layout</p>
            </div>
        )
        if (schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph) {
            const unpinnedDashboards = graphDashboards.filter((x) => !x.isPinned)
            if (unpinnedDashboards.length > 0) {
                toast.warning(<Msg />, {
                    position: toast.POSITION.TOP_LEFT,
                    autoClose: 10000,
                    style: { width: '680px' },
                })
            }
        }
    }
    let toolbarButtons = <></>
    if (isPrintPreview) {
        toolbarButtons = (
            <div className="noPrint">
                <IconButton
                    tooltip="Back to Schedule Details"
                    onClick={() => setIsPrintPreview(false)}
                    icon="bi-arrow-left"
                />
                <IconButton tooltip="Print" onClick={() => window.print()} toolbarLeftMargin icon="bi-printer" />
            </div>
        )
    } else {
        toolbarButtons = (
            <>
                {schedule.shift && (
                    <>
                        <IconButton
                            tooltip="Edit Shifts"
                            onClick={() => setShowDialog('EditShift')}
                            disabled={readonlyView}
                            toolbarLeftMargin
                            icon="bi-pencil"
                        />
                        <SeperatorVertical />
                    </>
                )}

                <>
                    <IconButton
                        tooltip="Reset Changes"
                        onClick={() => setShowDialog('ConfirmDiscardDraft')}
                        disabled={!isDraftSchedule || readonlyView}
                        toolbarLeftMargin
                        icon="bi-arrow-counterclockwise"
                        text={isDraftSchedule ? 'Reset' : undefined}
                        // variant={isDraftSchedule ? 'warning' : 'white'}
                    />
                    <IconButton
                        tooltip="Save Schedule"
                        onClick={() => saveDraftSchedule()}
                        disabled={!isDraftSchedule || isSaving || readonlyView}
                        toolbarLeftMargin
                        icon="bi-save2-fill"
                        text={isDraftSchedule ? 'Save' : undefined}
                        // variant={isDraftSchedule ? 'success' : 'white'}
                        // iconcolor={isDraftSchedule ? '#fff' : undefined}
                    />
                    <IconButton
                        tooltip="Save Schedule As New Copy"
                        onClick={() => setShowDialog('SaveAs')}
                        toolbarLeftMargin
                        icon="bi-save2"
                    />
                    <SeperatorVertical />
                </>

                <ToolbarToggleDropdown
                    tooltip="Graph"
                    tooltipSplit="Graph Settings..."
                    icon="bi-graph-up"
                    isToggled={
                        schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both ||
                        schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph
                    }
                    disabled={isSaving}
                    onToggleChange={(toggled) => {
                        if (toggled && schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Table) {
                            setViewMode(ScheduleDetailsViewMode.Both)
                        } else if (!toggled && schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both) {
                            setViewMode(ScheduleDetailsViewMode.Table)
                            return false
                        }
                        return true
                    }}
                >
                    <GraphOptionsMenu
                        initialSelections={schedule.viewSettings.overlays}
                        graphOptionsChanged={setGraphOverlays}
                    />
                </ToolbarToggleDropdown>

                <ToolbarToggleDropdown
                    tooltip="Table"
                    tooltipSplit="Table Settings..."
                    icon="bi-table"
                    isToggled={
                        schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both ||
                        schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Table
                    }
                    disabled={isSaving}
                    onToggleChange={(toggled) => {
                        if (toggled && schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph) {
                            setViewMode(ScheduleDetailsViewMode.Both)
                        } else if (!toggled && schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both) {
                            setViewMode(ScheduleDetailsViewMode.Graph)
                            return false
                        }
                        return true
                    }}
                >
                    <TableOptionsMenu
                        initialSelections={schedule.viewSettings.tableFilters}
                        tableOptionsChanged={setTableFilterOptions}
                    />
                </ToolbarToggleDropdown>

                <SeperatorVertical />

                <IconButton
                    tooltip="Print Preview"
                    onClick={() => handlePrintPreviewClick()}
                    disabled={isSaving}
                    toolbarLeftMargin
                    icon="bi-printer"
                />

                <EllipsisDropdown>
                    {schedule.shift && (
                        <>
                            <EllipsisDropdownItem onClick={() => setShowDialog('EditShift')} disabled={readonlyView}>
                                <ItemWithIcon bootstrapIconClass="bi-pencil">Edit Shifts</ItemWithIcon>
                            </EllipsisDropdownItem>
                            <Dropdown.Divider />
                        </>
                    )}

                    <>
                        <EllipsisDropdownItem
                            onClick={() => setShowDialog('ConfirmDiscardDraft')}
                            disabled={!isDraftSchedule || isSaving || readonlyView}
                        >
                            <ItemWithIcon bootstrapIconClass="bi-arrow-counterclockwise">Reset Changes</ItemWithIcon>
                        </EllipsisDropdownItem>
                        <EllipsisDropdownItem
                            onClick={() => saveDraftSchedule()}
                            disabled={!isDraftSchedule || isSaving || readonlyView}
                        >
                            <ItemWithIcon bootstrapIconClass="bi-save-fill">Save Schedule</ItemWithIcon>
                        </EllipsisDropdownItem>
                        <EllipsisDropdownItem onClick={() => setShowDialog('SaveAs')}>
                            <ItemWithIcon bootstrapIconClass="bi-save">Save Schedule As New Copy</ItemWithIcon>
                        </EllipsisDropdownItem>
                        <Dropdown.Divider />
                    </>

                    <EllipsisDropdownHeader>Time Reference</EllipsisDropdownHeader>
                    <EllipsisDropdownItem onClick={() => setTimeMode(TimeModeEnum.UTC)}>
                        <IndentedDropdownItem checked={schedule.viewSettings.timeMode === TimeModeEnum.UTC}>
                            {TimeModeEnum[TimeModeEnum.UTC]}
                        </IndentedDropdownItem>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setTimeMode(TimeModeEnum.Base)}>
                        <IndentedDropdownItem checked={schedule.viewSettings.timeMode === TimeModeEnum.Base}>
                            {TimeModeEnum[TimeModeEnum.Base]}
                        </IndentedDropdownItem>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setTimeMode(TimeModeEnum.Local)}>
                        <IndentedDropdownItem checked={schedule.viewSettings.timeMode === TimeModeEnum.Local}>
                            {TimeModeEnum[TimeModeEnum.Local]}
                        </IndentedDropdownItem>
                    </EllipsisDropdownItem>
                    <Dropdown.Divider />

                    <EllipsisDropdownHeader>View</EllipsisDropdownHeader>
                    <EllipsisDropdownItem onClick={() => setViewMode(ScheduleDetailsViewMode.Both)}>
                        <IndentedDropdownItem checked={schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both}>
                            Graph and Table
                        </IndentedDropdownItem>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setViewMode(ScheduleDetailsViewMode.Graph)}>
                        <IndentedDropdownItem
                            checked={schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph}
                        >
                            {ScheduleDetailsViewMode[ScheduleDetailsViewMode.Graph]}
                        </IndentedDropdownItem>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setViewMode(ScheduleDetailsViewMode.Table)}>
                        <IndentedDropdownItem
                            checked={schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Table}
                        >
                            {ScheduleDetailsViewMode[ScheduleDetailsViewMode.Table]}
                        </IndentedDropdownItem>
                    </EllipsisDropdownItem>
                    <Dropdown.Divider />

                    <EllipsisDropdownItem onClick={() => setShowDialog('GraphSettings')} disabled={readonlyView}>
                        <ItemWithIcon bootstrapIconClass="bi-graph-up">Graph Settings</ItemWithIcon>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setShowDialog('TableSettings')} disabled={readonlyView}>
                        <ItemWithIcon bootstrapIconClass="bi-table">Table Settings</ItemWithIcon>
                    </EllipsisDropdownItem>
                    <Dropdown.Divider />

                    <EllipsisDropdownItem onClick={() => setShowDialog('SleepSettings')} disabled={readonlyView}>
                        <ItemWithIcon bootstrapIconClass="bi-moon">Sleep Settings</ItemWithIcon>
                    </EllipsisDropdownItem>
                    {schedule.scenarioParameters.editSleep && (
                        <EllipsisDropdownItem onClick={() => setShowDialog('ResetSleep')} disabled={readonlyView}>
                            <ItemWithIcon bootstrapIconClass="bi-upload">Reset Sleep</ItemWithIcon>
                        </EllipsisDropdownItem>
                    )}
                    <Dropdown.Divider />

                    <EllipsisDropdownItem onClick={() => handlePrintPreviewClick()} disabled={isSaving}>
                        <ItemWithIcon bootstrapIconClass="bi-printer">Print Preview</ItemWithIcon>
                    </EllipsisDropdownItem>
                </EllipsisDropdown>
            </>
        )
    }

    const overlappingEventsBanner = (
        <EventOverlapsBanner
            elementRef={bannerRef}
            schedule={schedule}
            editOverlapClicked={(event1, event2) => {
                setOverlappingEvents([event1, event2])
                setShowDialog('EventOverlaps')
            }}
        />
    )

    const breadCrumbs = (
        <RowBreadcrumbs
            entries={[
                { label: 'Scenarios', url: '/Scenarios' },
                { label: schedule.scenarioName, url: `/scenario/${schedule.scenarioId}` },
                { label: 'Schedule View' },
            ]}
        />
    )

    // usecallbacks so that "memo" works on EffectivenessGraph
    const setEventTooltipPositionCallback = useCallback<EventHoverCallback>(
        (hoverArgs?: EventHoverArgs) => setEventTooltipArgs(hoverArgs ?? null),
        [],
    )
    const setEventEditMode = useCallback<EventEditCallback>((args?: ScheduleEvent) => {
        setEditEvent(args ?? null)
        setShowDialog('EditEvent')
    }, [])

    const updateGraphAfterZoomCallback = useCallback(
        (zoomDetail: ZoomDetails) =>
            setZoomPosition({
                zoomStart: zoomDetail.startTime,
                left: zoomDetail.leftRatio,
                right: zoomDetail.rightRatio,
            }),
        [],
    )

    const updateScheduleCallback = useCallback(setScheduleWithNewData, [setScheduleWithNewData])

    const addNewEventCallback = useCallback(
        (scheduleEvent: ScheduleEvent) => {
            setEventEditMode(scheduleEvent)
        },
        [setEventEditMode],
    )

    const rowIndexCallback = useCallback(
        (testRowIndex: number) => {
            setRowIndex(testRowIndex)
        },
        [setRowIndex],
    )

    const clickGraphEvent = useCallback(
        (scheduleEvent: ScheduleEvent) => {
            if (!readonlyView) {
                setEventEditMode(scheduleEvent)
            }
        },
        [readonlyView, setEventEditMode],
    )

    const clickEditEventTime = useCallback((args: ClickEditTimeParams) => {
        setEditDateTimeValue({
            startTime: args.startTime,
            startTimeLocalized: args.startTimeLocalized,
            endTime: args.endTime,
            endTimeLocalized: args.endTimeLocalized,
            timePosition: args.timePosition,
            dutyUuid: args.dutyUuid,
        })
        setShowDialog('EditDateTime')
    }, [])

    const scheduleEvents = useMemo(() => {
        const filteredIgnoreExplicitSleep = schedule.events.filter(
            (x) => !(schedule.scenarioParameters.ignoreExplicitSleep && x.isExplicitSleep()),
        )

        return filterTableEvents(filteredIgnoreExplicitSleep, schedule.viewSettings.tableFilters)
    }, [schedule])

    const pwsRuleIds = useMemo(() => schedule.scenarioParameters.plannedWorkSleepRules, [schedule])
    const pwsEnabled = useMemo(() => schedule.scenarioParameters.plannedWorkSleep, [schedule])

    const addDashboardCallback = useCallback(
        (newDashboard: DashboardInfo) => {
            setGraphDashboards((previous) => {
                const updated = [...previous, { ...newDashboard }]
                reorderDashboards(updated)
                return updated
            })
            setGraphInspectorLines((previous) => {
                const updated = [...previous, { ...newDashboard }]
                reorderDashboards(updated)
                return updated
            })
        },
        [setGraphDashboards, setGraphInspectorLines],
    )

    const reorderDashboards = (dashboards: DashboardInfo[]) => {
        dashboards.sort((a, b) => {
            if (a.detailItem.utcTime < b.detailItem.utcTime) {
                return -1
            }
            if (a.detailItem.utcTime > b.detailItem.utcTime) {
                return 1
            }
            return 0
        })

        // re-order the dashboards
        dashboards.forEach((db, i) => {
            db.dashboardOrderNumber = i + 1
        })
    }

    const updateDashboardDataCallback = useCallback(
        (updatedDashboardId: string, data: DetailData, updateInspectors: boolean) => {
            const update = (previous: DashboardInfo[]): DashboardInfo[] => {
                const updated = previous.map((dashboard) => {
                    if (dashboard.dashboardId === updatedDashboardId) {
                        return { ...dashboard, detailItem: data }
                    }
                    return dashboard
                })

                // at the end of a drag, we update things like the order of dashboards.
                if (updateInspectors) {
                    reorderDashboards(updated)
                }

                return updated
            }

            // keep the "graphDashboards" array in sync, but only update the graphInspectorLines
            // array at certain times (ie, not while dragging) because that will set the position of the
            // inspector lines.  Don't want to re-render those while we are dragging them.
            setGraphDashboards(update)
            if (updateInspectors) {
                setGraphInspectorLines(update)
            }
        },
        [setGraphDashboards],
    )

    const scheduleDetailsContextMemo = useMemo((): ScheduleDetailsState => {
        return {
            setFormState: setEditEventFormState,
            getFormState: () => editEventFormState,
            setSchedule: (updatedSchedule: Schedule) => setScheduleWithNewData(updatedSchedule),
            addNewEventViaDialog: addNewEventCallback,
            setChangedGridRowIndex: rowIndexCallback,
            setDeleteEvents: (eventsToDelete: ScheduleEvent[]) => {
                setDeleteEvents(eventsToDelete)
                setShowDialog('ConfirmDeleteEvent')
            },
        }
    }, [setScheduleWithNewData, editEventFormState, addNewEventCallback, rowIndexCallback])

    /**
     * Close the given dashboard
     */
    const closeDashboard = useCallback((db: DashboardInfo) => {
        const filterAndReorder = (previous: DashboardInfo[]): DashboardInfo[] => {
            const filtered = [...previous.filter((x) => x.dashboardId !== db.dashboardId)]
            reorderDashboards(filtered)
            return filtered
        }

        setGraphDashboards(filterAndReorder)
        setGraphInspectorLines(filterAndReorder)
    }, [])

    /**
     * Pin the given dashboard
     */
    const pinDashboard = useCallback((db: DashboardInfo) => {
        setGraphDashboards((previous) => {
            const updatedDashboards = [
                ...previous.map((thisDb) => {
                    if (thisDb.dashboardId === db.dashboardId) {
                        thisDb.isPinned = !thisDb.isPinned
                    } else {
                        if (thisDb.isPinned) {
                            // auto-unpin the one that was already pinned, and put
                            // it in the location where the one that has just been pinned was
                            thisDb.dashboardLeftX = db.dashboardLeftX
                        }
                        thisDb.isPinned = false
                    }
                    return thisDb
                }),
            ]
            setGraphInspectorLines(updatedDashboards)
            return updatedDashboards
        })
    }, [])

    const overlappingEventsDialogClosedHandler = useCallback(
        async (dialogResult: DialogResultEnum, updatedDates?: UpdatedDates) => {
            if (dialogResult === DialogResultEnum.Completed && updatedDates) {
                const updatedSchedule = { ...schedule } as ScheduleWithEvents
                const updatedEvents = [...updatedSchedule.events] as ScheduleEvent[]

                const evt1 = updatedEvents.find((x) => x.uuid === updatedDates.event1Uuid)!
                evt1.start = new Date(evt1.start.getTime() + updatedDates.event1StartMinutesDiff * 60000)
                evt1.duration = updatedDates.event1DurationMinutes

                const evt2 = updatedEvents.find((x) => x.uuid === updatedDates.event2Uuid)!
                evt2.start = new Date(evt2.start.getTime() + updatedDates.event2StartMinutesDiff * 60000)
                evt2.duration = updatedDates.event2DurationMinutes

                updatedSchedule.events = updatedEvents
                const [response, message] = await api.updateScheduleWithEvents(updatedSchedule)
                showResponseMessage(message)
                setScheduleWithNewData(response)
            }
            setShowDialog('None')
        },
        [api, schedule, setScheduleWithNewData],
    )

    const shiftDialogCloseCallback = useCallback(
        (status: DialogResultEnum, updatedSchedule?: Schedule) => {
            setShowDialog('None')
            if (status === DialogResultEnum.Completed && updatedSchedule) {
                setScheduleWithNewData(updatedSchedule)
            }
        },
        [setScheduleWithNewData],
    )

    if (!scenarioPalette) {
        return <LoadingSpinner />
    }

    // render dashboards if in Graph view.
    let graphDashboardElements = null
    if (schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph) {
        graphDashboardElements = graphDashboards
            .filter((x) => !x.isPinned)
            .map((db) => (
                <GraphDashboard
                    orientationHorizontal={graphDashboardHorizontalOrientation}
                    timeMode={schedule.viewSettings.timeMode}
                    key={db.dashboardId}
                    leftX={db.dashboardLeftX}
                    closeClick={() => {
                        closeDashboard(db)
                    }}
                    pinClick={() => {
                        pinDashboard(db)
                    }}
                    palettes={scenarioPalette}
                    dataItem={db.detailItem}
                    dashboardNumber={db.dashboardOrderNumber}
                    isPrintPreview={isPrintPreview}
                />
            ))
    }

    let pinnedDashboardEl = null
    const pinnedDashboard = graphDashboards.find((x) => x.isPinned)
    if (pinnedDashboard) {
        pinnedDashboardEl = (
            <GraphDashboard
                orientationHorizontal={false}
                timeMode={schedule.viewSettings.timeMode}
                closeClick={() => closeDashboard(pinnedDashboard)}
                pinClick={() => {
                    pinDashboard(pinnedDashboard)
                }}
                palettes={scenarioPalette}
                dashboardNumber={pinnedDashboard.dashboardOrderNumber}
                dataItem={pinnedDashboard.detailItem}
                isPrintPreview={isPrintPreview}
            />
        )
    }

    let pageHeading = <></>
    if (!isPrintPreview) {
        pageHeading = (
            <ScheduleDetailsHeading
                onClick={() => setShowDialog('EditSchedule')}
                schedule={schedule}
                sharingIconClicked={() => {
                    setShowDialog('Share')
                }}
            />
        )
    }
    // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDraftSchedule && !isSaving)
    return (
        <>
            {!schedule.id && <LoadingSpinner />}
            <GridPageLayout
                breadcrumbs={breadCrumbs}
                headingContent={pageHeading}
                buttons={toolbarButtons}
                onMainContentHeightChange={setPageContentHeight}
                onMainContentWidthChange={setPageContentWidth}
                windowResizeDelayMs={20}
            >
                <>
                    {isPrintPreview && <PrintableScheduleDetailsHeader schedule={schedule} />}

                    {eventTooltipArgs && <EventTimelineTooltip hoverArgs={eventTooltipArgs} />}

                    {showDialog === 'EditDateTime' && editDateTimeValue && (
                        <EditDateTimeDialog
                            startTime={editDateTimeValue.startTime}
                            startTimeLocalized={editDateTimeValue.startTimeLocalized}
                            endTime={editDateTimeValue.endTime}
                            endTimeLocalized={editDateTimeValue.endTimeLocalized}
                            timePosition={editDateTimeValue.timePosition}
                            dutyUuid={editDateTimeValue.dutyUuid}
                            timeMode={schedule.viewSettings.timeMode}
                            scheduleId={schedule.id}
                            scheduleModified={schedule.modified}
                            closeCallback={(dialogResult, updatedSchedule) => {
                                setShowDialog('None')
                                if (dialogResult === DialogResultEnum.Completed && updatedSchedule) {
                                    setScheduleWithNewData(updatedSchedule)
                                }
                            }}
                        />
                    )}

                    {showDialog === 'EventOverlaps' && overlappingEvents.length === 2 && (
                        <OverlappingEventsDialog
                            event1={overlappingEvents[0]}
                            event2={overlappingEvents[1]}
                            closeCallback={overlappingEventsDialogClosedHandler}
                            timeMode={schedule.viewSettings.timeMode}
                        />
                    )}

                    {/* shift editing dialog */}
                    {showDialog === 'EditShift' && (
                        <ShiftScheduleDialog
                            schedule={schedule}
                            scenarioId={schedule.scenarioId}
                            scenarioName={schedule.scenarioName}
                            shiftSchedule={schedule.shift}
                            closeCallback={shiftDialogCloseCallback}
                            modeEdit
                        />
                    )}

                    {showDialog === 'SaveAs' && (
                        <ScheduleSaveAsDialog
                            scheduleId={schedule.id}
                            scheduleName={`${schedule.name}-Copy`}
                            scenarioName={schedule.scenarioName}
                            saveAsConfirmed={(newScheduleId) => {
                                setIsSaving(true)
                                setShowDialog('None')
                                changeUrlToNewScheduleId(newScheduleId)
                                setIsSaving(false)
                            }}
                            closeCallback={() => {
                                setShowDialog('None')
                            }}
                        />
                    )}

                    <ScheduleDetailsContext.Provider value={scheduleDetailsContextMemo}>
                        {showDialog === 'EditSchedule' && (
                            <EditScheduleDialog
                                schedule={schedule}
                                readonly={readonlyView}
                                closeCallback={() => {
                                    setShowDialog('None')
                                }}
                            />
                        )}

                        {showDialog === 'EditEvent' &&
                            editEvent !== null &&
                            editEvent.isDutyRollup() &&
                            editEvent.dutyUuid !== undefined && (
                                <DutyDialog
                                    timeMode={schedule.viewSettings.timeMode}
                                    dutyEvent={editEvent}
                                    dutySelection={getDutyEventSelection(
                                        editEvent.uuid!,
                                        schedule,
                                        metadata.dutyAutoWorkTypes,
                                        schedule.scenarioParameters.commuteType === 'FixedBuffer',
                                    )}
                                    dutyWorkTypes={metadata.dutyAutoWorkTypes}
                                    closeCallback={async (
                                        status: DialogResultEnum,
                                        updatedDutyState?: DutyItemState[],
                                    ) => {
                                        if (status === DialogResultEnum.Completed && updatedDutyState) {
                                            // construct the api request
                                            const request: UpdateDutyRequest = {
                                                scheduleId: schedule.id,
                                                dutyId: editEvent.dutyUuid!,
                                                scheduleModified: schedule.modified,
                                                dutyItemState: updatedDutyState,
                                            }
                                            const [updatedSchedule, message] = await api.editDuty(request)
                                            showResponseMessage(message)
                                            setScheduleWithNewData(updatedSchedule)
                                        }
                                        setEditEvent(null)
                                        setShowDialog('None')
                                    }}
                                />
                            )}

                        {showDialog === 'EditEvent' &&
                            editEvent !== null &&
                            !(editEvent.isDutyRollup() && editEvent.dutyUuid !== undefined) && (
                                <EventEditingDialog
                                    allEventTagNames={schedule.allScheduleEventTagNames}
                                    pwsRules={pwsRuleIds}
                                    pwsEnabled={pwsEnabled}
                                    timeMode={schedule.viewSettings.timeMode}
                                    scheduleEvent={editEvent}
                                    deleteCallback={deleteEventCallback}
                                    closeCallback={closeCalback}
                                />
                            )}

                        {/* show the effectiveness graph once page height is established */}
                        {pageContentHeight > 0 &&
                            schedule.id > 0 &&
                            schedule.viewSettings.viewMode !== ScheduleDetailsViewMode.Table && (
                                <>
                                    <div
                                        className={`js-effectiveness-graph ${
                                            schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both
                                                ? 'both-view'
                                                : ''
                                        }`}
                                        ref={graphContainerEl}
                                    >
                                        <EffectivenessGraph
                                            height={pageContentHeight}
                                            width={pageContentWidth}
                                            palettes={scenarioPalette}
                                            schedule={schedule}
                                            isPrintPreview={isPrintPreview === true}
                                            isReadonly={readonlyView}
                                            leftZoomRatio={zoomPosition.left}
                                            rightZoomRatio={zoomPosition.right}
                                            eventHoverHandler={setEventTooltipPositionCallback}
                                            eventEditHandler={setEventEditMode}
                                            updateGraphAfterZoom={updateGraphAfterZoomCallback}
                                            updateSchedule={updateScheduleCallback}
                                            graphContainerEl={graphContainerEl}
                                            hasPinnedDashboard={
                                                graphDashboards.filter((x) => x.isPinned).length > 0 &&
                                                schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph
                                            }
                                            pinnedDashoardContainerEl={pinnedDashboardContainerEl}
                                            dashboards={graphInspectorLines}
                                            addDashboard={addDashboardCallback}
                                            closeDashboard={closeDashboard}
                                            pinDashboardClick={pinDashboard}
                                            updateDashboardData={updateDashboardDataCallback}
                                            addNewEvent={addNewEventCallback}
                                        />
                                    </div>
                                    {schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Graph && (
                                        <div
                                            style={{ paddingLeft: '15px' }}
                                            className="pinned-dashboard-container"
                                            ref={pinnedDashboardContainerEl}
                                        >
                                            {pinnedDashboardEl}
                                        </div>
                                    )}
                                </>
                            )}

                        {graphDashboardElements}

                        {pageContentHeight > 0 &&
                            schedule.id !== 0 &&
                            schedule.viewSettings.viewMode !== ScheduleDetailsViewMode.Graph && (
                                <>
                                    {overlappingEventsBanner}
                                    <ScheduleEventsTable
                                        gridHeight={pageContentHeight - bannerHeight}
                                        schedule={schedule}
                                        clickedEvent={clickGraphEvent}
                                        clickedEditTime={clickEditEventTime}
                                        readonly={readonlyView}
                                        events={scheduleEvents}
                                        zoomStart={zoomPosition.zoomStart}
                                    />
                                </>
                            )}

                        {showDialog === 'CannotDeleteEvent' && (
                            <SimpleDialog
                                headerText="Cannot Delete"
                                closeCallback={() => {
                                    // go back to the edit event dialog using the preserved formState
                                    setDeleteEvents([])
                                    if (editEvent) {
                                        setShowDialog('EditEvent')
                                    } else {
                                        setShowDialog('None')
                                    }
                                }}
                            >
                                Cannot delete the only event left in the schedule
                            </SimpleDialog>
                        )}

                        {showDialog === 'ConfirmDeleteEvent' && (
                            <ConfirmationDialog
                                headerText="Confirm Delete Event"
                                closeCallback={() => {
                                    // go back to the edit event dialog using the preserved formState
                                    setDeleteEvents([])
                                    if (editEvent) {
                                        setShowDialog('EditEvent')
                                    } else {
                                        setShowDialog('None')
                                    }
                                }}
                                confirmedCallback={() => {
                                    setShowDialog('None')
                                    setEditEvent(null)
                                    deleteEvent()
                                    setDeleteEvents([])
                                    scheduleDetailsContextMemo.setFormState(null)
                                }}
                            >
                                <>
                                    <p>Are you sure you want to delete the following event(s)?</p>
                                    <ul>
                                        {deleteEvents.map((evt) => (
                                            <li key={evt.id.toString()}>{evt.label}</li>
                                        ))}
                                    </ul>
                                </>
                            </ConfirmationDialog>
                        )}

                        {showDialog === 'ConfirmDiscardDraft' && (
                            <ConfirmationDialog
                                headerText="Reset Changes"
                                closeCallback={() => setShowDialog('None')}
                                confirmedCallback={discardDraftSchedule}
                            >
                                Are you sure you want to reset this schedule and discard your changes?
                            </ConfirmationDialog>
                        )}

                        {showDialog === 'GraphSettings' && (
                            <GraphSettingsDialog
                                initialSelections={schedule.viewSettings.overlays}
                                closeCallback={() => setShowDialog('None')}
                                graphOptionsChanged={setGraphOverlays}
                            />
                        )}

                        {showDialog === 'TableSettings' && (
                            <TableSettingsDialog
                                initialSelections={schedule.viewSettings.tableFilters}
                                closeCallback={() => setShowDialog('None')}
                                tableOptionsChanged={setTableFilterOptions}
                            />
                        )}

                        {showDialog === 'SleepSettings' && (
                            <SleepSettingsDialog
                                scheduleId={schedule.id}
                                closeCallback={(dialogResult: DialogResultEnum, updatedSchedule?: Schedule) => {
                                    if (dialogResult === DialogResultEnum.Completed && updatedSchedule) {
                                        setScheduleWithNewData(updatedSchedule)
                                    }
                                    setShowDialog('None')
                                }}
                            />
                        )}

                        {showDialog === 'ResetSleep' && (
                            <ConfirmationDialog
                                headerText="Discard Sleep Edits"
                                closeCallback={() => setShowDialog('None')}
                                confirmedCallback={async () => {
                                    const [updatedSchedule, message] = await api.resetEditedSleep(schedule.id)
                                    showResponseMessage(message)
                                    setScheduleWithNewData(updatedSchedule)
                                }}
                            >
                                Are you sure you want to discard all sleep edits and reset to auto-sleep defaults?
                            </ConfirmationDialog>
                        )}

                        {showDialog === 'Share' && schedule.userCanEdit === false && (
                            <SharingDialog
                                itemType="schedule"
                                itemIds={[scheduleId]}
                                closeCallback={(status, updatedShares) => {
                                    if (status === DialogResultEnum.Completed && updatedShares) {
                                        toast.success('Sharing attributes have been updated')
                                        if (
                                            updatedShares.filter((x) => x.permissionId === 0).length ===
                                                updatedShares.length &&
                                            updatedShares.length > 0
                                        ) {
                                            // all set to None permission, so update the schedule's permissions locally.
                                            // the schedule will already be updated on the server, but just updating in memory
                                            // avoids hitting the server for this small amount of data
                                            setSchedule((previous) => {
                                                const updated = { ...previous }
                                                updated.permission.amOwnerOfSharedItem = false
                                                updated.permission.sharedWithMePermission = undefined
                                                return updated
                                            })
                                        }
                                    }
                                    setShowDialog('None')
                                }}
                            />
                        )}
                    </ScheduleDetailsContext.Provider>
                    <ErrorDialog
                        headerText="Error"
                        message={errorMessage}
                        closeCallback={() => setErrorMessage(null)}
                    />
                </>
            </GridPageLayout>
            {/* <UnsavedChangesDialog
                showDialog={showPrompt === true}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            /> */}
        </>
    )
}

export default ScheduleDetailsPage
