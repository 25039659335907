import { ChangeEvent, useState } from 'react'
import { Form } from 'react-bootstrap'
import { BaseInputProps } from './FormControlBase'

export interface FormControlTextInputProps extends BaseInputProps {
    value?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    onClick?: React.MouseEventHandler<HTMLOrSVGElement>
    disabled?: boolean
    readOnly?: boolean
    validated?: boolean
    onValidation?: () => boolean
}

const FormControlTextInput = (props: FormControlTextInputProps) => {
    const [hasBeenTouched, setHasBeenTouched] = useState(false)

    const isInvalid = (hasBeenTouched || props.validated) && props.onValidation && !props.onValidation()

    const cursorStyle = props.onClick ? { cursor: 'pointer' } : {}
    const invalidStyle = isInvalid
        ? {
              border: '1px solid rgba(220, 53, 69, 1)',
              //   backgroundImage:
              //       'data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e',
          }
        : {}

    return (
        <>
            <Form.Control
                role="textbox"
                aria-label={props.id || ''}
                type="text"
                value={props.value?.trimStart()}
                onChange={props.onChange}
                onClick={props.onClick}
                onBlur={() => setHasBeenTouched(true)}
                id={props.id}
                name={props.id}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
                isInvalid={isInvalid}
                style={{
                    ...cursorStyle,
                    ...invalidStyle,
                }}
            />
            {props.invalidText && <Form.Control.Feedback type="invalid">{props.invalidText}</Form.Control.Feedback>}
        </>
    )
}

export default FormControlTextInput
