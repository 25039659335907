import useGridLayout from 'hooks/useGridLayout'
import { ChangeEvent, memo, useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { isTagColumn } from 'services/utilities/kendoGridColumnsHelpers'
import gridLayoutActions from 'store/actions/gridLayoutActions'
import { dialogActions } from 'store/dialogsStore'
import { RootState, useAppDispatch } from 'store/store'
import { GridLayout, GridLayoutIdentifier } from 'types/GridLayout'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'
import SearchInput from '../../Inputs/SearchInput'
import classes from './ColumnPickerDialog.module.css'
import ColumnPickerLayoutMenu from './ColumnPickerLayoutMenu'
import SaveAsDialog from './ColumnPickerLayoutSaveAsDialog'

interface ColumnPickerDialogContentProps {
    /**
     * Optionally customzied grid layout.  If not passed in, the original grid layout
     */
    activeGridLayoutCustomized?: GridLayout

    /**
     * Original active grid layout (not customized)
     */
    activeGridLayoutOriginal: GridLayout

    saveAsClicked: () => void
    deleteClicked: () => void
    allGridLayouts: GridLayout[]

    /**
     * List of columns with filter applied
     */
    filteredColumns: string[]

    /**
     * List of columns that cannot be disabled (SFC options)
     */
    alwaysShownColumns: string[]
}

const ColumnPickerDialogContent = ({
    activeGridLayoutCustomized,
    activeGridLayoutOriginal,
    filteredColumns,
    alwaysShownColumns,
    saveAsClicked,
    deleteClicked,
    allGridLayouts,
}: ColumnPickerDialogContentProps) => {
    const dispatch = useAppDispatch()
    const searchText = useSelector<RootState, string>((state) => state.dialogs.gridLayoutDialogSearchText)
    const scrollPosition = useSelector<RootState, number>((state) => state.dialogs.checkboxesScrollPosition)

    const activeGridLayout = activeGridLayoutCustomized || activeGridLayoutOriginal

    const searchFilteredColumns = activeGridLayout.configurationJson.columns.filter(
        (x) => searchText === '' || x.title?.toLowerCase()?.includes(searchText.toLowerCase()),
    )

    useEffect(() => {
        // return to the previous scroll position which is stored in redux because changing
        // column selections causes a re-render which loses the scroll position
        const scrollableBody = document.querySelector('#scrollableColumnPickerBody')!
        scrollableBody.scrollTo(0, scrollPosition)
    }, [scrollPosition])

    return (
        <>
            <div className="d-flex mb-2">
                <p className="mt-1 me-2">Layout:</p>
                <div className="d-block w-100">
                    <ColumnPickerLayoutMenu
                        onSaveClick={saveAsClicked}
                        onEditClick={saveAsClicked}
                        onDeleteClick={deleteClicked}
                        selectedLayout={activeGridLayout}
                        allGridLayouts={allGridLayouts}
                        onLayoutSelected={(layout: GridLayout) => {
                            dispatch(gridLayoutActions.setActiveGridLayout(layout.id))
                        }}
                    />
                </div>
            </div>
            <div className="mb-3">
                <SearchInput
                    searchText={searchText}
                    setSearchText={(txt: string) => {
                        dispatch(dialogActions.setGridLayoutSearchText(txt))
                    }}
                />
            </div>

            <div id="scrollableColumnPickerBody" className={classes.checkboxesContainer}>
                {searchFilteredColumns.length === 0 && (
                    <p>No columns match the search text &quot;{searchText}&quot;.</p>
                )}
                {searchFilteredColumns.map((col) => {
                    const isFiltered = filteredColumns.includes(col.field as string)
                    const isAlwaysShown = alwaysShownColumns.includes(col.field as string)
                    let tooltip = ''
                    if (isFiltered) {
                        tooltip = 'Cannot hide this column because it has a filter applied'
                    } else if (isAlwaysShown) {
                        tooltip = 'This column is configured to always be shown (SFC Options)'
                    }

                    return (
                        <span title={tooltip} key={col.title}>
                            <FormCheck
                                title={tooltip}
                                key={col.title}
                                id={col.title}
                                label={col.title}
                                name={col.title}
                                checked={col.hide !== true || isAlwaysShown}
                                disabled={isFiltered || isAlwaysShown}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    // keep the scroll position; as any updates here will cause a re-render
                                    const currentScrollPosition =
                                        document.querySelector('#scrollableColumnPickerBody')!.scrollTop
                                    dispatch(dialogActions.setScrollPosition(currentScrollPosition))

                                    // use the original redux active layout which hasn't been
                                    // locally altered to show/hide specific tag columns.

                                    // failure to do this will cause the tag columns to be wiped out
                                    // from the database configuration.
                                    const config = { ...activeGridLayoutOriginal.configurationJson }

                                    if (activeGridLayoutCustomized) {
                                        // add in any customized tag columns (that aren't known to be in the database)
                                        // to the original redux layout, so we can save to the DB now.
                                        const tagColumns = activeGridLayoutCustomized.configurationJson.columns.filter(
                                            (x) => isTagColumn(x),
                                        )
                                        tagColumns.forEach((x) => {
                                            if (!config.columns.find((y) => y.field === x.field)) {
                                                config.columns.push(x)
                                            }
                                        })
                                    }

                                    config.columns = config.columns.map((x) => {
                                        if (x.field === col.field) {
                                            return { ...x, hide: !e.target.checked }
                                        }
                                        return x
                                    })

                                    const updatedJson = JSON.stringify(config)
                                    dispatch(gridLayoutActions.updateGridLayout(activeGridLayout.id, updatedJson))
                                }}
                            />
                        </span>
                    )
                })}
            </div>
        </>
    )
}

const getFooterButtons = (closeCallback: () => void): React.ReactNode => {
    return (
        <ButtonCustom isLarge variant="secondary" onClick={closeCallback}>
            Close
        </ButtonCustom>
    )
}

export interface ColumnPickerDialogProps {
    closeCallback: () => void

    /**
     * Pass in the active grid layout.  Passing it here in stead of pulling from state
     * to allow locally modifying it to include tag columns before passing in here.  Needs
     * to be modified upstream anyway to decide what columns to show on the grid.
     */
    activeGridLayoutCustomized?: GridLayout

    /**
     * Display name
     */
    gridTypeName?: string

    /**
     * grid identifier (unique in the UI)
     */
    gridId: GridLayoutIdentifier

    /**
     * List of columns with filter applied
     */
    filteredColumns: string[]

    /**
     * List of columns that cannot be disabled (SFC options)
     */
    alwaysShownColumns: string[]
}

const ColumnPickerDialog = (props: ColumnPickerDialogProps) => {
    const [showSaveAsDialog, setShowSaveAsDialog] = useState(false)
    const [selectedGridLayout, allGridLayouts] = useGridLayout(props.gridId)
    const dispatch = useAppDispatch()

    if (!selectedGridLayout) {
        throw new Error('No grid configuration found')
    }

    if (showSaveAsDialog) {
        // only show the child dialog, not both dialogs at the same time
        return (
            <SaveAsDialog
                typeDescription="Layout"
                saveAsCallback={(itemName: string, globalSwitchEnabled: boolean) => {
                    dispatch(gridLayoutActions.saveGridLayoutAs(selectedGridLayout.id, itemName))
                }}
                closeCallback={() => setShowSaveAsDialog(false)}
                globalDefaultValue={false}
                itemName={selectedGridLayout.name}
            />
        )
    }

    return (
        <SimpleDialog
            headerText={`Show / Hide ${props.gridTypeName || ''} Columns`}
            closeCallback={props.closeCallback}
            noScroll
            getFooterButtons={() => getFooterButtons(props.closeCallback)}
        >
            <ColumnPickerDialogContent
                {...props}
                allGridLayouts={allGridLayouts}
                activeGridLayoutOriginal={selectedGridLayout}
                saveAsClicked={() => setShowSaveAsDialog(true)}
                deleteClicked={() => {
                    // override to allow an alert as we are already in a dialog; seems like the right approach
                    // eslint-disable-next-line no-alert
                    if (window.confirm(`Are you sure you want to delete the layout "${selectedGridLayout.name}"?`)) {
                        dispatch(gridLayoutActions.deleteGridLayout(selectedGridLayout.id))
                    }
                }}
            />
        </SimpleDialog>
    )
}

export default memo(ColumnPickerDialog)
