import { ChangeEvent } from 'react'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface CommonFieldsProps {
    nameField?: string
    descriptionField?: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}
const ProfileCommonFields = (props: CommonFieldsProps) => {
    return (
        <>
            <InputTextFormRow
                labelText="Name"
                fieldId="name"
                subText="A unique name for this module profile"
                value={props.nameField}
                onChange={props.onChange}
                required
                invalidText="Enter a unique name"
            />
            <InputTextAreaFormRow
                labelText="Description"
                fieldId="description"
                subText="A description for this module profile"
                value={props.descriptionField}
                onChange={props.onChange}
            />
        </>
    )
}

export default ProfileCommonFields
