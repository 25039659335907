import { SortDescriptor } from '@progress/kendo-data-query'
import { ExcelExport, ExcelExportColumnProps } from '@progress/kendo-react-excel-export'
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid'
import useGridLayout from 'hooks/useGridLayout'
import usePalettes from 'hooks/usePalettes'
import { useCallback, useState } from 'react'
import { getColumnsFromGridLayout } from 'services/utilities/kendoGridColumnsHelpers'
import { KendoGridColumn } from 'types/GridLayout'
import { Duty } from 'types/HazardClassCase'
import KendoGridCustom from 'views/Common/Kendo/KendoGridCustom'

const HazardClassCaseActualDutiesTable = (props: {
    exportRef: React.MutableRefObject<ExcelExport | null>
    patternExportFilename: string
    sortDescriptor: SortDescriptor
    dataStateChanged: (e: GridDataStateChangeEvent) => void
    duties: Duty[]
}) => {
    const palettes = usePalettes()
    const [activeGridLayout] = useGridLayout('hazardClassDuties')

    const getColumnsHelper = useCallback(() => {
        if (!palettes || !activeGridLayout) return []
        return getColumnsFromGridLayout({ gridLayout: activeGridLayout, hazardClassGridOptions: { palettes } })
    }, [activeGridLayout, palettes])

    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(getColumnsHelper())
    const exportFormatColumns = stateColumns.map<ExcelExportColumnProps>((col) => ({
        field: col.field,
        title: col.title,
    }))

    return (
        <KendoGridCustom
            scrollable="none"
            noSelection
            gridLayoutName="hazardClassDuties"
            centeredContent
            filterable={false}
            pageable={false}
            data={props.duties}
            columns={stateColumns}
            selectedRowsState={{}}
            onSetSelectedRowsState={() => {}}
            setColumnVisibility={() => {}}
            excelExportRef={props.exportRef}
            excelExportFilename={`${props.patternExportFilename}.xlsx`}
            excelExportColumns={exportFormatColumns}
            dataState={{
                sort: [props.sortDescriptor],
            }}
            onDataStateChange={props.dataStateChanged}
        />
    )
}

export default HazardClassCaseActualDutiesTable
