import { GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { DurationUnitTypeNames, EventTriggerReferenceTypeNames, OffsetUnitTypeNames } from 'types/EnumTypes'
import { AutoMarkerRules, EventFilter } from 'types/ProfileInterfaces'
import { getDefaultAutoMarkerRules } from 'types/ProfileInterfacesDefaults'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import EventFilterDialog from 'views/Common/EventFilter/EventFilterDialog'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import InformationDialog from 'views/Common/GenericDialogs/InformationDialog'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'
import GridCheckCell from 'views/Common/Kendo/GridCellComponents/GridCheckCell'
import GridDropDownCell from 'views/Common/Kendo/GridCellComponents/GridDropDownCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'

interface AutoMarkerRulesDialogProps {
    profileId: string
    onClose: () => void
}

const AutoMarkerRulesDialog = (props: AutoMarkerRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const profile = useAppSelector((s) => s.profile.profileAutoMarkers)
    const [tempRules, setTempRules] = useState<AutoMarkerRules[]>(profile?.autoMarkerRules || [])
    const [tempRule, setTempRule] = useState<AutoMarkerRules | undefined>(undefined)
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    const [showEventFilter, setShowEventFilter] = useState(false)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    // operations
    const doNewRule = (existRecord?: AutoMarkerRules) => {
        const newRule = getDefaultAutoMarkerRules(existRecord, tempRules)
        setTempRule(newRule)
        setShowEventFilter(true)
        setSelectedRowsState({ [newRule.id]: true })
    }
    const doCopyRule = () => {
        if (selectedIds.length !== 1) return

        // get selected rule to copy as new
        const item = tempRules.find((i) => i.id === selectedIds[0])
        if (item) {
            doNewRule(item)
        }
    }
    const doDeleteRules = () => {
        setShowDialog(deleteDialog)
    }

    function changeOrder(direction: MoveDirection) {
        setTempRules(reorderItemsList(selectedIds, tempRules, direction))
    }

    // grid
    const EDIT_FIELD = 'inEdit'
    const enterEditHandler = useCallback(
        (dataItem: AutoMarkerRules, field: string | undefined) => {
            const newList = tempRules.map((item) => ({
                ...item,
                [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
            }))
            setTempRules(newList)
            setSelectedRowsState({ [dataItem.id]: true })
        },
        [tempRules],
    )
    const exitEditHandler = () => {
        const newData = tempRules.map((item) => ({ ...item, [EDIT_FIELD]: undefined }))
        setTempRules(newData)
    }

    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field || ''
        if (['eventOffset'].indexOf(field) >= 0 && event.value !== -1 && event.value < 0) {
            return
        }
        if (['markerDuration'].indexOf(field) >= 0 && event.value !== -1 && event.value < 1) {
            return
        }
        const newList = tempRules.map((item) => {
            if (item.id === event.dataItem.id) {
                return {
                    ...item,
                    [field]: event.value,
                }
            }
            return item
        })
        setTempRules(newList)
    }

    const getColumns = useMemo((): Array<KendoGridColumn> => {
        const getDropdownCell = (
            gridCellProps: GridCellProps,
            type: {
                [key in string]: string
            },
        ) => <GridDropDownCell {...gridCellProps} type={type} enterEdit={enterEditHandler} editField={EDIT_FIELD} />

        const getCheckboxCell = (gridCellProps: GridCellProps) => (
            <GridCheckCell {...gridCellProps} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
        )
        const columns: Array<KendoGridColumn> = [
            {
                title: 'Name',
                field: 'eventFilter.name',
                cell: (cellProps: GridCellProps) => {
                    const rule = cellProps.dataItem as AutoMarkerRules
                    const cellContent = (
                        <TransparentButton
                            onClick={() => {
                                setTempRule(rule)
                                setShowEventFilter(true)
                            }}
                        >
                            {rule.eventFilter.name}
                        </TransparentButton>
                    )
                    return FormattedNameCell(cellProps, cellContent)
                },
            },
            {
                title: 'Event Trigger',
                headerClassName: 'MultiLineCellHeader',
                children: [
                    {
                        title: 'Offset',
                        field: 'eventOffset',
                        editor: 'numeric',
                    },
                    {
                        title: 'Offset Unit',
                        field: 'eventOffsetUnit',
                        cell: (c: GridCellProps) => getDropdownCell(c, OffsetUnitTypeNames),
                    },
                    {
                        title: 'Reference',
                        field: 'eventOffsetReference',
                        cell: (c: GridCellProps) => getDropdownCell(c, EventTriggerReferenceTypeNames),
                    },
                ],
            },
            {
                title: 'Marker to Create',
                headerClassName: 'MultiLineCellHeader',
                children: [
                    {
                        title: 'Name',
                        field: 'markerName',
                        editor: 'text',
                    },
                    {
                        title: 'Duration',
                        field: 'markerDuration',
                        editor: 'numeric',
                    },
                    {
                        title: 'Duration Unit',
                        field: 'markerDurationUnit',
                        cell: (c: GridCellProps) => getDropdownCell(c, DurationUnitTypeNames),
                    },
                    {
                        title: 'Critical',
                        field: 'markerIsCritical',
                        cell: (c: GridCellProps) => getCheckboxCell(c),
                        editor: 'boolean',
                    },
                ],
            },
        ]
        return columns
    }, [enterEditHandler])

    const deleteDialog = useMemo(
        () => (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Auto-Marker Rule"
                setTempItems={setTempRules}
                tempItems={tempRules}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        ),
        [selectedIds, tempRules],
    )

    if (!profile) {
        return <></>
    }

    const onSave = () => {
        const eventOffsetValidation = tempRules.find(
            (x) => x.eventOffset !== undefined && x.eventOffset < 0 && x.eventOffset !== -1,
        )
        const emptyMarkerNameItem = tempRules.find((x) => x.markerName.length === 0 || !x.markerName.trim())
        const markerDurationValidation = tempRules.find(
            (x) => x.markerDuration !== undefined && x.markerDuration < 0 && x.eventOffset === -1,
        )

        if (eventOffsetValidation || markerDurationValidation || emptyMarkerNameItem) {
            const messagesArray: JSX.Element[] = []
            if (eventOffsetValidation) {
                const newList = tempRules.map((item) => ({
                    ...item,
                    [EDIT_FIELD]: item.id === eventOffsetValidation.id ? 'eventOffset' : undefined,
                }))
                setTempRules(newList)
                messagesArray.push(<>- Event Trigger Offset must be 0 or more</>, <br />)
            }
            if (emptyMarkerNameItem) {
                const newList = tempRules.map((item) => ({
                    ...item,
                    [EDIT_FIELD]: item.id === emptyMarkerNameItem.id ? 'markerName' : undefined,
                }))
                setTempRules(newList)
                messagesArray.push(<>- Auto-Marker Name must be entered</>, <br />)
            }
            if (markerDurationValidation) {
                const newList = tempRules.map((item) => ({
                    ...item,
                    [EDIT_FIELD]: item.id === markerDurationValidation.id ? 'markerDuration' : undefined,
                }))
                setTempRules(newList)
                messagesArray.push(<>- Auto-Marker Duration must be more than 0</>, <br />)
            }
            const messages = (
                <>
                    {messagesArray.map((element, index) => (
                        <Fragment key={index}>{element}</Fragment>
                    ))}
                </>
            )
            showMessage('Warning', messages)
        } else {
            const updatedProfile = { ...profile, autoMarkerRules: tempRules }
            dispatch(profileActions.setProfileByType({ profile: updatedProfile, type: 'Markers' }))
            props.onClose()
        }
    }

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender originalProps={propsThe} td={td} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
    )

    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitEditHandler} editField={EDIT_FIELD} />
    )

    // event filter
    const handleEventFilterClose = (result: DialogResultEnum, eventFilter?: EventFilter) => {
        if (result === DialogResultEnum.Completed && eventFilter && tempRule) {
            const itemIndex = tempRules.findIndex((x) => x.id === tempRule.id)
            if (itemIndex >= 0) {
                const newList = tempRules.map((s) => {
                    if (s.id === tempRule.id) return { ...s, eventFilter }
                    return s
                })
                setTempRules(newList)
            } else {
                setTempRules([...tempRules, { ...tempRule, eventFilter }])
            }
        }
        setShowEventFilter(false)
    }

    // header
    const headerContent = 'Auto-Marker Rules'

    // grid layout
    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={tempRules}
                                selectedIds={selectedIds}
                                onAddClick={doNewRule}
                                onCopyClick={doCopyRule}
                                onDeleteClick={doDeleteRules}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={tempRules}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        onItemChange={itemEditedHandler}
                    />
                </Col>
            </Row>
        </Container>
    )

    const footerContent = <FormFooter onCancel={props.onClose} onOk={onSave} />

    // dialogs
    const showMessage = (header: string, message: string | JSX.Element) => {
        const infoDialog = (
            <InformationDialog
                headerText={header}
                message={message}
                onClose={() => {
                    setShowDialog(<></>)
                }}
            />
        )

        setShowDialog(infoDialog)
    }

    return (
        <>
            {showEventFilter ? (
                <EventFilterDialog eventFilter={tempRule?.eventFilter} onClose={handleEventFilterClose} />
            ) : (
                <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={props.onClose}>
                    <>{gridContent}</>
                </DialogLayout>
            )}
            {showDialog}
        </>
    )
}

export default AutoMarkerRulesDialog
