import React, { useState } from 'react'
import { updateTemplateProfileId } from 'store/actions/templateActions'
import { useAppDispatch } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { getProfileTitleByType } from 'types/ProfileInterfaces'
import IconButton from 'views/Common/Buttons/IconButton'
import ProfileSelectorTool from './ProfileSelectorTool'

interface Props {
    type: ProfileModuleTypes
    dataSourceId?: string
    onDelete?: (deleteItemId: string) => void
    children: React.ReactNode
}
const ProfileExpansionPanel = ({ dataSourceId, type, onDelete, children }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useAppDispatch()

    const togglePanel = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div style={{ border: '1px solid #ccc', width: '100%' }}>
            <div
                onClick={togglePanel}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    backgroundColor: '#f7f7f7',
                    cursor: 'pointer',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i
                        className={isOpen ? 'bi-chevron-down' : 'bi-chevron-right'}
                        style={{
                            fontSize: '19px',
                            marginRight: '10px',
                        }}
                    />
                    <h6 style={{ width: '160px', margin: 0 }}>{getProfileTitleByType(type)}</h6>
                </div>
                <div
                    style={{ flexGrow: 1, textAlign: 'center', marginLeft: '50px', marginRight: '100px' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ProfileSelectorTool
                        profileType={type}
                        dataSourceId={dataSourceId}
                        onSave={() => {
                            //
                        }}
                        onSelected={async (selectedProfileId, oldId) => {
                            await dispatch(updateTemplateProfileId(selectedProfileId, oldId, type))
                        }}
                    />
                </div>
                {type === 'DataSource' && (
                    <IconButton
                        tooltip="Delete"
                        toolbarLeftMargin
                        icon="bi-trash"
                        onClick={(e) => {
                            e.stopPropagation()
                            onDelete?.(dataSourceId || '')
                        }}
                    />
                )}
            </div>
            {isOpen && <div style={{ padding: '10px', backgroundColor: '#fff' }}>{children}</div>}
        </div>
    )
}

export default ProfileExpansionPanel
