import { process, SortDescriptor } from '@progress/kendo-data-query'
import { ExcelExport, ExcelExportColumnProps } from '@progress/kendo-react-excel-export'
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid'
import useGridLayout from 'hooks/useGridLayout'
import usePalettes from 'hooks/usePalettes'
import usePrintPreviewGlobalState from 'hooks/usePrintPreviewGlobalState'
import useRecalculateItemsStatus from 'hooks/useRecalculateItemsStatus'
import { flatten } from 'lodash'
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { getColumnsFromGridLayout } from 'services/utilities/kendoGridColumnsHelpers'
import { handleApiError, handleRecalculationError } from 'services/utilities/toastrUtils'
import { RecalculationState } from 'store/recalculationStore'
import { RootState } from 'store/store'
import { HazardClass, HazardClassPatternsSort } from 'types/HazardClass'
import HazardClassCase, { Duty } from 'types/HazardClassCase'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import EllipsisDropdown, {
    EllipsisDropdownHeader,
    EllipsisDropdownItem,
    IndentedDropdownItem,
    ItemWithIcon,
} from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import InsightsRefreshButton from 'views/Common/Buttons/InsightsRefreshButton'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import SingleInputDialog from 'views/Common/GenericDialogs/SingleInputDialog'
import CenteredContent from 'views/Common/Layout/CenteredContent'
import GridPageLayout from 'views/Common/Layout/PageLayout'
import RowBreadcrumbs from 'views/Common/Layout/RowBreadcrumbs'
import HazardClassPanel, { HazardClassPanelOverallHeight } from 'views/Insights/Components/HazardClassPanel'
import PatternPanel from 'views/Insights/PatternPanel/PatternPanel'
import RenamePatternDialog from 'views/Insights/PatternPanel/RenamePatternDialog'

type DialogMode = 'RenamePattern' | 'LimitResultsCustom' | 'ConfirmDelete' | 'None'

interface HazardClassDuty extends Duty {
    pattern: string
}

const HazardClassView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const palettes = usePalettes()
    const [isPrintPreview, setIsPrintPreview] = useState(false)
    // using multiple refs: https://stackoverflow.com/a/56063129/210910
    const patternPanelRefs = useRef<Array<HTMLElement | null>>([])
    const [renamePattern, setRenamePattern] = useState<HazardClassCase | null>(null)
    const [contentHeight, setContentHeight] = useState(0)
    const [contentWidth, setContentWidth] = useState(0)
    const [hazardClass, setHazardClass] = useState<HazardClass | undefined>()
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')
    const [synchronousRefreshTime, setSynchronousRefreshTime] = useState<Date | null>(null)
    const [isSynchronousRefreshing, setIsSynchronousRefreshing] = useState(false)
    const requiresRefresh = useSelector<RootState, number>((x) => x.insights.hazardClassRequiresRefresh)
    const [activeGridLayout] = useGridLayout('hazardClassDuties')
    const exportVisibleRef = useRef<ExcelExport | null>(null)
    const exportAllRef = useRef<ExcelExport | null>(null)

    const synchronousOnly = useSelector<RootState, boolean | undefined>((x) => x.app.user?.refreshInsightsSynchronously)

    const recalculationState = useSelector<RootState, RecalculationState>((x) => x.insightsRecalculation)
    const isRefreshing =
        (hazardClass !== undefined && recalculationState.itemsBeingRecalculated.includes(hazardClass.id)) ||
        isSynchronousRefreshing

    const api = globals.getApi()
    const reportingApi = api.getReportingApi()
    const processingApi = api.getProcessingApi()

    const [recalculationCompletedTime, , insightsRecalculationState] = useRecalculateItemsStatus('Insights', 2000)

    const params = useParams() as any
    const defaultDutiesTableSort: SortDescriptor = useMemo(() => ({ field: 'insightsScore', dir: 'asc' }), [])

    const loadHazardClass = useCallback(
        async (hazardClassId: number) => {
            const loadedHazardClass = await reportingApi.getHazardClass(hazardClassId)
            document.title = loadedHazardClass.name
            // set the default sort for the pattern duties tables
            if (loadedHazardClass.casesParsed) {
                loadedHazardClass.casesParsed.forEach((pattern) => {
                    pattern.dutiesGridSort = defaultDutiesTableSort
                })
            }
            setHazardClass(loadedHazardClass)
        },
        [reportingApi, defaultDutiesTableSort],
    )

    useEffect(() => {
        const loadData = async () => {
            try {
                const hazardClassId = parseInt(params.id)
                await loadHazardClass(hazardClassId)
                setIsSynchronousRefreshing(false)
                if (recalculationCompletedTime) {
                    toast.success('Insights data has been refreshed')
                }
            } catch (err: any) {
                handleApiError(err)
            }
        }
        loadData()
    }, [params.id, reportingApi, recalculationCompletedTime, synchronousRefreshTime, loadHazardClass, requiresRefresh])

    usePrintPreviewGlobalState(isPrintPreview)

    const updateHazardClassSort = async (sort: HazardClassPatternsSort) => {
        const updatedHazardClass = { ...hazardClass! }
        if (updatedHazardClass.patternsSort === sort) {
            return
        }
        updatedHazardClass.patternsSort = sort
        // this api call could be modified to return the updated data rather than making 2 calls
        await reportingApi.saveHazardClass(updatedHazardClass, false, true)
        await loadHazardClass(hazardClass!.id)
    }

    const updateHazardClassLimit = async (limit: number) => {
        const updatedHazardClass = { ...hazardClass! }
        if (updatedHazardClass.patternsLimit === limit) {
            return
        }
        updatedHazardClass.patternsLimit = limit
        await reportingApi.saveHazardClass(updatedHazardClass, false, false)
        setHazardClass(updatedHazardClass)
    }

    const updateHazardShowDuties = async (hideDuties: boolean) => {
        const updatedHazardClass = { ...hazardClass! }
        if (updatedHazardClass.hidePatternDuties === hideDuties) {
            return
        }
        updatedHazardClass.hidePatternDuties = hideDuties
        await reportingApi.saveHazardClass(updatedHazardClass, false, false)
        setHazardClass(updatedHazardClass)
    }

    const clickRefresh = (hc: HazardClass) => {
        if (synchronousOnly) {
            setIsSynchronousRefreshing(true)
            setSynchronousRefreshTime(new Date())
        } else {
            processingApi.beginItemRefresh([hc.id], 'Insights', dispatch)
        }
    }

    let customMenuItemText = 'Custom...'
    let isCustomLimit = false

    if (hazardClass) {
        isCustomLimit = ![5, 10, 25].includes(hazardClass.patternsLimit)
        if (isCustomLimit) {
            customMenuItemText = `Custom (${hazardClass?.patternsLimit})`
        }
    }

    const printButtons = (
        <div className="noPrint">
            <IconButton
                tooltip="Back to Schedule Details"
                onClick={() => setIsPrintPreview(false)}
                icon="bi-arrow-left"
            />
            <IconButton tooltip="Print" onClick={() => window.print()} toolbarLeftMargin icon="bi-printer" />
        </div>
    )

    const toolbarButtons = hazardClass && (
        <>
            <>
                <InsightsRefreshButton
                    isRefreshing={isRefreshing}
                    requiresRefresh={hazardClass.requiresRecalculation}
                    onClick={() => clickRefresh(hazardClass)}
                >
                    Refresh
                </InsightsRefreshButton>
                <SeperatorVertical />
            </>

            <IconButton
                tooltip="Edit Hazard Class Configuration"
                onClick={() => navigate(`/insights/${hazardClass.id}/configuration`)}
                toolbarLeftMargin
                disabled={isRefreshing}
                icon="bi-pencil"
            />
            <SeperatorVertical />

            <IconButton
                tooltip="Print Preview"
                toolbarLeftMargin
                disabled={isRefreshing}
                onClick={() => setIsPrintPreview(true)}
                icon="bi-printer"
            />

            <EllipsisDropdown>
                <EllipsisDropdownItem onClick={() => navigate(`/insights/${hazardClass.id}/configuration`)}>
                    <ItemWithIcon bootstrapIconClass="bi-pencil">Edit Hazard Class Configuration</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => exportVisibleRef.current!.save()}>
                    <ItemWithIcon bootstrapIconClass="bi-file-earmark-arrow-down">Export Visible Patterns</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => exportAllRef.current!.save()}>
                    <ItemWithIcon bootstrapIconClass="bi-file-earmark-arrow-down">Export All Patterns</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => setIsPrintPreview(true)}>
                    <ItemWithIcon bootstrapIconClass="bi-printer">Print</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownItem onClick={() => navigate(`/Insights/${hazardClass.id}/Copy`)}>
                    Copy
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => setDialogMode('ConfirmDelete')}>Delete</EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownHeader>Detail</EllipsisDropdownHeader>
                <EllipsisDropdownItem onClick={() => updateHazardShowDuties(!hazardClass.hidePatternDuties)}>
                    <IndentedDropdownItem checked={!hazardClass.hidePatternDuties} checkBootstrapIcon="bi-check">
                        Show Duties Table
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownHeader bootstrapIconClass="bi-sort-down-alt">Sort by</EllipsisDropdownHeader>
                <EllipsisDropdownItem onClick={() => updateHazardClassSort('MatchingDutiesCount')}>
                    <IndentedDropdownItem checked={hazardClass.patternsSort === 'MatchingDutiesCount'}>
                        Matching Duties Count
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => updateHazardClassSort('MatchingDutiesPercent')}>
                    <IndentedDropdownItem checked={hazardClass.patternsSort === 'MatchingDutiesPercent'}>
                        Matching Duties %
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownHeader>Limit Results</EllipsisDropdownHeader>
                <EllipsisDropdownItem onClick={() => updateHazardClassLimit(5)}>
                    <IndentedDropdownItem checked={hazardClass.patternsLimit === 5}>Top 5</IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => updateHazardClassLimit(10)}>
                    <IndentedDropdownItem checked={hazardClass.patternsLimit === 10}>Top 10</IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => updateHazardClassLimit(25)}>
                    <IndentedDropdownItem checked={hazardClass.patternsLimit === 25}>Top 25</IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => setDialogMode('LimitResultsCustom')}>
                    <IndentedDropdownItem checked={isCustomLimit}>{customMenuItemText}</IndentedDropdownItem>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </>
    )

    const breadCrumbs = (
        <RowBreadcrumbs entries={[{ label: 'Insights Dashboard', url: '/insights' }, { label: 'Hazard Class' }]} />
    )

    let patternsContainerStyle: CSSProperties = {}
    if (!isPrintPreview) {
        patternsContainerStyle = {
            height: contentHeight - HazardClassPanelOverallHeight,
            overflowY: 'auto',
        }
    }

    handleRecalculationError(insightsRecalculationState.recalculationErrorMessage, dispatch, 'insights')

    const extraExportColumns: ExcelExportColumnProps[] = [
        {
            field: 'pattern',
            title: 'Pattern ID/Name',
        },
        {
            field: 'scheduleStartDate',
            title: 'Schedule Start Date',
        },
    ]

    if (!palettes) {
        return null
    }

    const exportFormatColumns = [
        ...extraExportColumns,
        ...getColumnsFromGridLayout({
            gridLayout: activeGridLayout,
            hazardClassGridOptions: { palettes },
        }).map<ExcelExportColumnProps>((col) => ({
            field: col.field,
            title: col.title,
        })),
    ]

    return (
        <>
            {hazardClass && hazardClass.casesParsed && (
                <ExcelExport
                    ref={exportVisibleRef}
                    fileName={`${hazardClass.name}_HazardClass_MatchingDuties.xlsx`}
                    data={flatten(
                        hazardClass.casesParsed
                            .slice(0, hazardClass.patternsLimit)
                            .filter((x) => !x.hidden)
                            .map<HazardClassDuty[]>((pattern) => {
                                // this ensures that the overall HC export sorting matches individual tables
                                const sortedDuties = process(pattern.duties, { sort: [pattern.dutiesGridSort!] })
                                return sortedDuties.data.map((duty) => ({
                                    ...duty,
                                    pattern: pattern.name || pattern.id.toString(),
                                }))
                            }),
                    )}
                    columns={exportFormatColumns}
                />
            )}

            {hazardClass && hazardClass.casesParsed && (
                <ExcelExport
                    ref={exportAllRef}
                    fileName={`${hazardClass.name}_HazardClass_MatchingDuties.xlsx`}
                    data={flatten(
                        hazardClass.casesParsed.map<HazardClassDuty[]>((pattern) => {
                            // this ensures that the overall HC export sorting matches individual tables
                            const sortedDuties = process(pattern.duties, { sort: [pattern.dutiesGridSort!] })
                            return sortedDuties.data.map((duty) => ({
                                ...duty,
                                pattern: pattern.name || pattern.id.toString(),
                            }))
                        }),
                    )}
                    columns={exportFormatColumns}
                />
            )}

            {dialogMode === 'RenamePattern' && renamePattern && (
                <RenamePatternDialog
                    hazardClassId={hazardClass?.id!}
                    originalName={renamePattern.name}
                    patternId={renamePattern.id!}
                    isHidden={renamePattern.hidden}
                    closeCallback={(result, newName) => {
                        if (result === DialogResultEnum.Completed && newName) {
                            // need to update the data
                            setHazardClass((previous) => {
                                const updatedHazardClass = { ...previous! }
                                const updatedPattern = updatedHazardClass.casesParsed?.find(
                                    (x) => x.id === renamePattern.id,
                                )!
                                updatedPattern.name = newName
                                return updatedHazardClass
                            })
                        }
                        setDialogMode('None')
                    }}
                />
            )}

            {dialogMode === 'LimitResultsCustom' && (
                <SingleInputDialog
                    dialogTitle="Maximum Pattern Results to Show"
                    inputPlaceholder="Enter a number"
                    inputType="number"
                    defaultValue="30"
                    closeCallback={async (result, newValue) => {
                        setDialogMode('None')
                        if (result === DialogResultEnum.Completed && newValue) {
                            const newLimit = parseInt(newValue)
                            await updateHazardClassLimit(newLimit)
                        }
                    }}
                />
            )}

            {dialogMode === 'ConfirmDelete' && (
                <ConfirmationDialog
                    headerText="Delete Hazard Class"
                    closeCallback={() => setDialogMode('None')}
                    confirmedCallback={async () => {
                        // delete this Hazard Class and navigate to the dashboard
                        await reportingApi.deleteHazardClass(hazardClass!.id)
                        navigate('/Insights/', { replace: true })
                    }}
                >
                    Are you sure you want to delete this Hazard Class?
                </ConfirmationDialog>
            )}

            <GridPageLayout
                breadcrumbs={breadCrumbs}
                headingContent={hazardClass?.name || ''}
                buttons={isPrintPreview ? printButtons : toolbarButtons}
                onMainContentHeightChange={setContentHeight}
                onMainContentWidthChange={setContentWidth}
            >
                {hazardClass && (
                    <>
                        <HazardClassPanel
                            isPrintPreview={isPrintPreview}
                            hazardClass={hazardClass}
                            patternSeriesClicked={(hcCase) =>
                                patternPanelRefs.current[hcCase.id]?.scrollIntoView({ behavior: 'smooth' })
                            }
                        />
                        {hazardClass.casesParsed && hazardClass.casesParsed.length > 0 && (
                            <div style={patternsContainerStyle}>
                                {hazardClass.casesParsed.slice(0, hazardClass.patternsLimit).map((hcCase, index) => (
                                    <PatternPanel
                                        key={index}
                                        isPrintPreview={isPrintPreview}
                                        setRef={(el: HTMLElement) => {
                                            patternPanelRefs.current[hcCase.id] = el
                                        }}
                                        case={hcCase}
                                        hazardClass={hazardClass}
                                        panelWidth={contentWidth}
                                        renameClicked={(isHidden) => {
                                            setRenamePattern({ ...hcCase, hidden: isHidden })
                                            setDialogMode('RenamePattern')
                                        }}
                                        getCaseById={(parentId) =>
                                            hazardClass.casesParsed?.find((x) => x.id === parentId)?.index ?? 0
                                        }
                                        parentClicked={(parentId) => {
                                            patternPanelRefs.current[parentId]?.scrollIntoView({ behavior: 'smooth' })
                                        }}
                                        dataStateChanged={(e: GridDataStateChangeEvent) => {
                                            setHazardClass((previous) => {
                                                const updated = { ...previous! }
                                                const thisCase = updated.casesParsed!.find((x) => x.id === hcCase.id)!
                                                // this is the key part: update the sort in the state, so we have it in this component
                                                // for exporting the entire HC with all patterns, but also we pass it back down
                                                // into child components for sorting at the individual grid level
                                                thisCase.dutiesGridSort = e.dataState.sort![0]
                                                if (!thisCase.dutiesGridSort) {
                                                    // handles the case of the user "unsorting" (eg, click a header three times)
                                                    thisCase.dutiesGridSort = defaultDutiesTableSort
                                                }
                                                return updated
                                            })
                                        }}
                                    />
                                ))}

                                {!isPrintPreview && (
                                    <CenteredContent>
                                        <ButtonCustom
                                            isLarge
                                            onClick={() => {
                                                let limit = hazardClass.patternsLimit
                                                if (limit === 5) {
                                                    limit += 5
                                                } else if (limit === 10) {
                                                    limit += 15
                                                } else if (limit >= 25) {
                                                    limit += 5
                                                }
                                                updateHazardClassLimit(limit)
                                            }}
                                            disabled={hazardClass.casesParsed.length <= hazardClass.patternsLimit}
                                        >
                                            Load More
                                        </ButtonCustom>
                                    </CenteredContent>
                                )}
                            </div>
                        )}
                    </>
                )}
            </GridPageLayout>
        </>
    )
}

export default HazardClassView
