import Axios from 'services/axios/axios-sfc'
import { SettingValue, SystemSetting } from 'types/SystemSetting'
import SFApiBase from './sfApiBase'

class SFSystemSettingApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    getSystemSettings = async (settingCatalog: string): Promise<SystemSetting[]> => {
        return (
            await this.axios.get(this.baseUrl + `/Api/SystemSettings?settingType=${settingCatalog}`, this.getConfig())
        ).data as SystemSetting[]
    }

    updateSystemSettings = async (settings: SettingValue[]) => {
        await this.axios.put(this.baseUrl + '/Api/SystemSettings', settings, this.getConfig())
    }

    getSettingValue = async (settingName: string): Promise<string> => {
        return (
            await this.axios.get(
                this.baseUrl + `/Api/SystemSettings/GetSettingValue?name=${settingName}`,
                this.getConfig(),
            )
        ).data as string
    }

    getSettingValues = async (settingNames: string[]): Promise<string[]> => {
        return (
            await this.axios.post(this.baseUrl + '/Api/SystemSettings/GetSettingValues', settingNames, this.getConfig())
        ).data as string[]
    }
}

export default SFSystemSettingApi
