import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import dialogsReducer from './dialogsStore'
import appReducer from './globalStore'
import gridLayoutsReducer from './gridLayoutStore'
import insightsReducer from './insightsStore'
import palettesReducer from './palettesStore'
import profileReducer from './profileStore'
import { insightsRecalculationReducer, reportsRecalculationReducer } from './recalculationStore'
import reportsReducer from './reportingStore'

const store = configureStore({
    reducer: {
        reports: reportsReducer,
        insights: insightsReducer,
        reportsRecalculation: reportsRecalculationReducer,
        insightsRecalculation: insightsRecalculationReducer,
        gridLayouts: gridLayoutsReducer,
        dialogs: dialogsReducer,
        app: appReducer,
        profile: profileReducer,
        palettes: palettesReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export type RootState = ReturnType<typeof store.getState>
// export default store
export type AppDispatch = typeof store.dispatch

// useAppDispatch and useAppSelector are used in place of useDispatch and useSelector to work
// with types of "thunks" such as in gridLayoutsStore.ts
// see: https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
