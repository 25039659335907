import { EventTypeCreationTypeNames, getRecordKeyValues, LabelFilterTypeNames } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { MultiColumnFormRow, MultiColumnFormRowProps, SwitchFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'

interface CommonDataSourceParameterProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const NonCrewingEventsParameters = (props: CommonDataSourceParameterProps) => {
    const labelFilterMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Label Filter',
        subText: 'Which non-crewing events to include',
        invalidText: '',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: props.profile.labelFilterType,
                    onChange: props.onChange,
                    id: 'labelFilterType',
                    options: getRecordKeyValues(LabelFilterTypeNames),
                },
            },
            {
                colType: 'textInput',
                inputTextProps: {
                    value: props.profile.labelFilterList,
                    onChange: props.onChange,
                    id: 'labelFilterList',
                },
            },
        ],
    }

    const durationFilterMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Duration Filter',
        subText: 'Include events between durations',
        invalidText: '',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.durationFilterEnable,
                    onChange: props.onChange,
                    id: 'durationFilterEnable',
                },
            },
            {
                colType: 'numericInput',
                colWidth: 2,
                inputNumericProps: {
                    value: props.profile.durationFilterRangeStart,
                    onChange: props.onChange,
                    id: 'durationFilterRangeStart',
                    invalidText: 'Enter a value of 0 or greater',
                },
            },
            {
                colWidth: 1,
                colType: 'text',
                value: 'and',
            },
            {
                colType: 'numericInput',
                colWidth: 2,
                inputNumericProps: {
                    value: props.profile.durationFilterRangeEnd,
                    onChange: props.onChange,
                    id: 'durationFilterRangeEnd',
                    invalidText: 'Enter a value of 0 or greater',
                },
            },
        ],
    }

    const eventTypeMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Event Type',
        subText: 'How non-crewing events should be created',
        invalidText: '',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: props.profile.eventTypeCreationType,
                    onChange: props.onChange,
                    id: 'eventTypeCreationType',
                    options: getRecordKeyValues(EventTypeCreationTypeNames),
                },
            },
            {
                colType: 'textInput',
                inputTextProps: {
                    value: props.profile.eventTypeCreationList,
                    onChange: props.onChange,
                    id: 'eventTypeCreationList',
                },
            },
        ],
    }

    return (
        <>
            <IndentedBlock headingText="Non-Crewing Events" subheadingText="How to handle non-crewing events">
                <MultiColumnFormRow {...labelFilterMultiColumn} />

                {props.profile.durationFilterEnable ? (
                    <MultiColumnFormRow {...durationFilterMultiColumn} />
                ) : (
                    <SwitchFormRow
                        labelText="Duration Filter"
                        subText="Include events between durations"
                        value={props.profile.durationFilterEnable}
                        onChange={props.onChange}
                        fieldId="durationFilterEnable"
                    />
                )}

                <MultiColumnFormRow {...eventTypeMultiColumn} />
            </IndentedBlock>
        </>
    )
}

export default NonCrewingEventsParameters
