import { filterOutItems } from 'services/utilities/arrayUtils'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'

export interface DeleteConfirmationWithStateUpdateDialogProps<T> {
    topic: string
    message?: string
    setTempItems: (rules: T[]) => void
    tempItems: T[]
    selectedIdsForDelete: string[]
    onClose: (result: DialogResultEnum) => void
}

const DeleteConfirmationWithStateUpdateDialog = <T extends { id: string }>({
    topic,
    message,
    tempItems,
    setTempItems,
    selectedIdsForDelete,
    onClose,
}: DeleteConfirmationWithStateUpdateDialogProps<T>) => {
    return (
        <DeleteConfirmationDialog
            topic={topic}
            message={message}
            numRows={selectedIdsForDelete.length}
            onCloseDialog={(dialogResult: DialogResultEnum) => {
                if (dialogResult === DialogResultEnum.Completed) {
                    setTempItems(filterOutItems(tempItems, selectedIdsForDelete))
                }
                onClose(dialogResult)
            }}
        />
    )
}

export default DeleteConfirmationWithStateUpdateDialog
