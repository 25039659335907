import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'

export interface SearchInputProps {
    searchText: string
    setSearchText: (searchText: string) => void
}
const SearchInput = ({ searchText, setSearchText }: SearchInputProps) => {
    return (
        <div style={{ position: 'relative' }}>
            {/* position the  magnifying glass icon to the left of the input box */}
            <i
                style={{
                    position: 'absolute',
                    left: '12px',
                    top: '6px',
                }}
                className="bi bi-search"
            ></i>
            <div className="pl-4">
                <Form.Control
                    type="search"
                    //  leave space for the magnifying glass icon
                    style={{ paddingLeft: '36px' }}
                    value={searchText}
                    placeholder="Search..."
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                />
            </div>
        </div>
    )
}

export default SearchInput
