import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import CommonDataSourceParameters from './CommonDataSourceParameters'
import FilterParameters from './FilterParameters'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface DataSourceSafteFastCSVProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceSafteFastCSV = (props: DataSourceSafteFastCSVProps) => {
    return (
        <>
            <CommonDataSourceParameters profile={props.profile} onChange={props.onChange} />

            <InputTextFormRow
                labelText="Custom Date Format"
                subText="A custom date/time format that is used in the CSV content"
                value={props.profile.customDateFormat}
                onChange={props.onChange}
                fieldId="customDateFormat"
            />

            <InputTextFormRow
                labelText="Crewing Codes"
                subText="Codes that will be converted to crewing events"
                value={props.profile.crewingCodes}
                onChange={props.onChange}
                fieldId="crewingCodes"
            />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />

            <FilterParameters profile={props.profile} onChange={props.onChange} validatedForm={props.validatedForm} />

            <InputFileFormRow
                labelText="SAFTE-FAST CSV FILE"
                fieldId="dataFieldPath1"
                subText="Local path to a schedules file (.csv, .xls, .xlsx)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                invalidText="Enter a valid path"
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceSafteFastCSV
