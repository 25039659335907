import usePalettes from 'hooks/usePalettes'
import moment from 'moment'
import { useEffect } from 'react'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report } from 'types/Reports'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import getColumns from '../Components/ReportDrilldownColumns'

export type DrilldownSelection = {
    from: number
    to: number
}

/**
 * Fetches drilldown data when the input drilldownSelection argument changes.  Populates setDrilldownData and setDrilldownColumns function arguments.
 * @param report
 * @param reportMetadata
 * @param drilldownSelection
 * @param setDrilldownData
 * @param setDrilldownColumns
 */
const useReportDrilldownData = (
    report: Report | undefined,
    reportMetadata: ReportingMetadata | null,
    drilldownSelection: DrilldownSelection | undefined,
    setDrilldownData: (drilldownData: any) => void,
    setDrilldownColumns: (gridColumns: KendoGridColumn[]) => void,
    setError: (error: string) => void,
) => {
    const api = globals.getApi()
    const reportingApi = api.getReportingApi()
    const palette = usePalettes()

    // for updates to the drilldown selection
    useEffect(() => {
        if (!palette) {
            return
        }

        if (!drilldownSelection) {
            return
        }

        const rpt = report!
        if (!rpt.configOptions) {
            // non-configurable report without drilldown
            // FIXME: throw error to user like 5.0
            setError('This type of report does not provide any drill-down data.')
            return
        }

        const fetchData = async () => {
            try {
                const loadedDrilldownData = await reportingApi.getReportDrilldown({
                    reportId: rpt.id,
                    xAxisStartIndex: drilldownSelection!.from,
                    xAxisEndIndex: drilldownSelection!.to,
                    visibleSeriesIds: rpt
                        .configOptions!.series.filter((x) => x.visible)
                        .map((x) => x.seriesId)
                        .join(','),
                })

                loadedDrilldownData.forEach((drilldownRecord: any) => {
                    // need to attach the scenario to each drilldown row
                    // so the grid can apply the appropriate palette
                    drilldownRecord.scenario = reportMetadata?.scenarios.find(
                        (x) => x.id === drilldownRecord.scenarioId,
                    )!
                    // Implement sorting for ScheduleId
                    // SFC-3245 - v6 Report data grid should sort schedule
                    // Ids numerically if they are numbers
                    // We want to use the classic isNaN js function that v5 supports
                    // eslint-disable-next-line no-restricted-globals
                    if (!isNaN(drilldownRecord.ScheduleID)) {
                        drilldownRecord.ScheduleID = Number(drilldownRecord.ScheduleID)
                    } else {
                        const newValue = drilldownRecord.ScheduleID
                        drilldownRecord.ScheduleID = newValue
                    }
                })
                formatReportRowsDates(loadedDrilldownData)
                setDrilldownData(loadedDrilldownData)
                setDrilldownColumns(getColumns(rpt, reportMetadata!, loadedDrilldownData, palette))
            } catch (e: any) {
                setError(e.message)
            }
        }

        fetchData()
    }, [
        api,
        report,
        reportingApi,
        drilldownSelection,
        reportMetadata,
        setDrilldownData,
        setDrilldownColumns,
        setError,
        palette,
    ])
}

// Format dates in format 05/27/16 04:10 for drill down data
// This method will change the date values in drillDownData parameter
// Inside formatReportRowsDates you can configure the list of date columns where
// this override is required. SFC-3409 - Date (Base) Column in Reports -showing extra data
const formatReportRowsDates = (drilldownData: any) => {
    const dateFormat = dateTimeFormatting.getAppDateTimeFormat()

    // Define a list of date columns to override
    const dateColumnsToOverride = [
        'TimeEndBase',
        'TimeEndLocal',
        'TimeEnd',
        'TimeBase',
        'TimeLocal',
        'Time',
        'DateOfFirstEventBase',
        'DateOfFirstEvent',
    ]
    drilldownData.forEach((row: any) => {
        return dateColumnsToOverride.forEach((dateColumn: any) => {
            /* eslint-disable */
            let dateValue = moment(row[dateColumn]).format(dateFormat)
            /* eslint-disable */
            row[dateColumn] = dateValue
        })
    })
}

export default useReportDrilldownData
