import waypointImageHighlighted from 'assets/waypoint-highlight.svg'
import waypointImage from 'assets/waypoint.svg'
import * as d3 from 'd3'
import SFApi from 'services/api/sfApi'
import { showResponseMessage } from 'services/utilities/toastrUtils'
import { D3Selection, SVGEL } from 'types/d3TypeHelpers'
import GraphSchedule from 'types/GraphSchedule'
import { EventBase, ScheduleDetailsViewMode, SleepQualityEnum, TimeModeEnum } from 'types/interfaces'
import Schedule from 'types/Schedule'
import ScheduleEvent, { ScheduleEventDataType, ScheduleEventType } from 'types/ScheduleEvent'
import {
    EventEditCallback,
    EventHoverCallback,
    GraphConfig,
    Scales,
} from 'views/Schedules/ScheduleDetails/EffectivenessGraph/EffectivenessGraphTypes'
import * as graphUtils from './EffectivenessGraphUtils'
import { TooltipWidth } from './EventTimelineTooltip'

class EffectivenessGraphTimeline {
    static readonly WaypointSize = 50
    private isDragging: boolean = false
    private highlightedWaypointEventIds: number[] = []
    private hoveredEvent: EventBase | null = null
    private timeMode: TimeModeEnum
    constructor(
        private svg: SVGEL,
        private parentGroup: D3Selection,
        private graphConfig: GraphConfig,
        private schedule: GraphSchedule,
        private scales: Scales,
        private eventHover: EventHoverCallback,
        private eventEdit: EventEditCallback,
        private setSchedule: (schedule: Schedule) => void,
        private showLoading: (show: boolean) => void,
        private addNewEvent: (scheduleEvent: ScheduleEvent) => void,
        private api: SFApi,
    ) {
        this.timeMode = schedule.viewSettings.timeMode
    }

    private getKeyRectangleContainerHeight = (graphConfig: GraphConfig): number => {
        return graphConfig.timelineRowHeight
    }

    private getKeyRectangleY2PositionForDataItem = (
        type: ScheduleEventDataType,
        config: GraphConfig,
        offset?: number,
    ): number => {
        const dataKey = graphUtils.DataKeys.find((x) => x.type === type)
        return (dataKey!.position + (offset || 0)) * this.getKeyRectangleContainerHeight(config) - 10
    }

    private getKeyRectangleHeight = (scheduleEvent: ScheduleEvent, config: GraphConfig): number => {
        const quality = scheduleEvent.quality
        const defaultHeight = this.getKeyRectangleContainerHeight(config)
        return quality ? defaultHeight * (quality as number) : defaultHeight
    }

    private getKeyRectangleY1PositionForDataItem = (scheduleEvent: ScheduleEvent, config: GraphConfig) =>
        this.getKeyRectangleY2PositionForDataItem(scheduleEvent.getDataType(), config) +
        config.timelineRowHeight -
        this.getKeyRectangleHeight(scheduleEvent, config)

    private isValidKeyRowType = (graphConfig: GraphConfig, type: ScheduleEventDataType) =>
        graphUtils.DataKeys.filter((x) => x.type === type).length > 0

    private getKeyYOffset = (config: GraphConfig) =>
        config.overallGraphHeight -
        config.timelineHeight -
        graphUtils.getZoomBarHeight(this.schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both) +
        graphUtils.KeyAreaMagicVerticalOffset

    private getDataKeysOrderedByPosition = () => {
        const dataKeys = [...graphUtils.DataKeys]
        dataKeys.sort((a, b) => {
            if (a.position < b.position) {
                return -1
            }
            if (a.position > b.position) {
                return 1
            }
            // a must be equal to b
            return 0
        })
        return dataKeys.filter((dk) => dk.hasSeparateRow)
    }

    /**
     * The user clicked on an event rectangle, so show the event editing dialog.
     * @param {*} d datum
     */
    private handleEventRectangleClick = (scheduleEvent: ScheduleEvent) => {
        if (this.graphConfig.isReadonly || !scheduleEvent.isEditable()) {
            // cannot edit the item
            return
        }

        // show the event dialog
        this.eventEdit(scheduleEvent)
    }

    private calculateMouseLeftPosition = (event: MouseEvent): number => {
        const tooltipWidthHalf = TooltipWidth / 2.0
        let left = event.pageX - tooltipWidthHalf
        const leftMargin = graphUtils.GraphX
        const zoomScaledWidth = this.graphConfig.graphWidth
        const distanceToRightSide = zoomScaledWidth - event.pageX + leftMargin
        if (event.pageX - leftMargin < tooltipWidthHalf) {
            left = leftMargin
        } else if (distanceToRightSide < tooltipWidthHalf) {
            left = leftMargin + zoomScaledWidth - tooltipWidthHalf * 2
        }
        return left
    }

    private setEventTooltipDisplay(display: 'show' | 'hide') {
        if (display === 'hide') {
            this.hoveredEvent = null
        }
        const eventTooltip = document.querySelector('.eventTimelineTooltip') as HTMLElement
        if (eventTooltip) {
            eventTooltip.style.display = display === 'show' ? 'block' : 'none'
        }
    }

    private eventMouseMove = (event: MouseEvent) => {
        const eventTooltip = document.querySelector('.eventTimelineTooltip') as HTMLElement
        if (eventTooltip) {
            eventTooltip.style.left = `${this.calculateMouseLeftPosition(event)}px`
        }
    }

    /**
     * Handles hovering your mouse over an even or other item in the key / puck area.
     */
    private eventMouseOver = (event: MouseEvent, basicEvent: EventBase, eventHover: EventHoverCallback) => {
        if (this.isDragging || this.hoveredEvent === basicEvent) {
            // don't show the event tooltip when dragging
            // or if the tooltip is already shown for this event
            return
        }

        // show in case it was hidden
        this.setEventTooltipDisplay('show')

        // constrain along x-axis within the graph area
        const isBothMode = this.schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both
        const topOffset = isBothMode ? -240 : 130

        // keep track of which event is hovered
        this.hoveredEvent = basicEvent

        eventHover({
            left: this.calculateMouseLeftPosition(event),
            top: this.graphConfig.calculatedPlotAreaHeight - topOffset, // leave enough room for the tallest hover-tooltip to not cover the waypoint indicators
            schedule: this.schedule,
            basicEvent,
            timeMode: this.timeMode,
        })

        if (basicEvent.getBasicEventType() === 'scheduleEvent') {
            this.highlightEventWaypointMarker(basicEvent as ScheduleEvent)
        }
    }

    private eventMouseOut = () => {
        this.setEventTooltipDisplay('hide')
        // check all events in case there are lingering "highlighted" waypoint markers
        // which can happen if they mouse out over a different one than they moused over
        // due to svg rendering overlap issues
        this.schedule.events.forEach((evt) => {
            if (this.highlightedWaypointEventIds.includes(evt.id)) {
                this.unHighlightEventWaypointMarker(evt)
            }
        })
    }

    private highlightEventWaypointMarker = (scheduleEvent: ScheduleEvent) => {
        if (
            scheduleEvent.shouldHaveWaypointMarkersDisplayed() !== true ||
            this.isDragging ||
            this.highlightedWaypointEventIds.includes(scheduleEvent.id)
        ) {
            return
        }
        this.highlightedWaypointEventIds.push(scheduleEvent.id)
        const waypointStart = document.querySelector(`#waypoint-start-${scheduleEvent.id}`) as HTMLElement
        const waypointEnd = document.querySelector(`#waypoint-end-${scheduleEvent.id}`) as HTMLElement
        const waypointStartText = document.querySelector(`#waypoint-start-text-${scheduleEvent.id}`) as HTMLElement
        const waypointEndText = document.querySelector(`#waypoint-end-text-${scheduleEvent.id}`) as HTMLElement
        const imageY = parseFloat(waypointStart.getAttribute('y')!) - this.waypointAdjust
        const textY = parseFloat(waypointStartText.getAttribute('y')!) - this.waypointAdjust
        const overlap = waypointStart.getBoundingClientRect().right - waypointEnd.getBoundingClientRect().left

        const startImageX =
            overlap > 0 ? parseFloat(waypointStart.getAttribute('x')!) - overlap / 2 : waypointStart.getAttribute('x')!
        const endImageX =
            overlap > 0 ? parseFloat(waypointEnd.getAttribute('x')!) + overlap / 2 : waypointEnd.getAttribute('x')!
        const startTextX =
            overlap > 0
                ? parseFloat(waypointStartText.getAttribute('x')!) - overlap / 2
                : waypointStartText.getAttribute('x')!
        const endTextX =
            overlap > 0
                ? parseFloat(waypointEndText.getAttribute('x')!) + overlap / 2
                : waypointEndText.getAttribute('x')!

        // set the image to the highlighted one
        waypointStart.setAttribute('href', waypointImageHighlighted)
        waypointEnd.setAttribute('href', waypointImageHighlighted)

        // shift the waypoints up
        waypointStart.setAttribute('y', imageY.toString())
        waypointStart.setAttribute('x', startImageX.toString())
        waypointStartText.setAttribute('y', textY.toString())
        waypointStartText.setAttribute('x', startTextX.toString())
        waypointEnd.setAttribute('y', imageY.toString())
        waypointEnd.setAttribute('x', endImageX.toString())
        waypointEndText.setAttribute('y', textY.toString())
        waypointEndText.setAttribute('x', endTextX.toString())

        // reduce opacity on other waypoints
        const allWaypoints = document.querySelectorAll('.js-waypoint-image, .js-waypoint-text')
        allWaypoints.forEach((el) => {
            const htmlEl = el as HTMLElement
            if (htmlEl.classList.contains(`js-waypoint-group-${scheduleEvent.id}`)) {
                return
            }
            htmlEl.style.opacity = '0.5'
        })
    }

    private unHighlightEventWaypointMarker = (scheduleEvent: ScheduleEvent) => {
        if (
            scheduleEvent.shouldHaveWaypointMarkersDisplayed() !== true ||
            this.isDragging ||
            !this.highlightedWaypointEventIds.includes(scheduleEvent.id)
        ) {
            return
        }
        this.highlightedWaypointEventIds = this.highlightedWaypointEventIds.filter((x) => x !== scheduleEvent.id)
        const waypointStart = document.querySelector(`#waypoint-start-${scheduleEvent.id}`) as HTMLElement
        const waypointEnd = document.querySelector(`#waypoint-end-${scheduleEvent.id}`) as HTMLElement
        const waypointStartText = document.querySelector(`#waypoint-start-text-${scheduleEvent.id}`) as HTMLElement
        const waypointEndText = document.querySelector(`#waypoint-end-text-${scheduleEvent.id}`) as HTMLElement
        const imageY = parseFloat(waypointStart.getAttribute('y')!) + this.waypointAdjust
        const textY = parseFloat(waypointStartText.getAttribute('y')!) + this.waypointAdjust

        // set the image to the standard (not highlighted) one
        waypointStart.setAttribute('href', waypointImage)
        waypointEnd.setAttribute('href', waypointImage)

        // set the position of the start & end waypoints back to the regular position
        waypointStart.setAttribute('y', imageY.toString())
        waypointStart.setAttribute(
            'x',
            (
                graphUtils.GraphX +
                this.scales.xScale(scheduleEvent.getStartMs()) -
                EffectivenessGraphTimeline.WaypointSize / 2
            ).toString(),
        )
        waypointStartText.setAttribute('y', textY.toString())
        waypointStartText.setAttribute(
            'x',
            (graphUtils.GraphX + this.scales.xScale(scheduleEvent.getStartMs())).toString(),
        )

        waypointEnd.setAttribute('y', imageY.toString())
        waypointEnd.setAttribute(
            'x',
            (
                graphUtils.GraphX +
                this.scales.xScale(scheduleEvent.getEndMs()) -
                EffectivenessGraphTimeline.WaypointSize / 2
            ).toString(),
        )
        waypointEndText.setAttribute('y', textY.toString())
        waypointEndText.setAttribute('x', (graphUtils.GraphX + this.scales.xScale(scheduleEvent.getEndMs())).toString())

        // return all waypoints to full opacity
        const allWaypoints = document.querySelectorAll('.js-waypoint-image, .js-waypoint-text')
        allWaypoints.forEach((el) => {
            ;(el as HTMLElement).style.opacity = '1.0'
        })
    }

    /**
     * Draw in the shaded rectangles that represent events.
     */
    private drawAllEventRectangles = (keyRects: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>): void => {
        keyRects
            .data(
                this.schedule.events.filter((d) => {
                    if (d.isExplicitSleep() && this.hideExplicitSleep()) {
                        return false
                    }
                    return this.isValidKeyRowType(this.graphConfig, d.getDataType())
                }),
            )
            .enter()
            .append('rect')
            .attr('class', (d) => {
                const editedSleepRect = d.isEditableSleep() ? ' editableSleepRect' : ''
                return d.getDataType() + 'Rect keyRect' + editedSleepRect
            })
            .attr('id', (d) => 'move-' + d.id)
            .attr('x', (d) => graphUtils.GraphX + this.scales.xScale(d.getStartMs()))
            .attr('y', (d) => {
                return (
                    this.getKeyRectangleY2PositionForDataItem(d.getDataType(), this.graphConfig) +
                    this.getKeyRectangleContainerHeight(this.graphConfig) -
                    this.getKeyRectangleHeight(d, this.graphConfig)
                )
            })
            .attr('width', (d) => this.scales.xScale(d.getEndMs()) - this.scales.xScale(d.getStartMs()))
            .attr('height', (d) => this.getKeyRectangleHeight(d, this.graphConfig))
            .on('mouseover', (e, evt) => this.eventMouseOver(e, evt, this.eventHover))
            .on('mousemove', this.eventMouseMove)
            .on('mouseout', () => this.eventMouseOut())
            .on('click', (e, evt) => this.handleEventRectangleClick(evt))
    }

    /**
     * Draw a border around the events key, the sleeps key, the markers key, etc.
     */
    private drawOuterRowBordersForEachKey(group: D3Selection) {
        const magicOffset = 10
        this.getDataKeysOrderedByPosition().forEach((item, i) => {
            group
                .append('rect')
                .attr('class', 'key')
                .attr(
                    'transform',
                    'translate(' +
                        graphUtils.GraphX +
                        ', ' +
                        (this.graphConfig.timelineRowHeight * i - magicOffset) +
                        ')',
                )
                .attr('width', this.graphConfig.graphWidth)
                .attr('height', this.graphConfig.timelineRowHeight)
        })
    }

    private getGroup = (): D3Selection =>
        this.parentGroup
            .append('g')
            .attr('class', 'key js-redraw js-key-group')
            .attr('clip-path', 'url(#clipKey)')
            .attr('transform', 'translate(' + -graphUtils.GraphX + ', ' + this.getKeyYOffset(this.graphConfig) + ')')

    private drawOverallBorderRectangle = (parentGroup: D3Selection) => {
        parentGroup
            .append('rect')
            .attr('y', this.graphConfig.calculatedPlotAreaHeight)
            .attr('width', this.graphConfig.graphWidth)
            .attr('height', this.graphConfig.timelineHeight)
            .attr('class', 'graphBorder')
    }

    /**
     * Draw the labels along the left side.  Eg, Work, Sleep, Marker, etc.
     */
    private drawKeyLabels = () => {
        const textGroup = this.svg
            .append('g')
            .attr('class', 'key js-redraw')
            .attr(
                'transform',
                'translate(' + (graphUtils.GraphX - 80) + ', ' + this.getKeyYOffset(this.graphConfig) + ')',
            )

        /**
         * How far in from the left side of the viewport are the labels
         * This should be coordinated with another variable "zoomLabelXOffset"
         * in zoomBar.
         */
        const labelIndent = 20

        this.getDataKeysOrderedByPosition().forEach((item, i) => {
            textGroup
                .append('text')
                .attr('class', 'key-labels')
                .attr('x', labelIndent)
                .attr('y', this.graphConfig.timelineRowHeight * i + this.graphConfig.timelineRowHeight / 1.2)
                .text(item.label)
        })
    }

    private shiftClickHandler = async (e: MouseEvent) => {
        if (this.graphConfig.isReadonly) {
            return
        }

        if (!e.shiftKey) {
            return
        }

        const rect = e.target as SVGRectElement
        const keyClass = Array.from(rect.classList).find((x) => x.includes('js-key'))
        if (!keyClass) {
            // shouldn't happen
            return
        }

        // prevent highlighting stuff in the page when the user shift+clicks
        document.getSelection()?.removeAllRanges()

        // keyClass types expected:
        // js-key-crewing-area
        // js-key-marker-area
        // js-key-sleep-area
        let defaultLabel = 'New Flight'
        let eventType: ScheduleEventType = 'Work'
        let dataType: ScheduleEventDataType = 'crewing'
        if (keyClass.includes('marker')) {
            defaultLabel = 'Marker'
            eventType = 'Marker'
            dataType = 'marker'
        } else if (keyClass.includes('sleep')) {
            defaultLabel = 'Explicit Sleep'
            eventType = 'ExplicitSleep'
            dataType = 'explicitSleep'
        }

        let newEventTime = this.scales.xScale.invert(e.pageX - graphUtils.getGraphXOffset())
        newEventTime.setSeconds(0)
        newEventTime.setMilliseconds(0)

        // get start location
        let startLocation = null
        let endLocation = null

        const followingEvent = this.schedule.events.filter((x) => x.getStartMs() > newEventTime.getTime())[0]
        const precedingEvents = this.schedule.events.filter((x) => x.getEndMs() < newEventTime.getTime())
        const precedingEvent = precedingEvents[precedingEvents.length - 1]

        if (followingEvent) {
            // can set the end location
            endLocation = followingEvent.from
        }
        if (precedingEvent) {
            startLocation = precedingEvent.to
            if (!endLocation) {
                endLocation = startLocation
            }
        } else if (followingEvent) {
            startLocation = followingEvent.from
        }

        let utcOffsetMinutes = 0
        if (startLocation && this.timeMode !== TimeModeEnum.UTC) {
            const utcLocation = this.timeMode === TimeModeEnum.Local ? startLocation : this.schedule.baseLocation
            utcOffsetMinutes = await this.api.getStationsApi().getUtcOffsetAtTime(utcLocation, newEventTime)
        }

        newEventTime = new Date(newEventTime.getTime())

        const tzFrom = utcOffsetMinutes
        const tzTo = utcOffsetMinutes
        const tzBase =
            this.timeMode === TimeModeEnum.Base
                ? utcOffsetMinutes
                : await this.api.getStationsApi().getUtcOffsetAtTime(this.schedule.baseLocation, newEventTime)

        const scheduleEvent = ScheduleEvent.newScheduleEventWithTzOffsets(
            defaultLabel,
            newEventTime,
            startLocation ?? '',
            endLocation ?? '',
            tzFrom,
            tzTo,
            tzBase,
            60,
            dataType,
            eventType,
            'Default',
        )

        if (eventType === 'ExplicitSleep') {
            scheduleEvent.quality = SleepQualityEnum.Excellent
        }

        scheduleEvent.plannedWorkSleepOwner = 'System'
        scheduleEvent.sleepCode = 'NA'
        scheduleEvent.scheduleId = this.schedule.id
        scheduleEvent.scheduleModified = this.schedule.modified

        this.addNewEvent(scheduleEvent)
    }

    private createKeyLightUnderlay = () => {
        // ideally figure out why we need this magic offset
        const magicOffset = 10
        const group = this.parentGroup
            .append('g')
            .attr('transform', 'translate(0, ' + magicOffset + ')')
            .attr('clip-path', 'url(#clipKeyLightUnderlay)')

        const separateRowKeys = graphUtils.DataKeys.filter((x) => x.hasSeparateRow)

        separateRowKeys.forEach((key) => {
            group
                .selectAll('underlay-rectangles')
                .data(this.schedule.lightIntervals)
                .enter()
                .append('rect')
                .attr('class', (d) => d.getDataType() + 'Rect keyRect js-key-' + key.type + '-area')
                .attr('x', (d) => this.scales.xScale(d.getStartMs()))
                .attr(
                    'y',
                    () =>
                        this.graphConfig.calculatedPlotAreaHeight +
                        this.getKeyRectangleY2PositionForDataItem(key.type, this.graphConfig),
                )
                .attr('width', (d) => this.scales.xScale(d.getEndMs()) - this.scales.xScale(d.getStartMs()))
                .attr('height', () => this.graphConfig.timelineRowHeight)
                .on('click', this.shiftClickHandler)
                .on('mouseover', (e, evt) => this.eventMouseOver(e, evt, this.eventHover))
                .on('mousemove', this.eventMouseMove)
                .on('mouseout', this.eventMouseOut)
        })
    }

    private textPositionOffset = EffectivenessGraphTimeline.WaypointSize / 3
    private waypointAdjust = EffectivenessGraphTimeline.WaypointSize / 3

    private createWayPoints = () => {
        const eventsWithWaypoints = this.schedule.events.filter((d) => {
            return d.shouldHaveWaypointMarkersDisplayed()
        })

        if (eventsWithWaypoints.length === 0) {
            // if no travelling work events don't need to create any waypoints SFC-2127
            return
        }

        const getWaypointMarkerYPositionBottom = () => {
            return graphUtils.GraphY + this.graphConfig.calculatedPlotAreaHeight
        }

        const group = this.svg
            .append('g')
            .attr('class', 'waypoints js-redraw js-waypoints-group')
            .attr(
                'transform',
                'translate(' +
                    0 +
                    ', ' +
                    (getWaypointMarkerYPositionBottom() - EffectivenessGraphTimeline.WaypointSize) +
                    ')',
            )
            .attr('clip-path', 'url(#clipWaypoints)')

        const waypoints = group.selectAll('image').data(eventsWithWaypoints).enter()

        const startWaypoints = waypoints.append('g').attr('class', 'js-start-waypoints')
        const endWaypoints = waypoints.append('g').attr('class', 'js-end-waypoints')

        const setPosition = (item: d3.Selection<SVGImageElement, ScheduleEvent, SVGGElement, unknown>) => {
            item.attr('y', this.waypointAdjust)
                .attr('width', EffectivenessGraphTimeline.WaypointSize)
                .attr('height', EffectivenessGraphTimeline.WaypointSize)
        }
        const setPositionText = (item: d3.Selection<SVGTextElement, ScheduleEvent, SVGGElement, unknown>) => {
            item.attr('y', this.waypointAdjust + this.textPositionOffset)
                .attr('width', EffectivenessGraphTimeline.WaypointSize)
                .attr('height', EffectivenessGraphTimeline.WaypointSize)
        }

        const drawWaypoint = (
            waypointsList: d3.Selection<SVGGElement, ScheduleEvent, SVGGElement, unknown>,
            position: 'start' | 'end',
            getTime: (d: ScheduleEvent) => number,
        ) => {
            setPosition(
                waypointsList
                    .append('image')
                    .attr('xlink:href', waypointImage)
                    .attr('class', (d) => {
                        return 'js-waypoint-image js-waypoint-group-' + d.id
                    })
                    .attr('id', (d) => {
                        return `waypoint-${position}-` + d.id
                    })
                    .attr('x', (d) => {
                        return (
                            graphUtils.GraphX +
                            this.scales.xScale(getTime(d)) -
                            EffectivenessGraphTimeline.WaypointSize / 2
                        )
                    }),
            )
        }

        const drawWaypointText = (
            waypointsList: d3.Selection<SVGGElement, ScheduleEvent, SVGGElement, unknown>,
            position: 'start' | 'end',
            getText: (d: ScheduleEvent) => string,
            getTime: (d: ScheduleEvent) => number,
        ) => {
            setPositionText(
                waypointsList
                    .append('text')
                    .text((d) => {
                        return getText(d)
                    })
                    .attr('class', (d) => {
                        return 'js-waypoint-text js-waypoint-group-' + d.id
                    })
                    .attr('id', (d) => {
                        return `waypoint-${position}-text-` + d.id
                    })
                    .attr('font-size', '10px') // this doesn't seem to actually adjust the font size
                    .attr('text-anchor', 'middle')
                    .attr('x', (d) => {
                        return graphUtils.GraphX + this.scales.xScale(getTime(d))
                    }),
            )
        }

        drawWaypoint(startWaypoints, 'start', (evt) => evt.getStartMs())
        drawWaypointText(
            startWaypoints,
            'start',
            (evt) => evt.from,
            (evt) => evt.getStartMs(),
        )
        drawWaypoint(endWaypoints, 'end', (evt) => evt.getEndMs())
        drawWaypointText(
            endWaypoints,
            'end',
            (evt) => evt.to,
            (evt) => evt.getEndMs(),
        )
    }

    private redrawEvents = () => {
        const xScale = this.scales.xScale
        this.schedule.events.forEach((scheduleEvent) => {
            const start = scheduleEvent.getStartMs()
            const newStartPosition = graphUtils.GraphX + xScale(start)
            const end = scheduleEvent.getEndMs()
            const newEndPos = graphUtils.GraphX + xScale(end)
            const width = xScale(end) - xScale(start)

            // move the rects
            const rectangle = document.querySelector(`#move-${scheduleEvent.id}`) as SVGRectElement
            if (rectangle) {
                rectangle.setAttribute('x', newStartPosition.toString())
                rectangle.setAttribute('width', width.toString())
            }

            // move the right drag rects
            const rectangleRightLine = document.querySelector(`#right-${scheduleEvent.id}`) as SVGRectElement
            if (rectangleRightLine) {
                rectangleRightLine.setAttribute('x', (graphUtils.GraphX + xScale(end) - 1).toString())
                rectangleRightLine.setAttribute('width', '3')
            }

            // move the left drag rects
            const rectangleLeftLine = document.querySelector(`#left-${scheduleEvent.id}`) as SVGRectElement
            if (rectangleLeftLine) {
                rectangleLeftLine.setAttribute('x', (graphUtils.GraphX + xScale(start) - 1).toString())
                rectangleLeftLine.setAttribute('width', '3')
            }

            // move any lines
            const line = document.querySelector(`#line-${scheduleEvent.id}`) as SVGRectElement
            if (line) {
                line.setAttribute('x1', newStartPosition.toString())
                line.setAttribute('x2', newStartPosition.toString())
            }

            // move waypoints
            const waypointStartImg = document.querySelector(`#waypoint-start-${scheduleEvent.id}`) as HTMLElement
            if (waypointStartImg) {
                const waypointStartPos = (newStartPosition - EffectivenessGraphTimeline.WaypointSize / 2).toString()
                waypointStartImg.setAttribute('x', waypointStartPos)
            }
            const waypointStartText = document.querySelector(`#waypoint-start-text-${scheduleEvent.id}`) as HTMLElement
            if (waypointStartText) {
                const waypointStartPos = newStartPosition.toString()
                waypointStartText.setAttribute('x', waypointStartPos)
            }

            const waypointEndImg = document.querySelector(`#waypoint-end-${scheduleEvent.id}`) as HTMLElement
            if (waypointEndImg) {
                const waypointEndPos = (newEndPos - EffectivenessGraphTimeline.WaypointSize / 2).toString()
                waypointEndImg.setAttribute('x', waypointEndPos.toString())
            }

            const waypointEndText = document.querySelector(`#waypoint-end-text-${scheduleEvent.id}`) as HTMLElement
            if (waypointEndText) {
                waypointEndText.setAttribute('x', newEndPos.toString())
            }
        })
    }

    private updateServerAfterDrag = async () => {
        if (!this.isDragging) {
            return
        }
        this.showLoading(true)

        const [updatedSchedule, message] = await this.api.updateScheduleWithEvents(this.schedule)
        this.setSchedule(updatedSchedule)
        showResponseMessage(message)
        this.showLoading(false)
        this.isDragging = false
    }

    private hookUpEventDragging = () => {
        const onScheduleEventDrag = (e: any, scheduleEvent: ScheduleEvent) => {
            if (this.graphConfig.isReadonly) {
                return
            }

            // hide any shown event tooltip
            this.setEventTooltipDisplay('hide')

            const graphLines = document.querySelectorAll<HTMLElement>('.js-graph-line')
            graphLines.forEach((x: HTMLElement) => {
                x.style.stroke = 'grey'
            })

            const rect = d3.select('#move-' + scheduleEvent.id)
            const rectXPosition = parseFloat(rect.attr('x'))
            const currentStartTime = this.scales.xScale.invert(rectXPosition)
            const deltaX = parseFloat(e.dx)
            const newStartTime = this.scales.xScale.invert(rectXPosition + deltaX)
            // nb: server doesn't handle seconds or milliseconds properly, so need to make sure we are in whole integer minutes
            const minutesDelta = Math.round((newStartTime.getTime() - currentStartTime.getTime()) / 60000)
            const millisecondsDelta = minutesDelta * 60000

            this.isDragging = this.isDragging || minutesDelta !== 0
            if (!this.isDragging) {
                return
            }

            // shift the events by the dragged distance
            if (scheduleEvent.isMarker() || scheduleEvent.isEditableSleep()) {
                scheduleEvent.start = new Date(scheduleEvent.start.getTime() + millisecondsDelta)
            } else {
                this.schedule.events
                    .filter((x) => x.dutyUuid === scheduleEvent.dutyUuid)
                    .forEach((evt) => {
                        evt.start = new Date(evt.start.getTime() + millisecondsDelta)
                    })
            }

            this.redrawEvents()
        }

        // hook up the "move" handler for dragging an event (not resizing) in the graph
        const moveInterval = d3
            .drag<SVGRectElement, ScheduleEvent>()
            .subject(Object)
            .on('drag', onScheduleEventDrag)
            .on('end', async () => {
                await this.updateServerAfterDrag()
            })

        d3.selectAll<SVGRectElement, ScheduleEvent>(
            '.crewingRect, .notCrewingRect, .markerRect, .criticalMarkerRect, .editableSleepRect',
        ).call(moveInterval)
    }

    /**
     * Draw a thin bar along the left edge of events that the user can resize with the mouse.
     */
    private drawLeftEdge = (group: D3Selection) => {
        const hideExplicitSleep =
            this.schedule.scenarioParameters.ignoreExplicitSleep && this.schedule.scenarioParameters.editSleep !== true
        group
            .selectAll('line')
            .data(
                this.schedule.events.filter(
                    (d: ScheduleEvent) =>
                        (d.isEditable() || d.isAnySleep() || d.isAutoMarker()) &&
                        !(d.isExplicitSleep() && hideExplicitSleep),
                ),
            )
            .enter()
            .append('line')
            .attr('class', (d) => d.getDataType() + 'LeftEdgeLine')
            .attr('id', (d) => 'line-' + d.id)
            .on('mouseover', (e, evt) => this.eventMouseOver(e, evt, this.eventHover))
            .on('mouseout', this.eventMouseOut)
            .attr('x1', (d) => graphUtils.GraphX + this.scales.xScale(d.getStartMs()))
            .attr('y1', (d) => this.getKeyRectangleY1PositionForDataItem(d, this.graphConfig))
            .attr('x2', (d) => graphUtils.GraphX + this.scales.xScale(d.getStartMs()))
            .attr('y2', (d) => this.getKeyRectangleY2PositionForDataItem(d.getDataType(), this.graphConfig, 1))
    }

    private hideExplicitSleep = () => {
        return (
            this.schedule.scenarioParameters.ignoreExplicitSleep && this.schedule.scenarioParameters.editSleep !== true
        )
    }

    /**
     * These are the small slivers at the left and right side of the rectangles
     * that you can grab with your mouse to slide left or right.
     */
    private createDragRects = () => {
        const rightDragResize = (e: any, scheduleEvent: ScheduleEvent) => {
            if (this.graphConfig.isReadonly) {
                return
            }
            const xScale = this.scales.xScale
            const rect = document.querySelector('#move-' + scheduleEvent.id.toString())!
            const rectXPosition = parseFloat(rect.getAttribute('x')!)
            const width = rect.clientWidth
            const currentEndTime = xScale.invert(rectXPosition + width)
            const deltaX = parseFloat(e.dx)
            const newEndTime = xScale.invert(rectXPosition + width + deltaX)
            const minutesDelta = Math.round((newEndTime.getTime() - currentEndTime.getTime()) / 60000)
            const millisecondsDelta = minutesDelta * 60000

            this.isDragging = this.isDragging || minutesDelta !== 0
            if (!this.isDragging) {
                return
            }

            // update the duration of the dragged event
            const newDuration = scheduleEvent.duration + minutesDelta
            if (newDuration <= 0) {
                return
            }

            scheduleEvent.duration = newDuration

            // shift the start of the following duty events by the dragged distance
            if (scheduleEvent.isAnyWork()) {
                this.schedule.events
                    .filter((x) => x.dutyUuid === scheduleEvent.dutyUuid && x.start > scheduleEvent.start)
                    .forEach((evt) => {
                        evt.start = new Date(evt.start.getTime() + millisecondsDelta)
                    })
            }
            this.redrawEvents()
        }

        const leftDragResize = (e: any, scheduleEvent: ScheduleEvent) => {
            if (this.graphConfig.isReadonly) {
                return
            }

            const xScale = this.scales.xScale
            const rect = document.querySelector('#move-' + scheduleEvent.id.toString())!
            const rectXPosition = parseFloat(rect.getAttribute('x')!)
            const currentStartTime = xScale.invert(rectXPosition)
            const deltaX = parseFloat(e.dx)
            const newStartTime = xScale.invert(rectXPosition + deltaX)
            const minutesDelta = Math.round((newStartTime.getTime() - currentStartTime.getTime()) / 60000)
            const millisecondsDelta = minutesDelta * 60000

            this.isDragging = this.isDragging || minutesDelta !== 0
            if (!this.isDragging) {
                return
            }

            const newDuration = scheduleEvent.duration - minutesDelta
            if (newDuration <= 0) {
                return
            }

            // update the duration and shift the start of the dragged event
            scheduleEvent.duration = newDuration
            scheduleEvent.start = new Date(scheduleEvent.start.getTime() + millisecondsDelta)

            this.redrawEvents()
        }

        const group = this.svg
            .append('g')
            .attr('class', 'key')
            .attr('clip-path', 'url(#clipKey)')
            .attr(
                'transform',
                'translate(' + 0 + ', ' + (this.getKeyYOffset(this.graphConfig) + graphUtils.GraphY) + ')',
            )

        const drags = group
            .selectAll('rect')
            .data(
                this.schedule.events.filter((d) => {
                    if (d.isExplicitSleep() && this.hideExplicitSleep()) return false

                    return d.isEditable()
                }),
            )
            .enter()

        const createDragEnds = (dragOptions: { dir: 'Left' | 'Right'; getTime: (evt: ScheduleEvent) => number }) => {
            const dragInDirection = d3
                .drag<SVGRectElement, ScheduleEvent>()
                .subject(Object)
                .on('drag', dragOptions.dir === 'Right' ? rightDragResize : leftDragResize)
                .on('end', async () => {
                    await this.updateServerAfterDrag()
                })

            drags
                .append('rect')
                .on('mouseover', (e, evt) => this.eventMouseOver(e, evt, this.eventHover))
                .on('mouseout', this.eventMouseOut)
                .on('click', (e, evt) => this.handleEventRectangleClick(evt))
                .attr('class', () => {
                    return 'eventDragEnd dragRect js-redraw'
                })
                .attr('id', (evt) => {
                    return dragOptions.dir.toLowerCase() + '-' + evt.id
                })
                .attr('x', (evt) => {
                    return graphUtils.GraphX + this.scales.xScale(dragOptions.getTime(evt)) - 1
                })
                .attr('y', (evt) => {
                    return this.getKeyRectangleY2PositionForDataItem(evt.getDataType(), this.graphConfig)
                })
                .attr('width', 5)
                .attr('height', () => this.getKeyRectangleContainerHeight(this.graphConfig))
                .call(dragInDirection)
        }

        if (!this.graphConfig.isReadonly) {
            createDragEnds({ dir: 'Left', getTime: (e) => e.getStartMs() })
            createDragEnds({ dir: 'Right', getTime: (e) => e.getEndMs() })
        }
    }

    /**
     * Render the timeline
     */
    render = () => {
        this.createKeyLightUnderlay()

        const group = this.getGroup()
        this.drawKeyLabels()
        const keyRects = group.selectAll('rect')
        this.drawAllEventRectangles(keyRects)

        if (!this.graphConfig.isReadonly) {
            this.hookUpEventDragging()
            this.createDragRects()
            this.drawLeftEdge(group)
        }
        this.drawOuterRowBordersForEachKey(group)

        this.drawOverallBorderRectangle(group)
        this.createWayPoints()
    }
}

export default EffectivenessGraphTimeline
