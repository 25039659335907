import ButtonCustom from 'views/Common/Buttons/ButtonCustom'

interface FormFooterProps {
    disabledSave?: boolean
    onCancel: () => void
    onOk?: () => void
}

const FormFooter = (props: FormFooterProps) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'end',
                maxWidth: '1200px',
                margin: 'auto',
                paddingRight: '20px',
            }}
        >
            <ButtonCustom
                isLarge
                type="submit"
                variant="primary"
                disabled={props.disabledSave}
                onClick={() => props.onOk?.()}
            >
                OK
            </ButtonCustom>
            <ButtonCustom isLarge toolbarLeftMargin variant="secondary" onClick={() => props.onCancel?.()}>
                Cancel
            </ButtonCustom>
        </div>
    )
}

export default FormFooter
