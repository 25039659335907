import { ColorPalettes, DetailDataWithTimelineTicks, ViewSettings } from './interfaces'
import Interval from './Interval'
import { ScenarioParameters } from './Scenario'
import ScheduleEvent from './ScheduleEvent'

class GraphSchedule {
    constructor(
        public id: number,
        public scenarioId: number,
        public name: string,
        public modified: Date,
        public scenarioName: string,
        public scenarioParameters: ScenarioParameters,
        public events: ScheduleEvent[],
        public baseLocation: string,
        public viewSettings: ViewSettings,
        public detailDataWithTicks: DetailDataWithTimelineTicks,
        public lightIntervals: Interval[],
        public palettes: ColorPalettes,
    ) {
        if (!palettes.hasParameterSet) {
            throw new Error('Color palettes must have a parameter set')
        }
        this.setContinuousDurationsAndEventGaps()
    }

    private areContiguousEvents = (scheduleEvent1: ScheduleEvent, scheduleEvent2: ScheduleEvent) => {
        return (
            scheduleEvent1.getEndMs() === scheduleEvent2.getStartMs() ||
            scheduleEvent2.getEndMs() === scheduleEvent1.getStartMs() ||
            (scheduleEvent1.getStartMs() === scheduleEvent2.getStartMs() &&
                scheduleEvent1.getEndMs() === scheduleEvent2.getEndMs())
        )
    }

    private generateContiguousEventSets = (events: ScheduleEvent[]): ScheduleEvent[][] => {
        if (!events.length) {
            return []
        }

        let previousEvent = events[0]
        const eventGroups: ScheduleEvent[][] = []
        let eventGroup: ScheduleEvent[] = [previousEvent]
        events.slice(1).forEach((currentEvent) => {
            if (this.areContiguousEvents(previousEvent, currentEvent)) {
                eventGroup.push(currentEvent)
            } else {
                eventGroups.push(eventGroup)
                eventGroup = [currentEvent]
            }
            previousEvent = currentEvent
        })

        if (eventGroup.length) {
            eventGroups.push(eventGroup)
        }

        eventGroups.forEach((groupedEvents) => {
            groupedEvents.sort((a, b) => {
                if (a.getStartMs() < b.getStartMs()) {
                    return -1
                }
                if (a.getStartMs() > b.getStartMs()) {
                    return 1
                }

                return 0
            })
        })

        return eventGroups
    }

    private setPrevNextDurationsForEventsGroup = (events: ScheduleEvent[][]): void => {
        if (!events.length) {
            return
        }
        let previousGroup = events[0]
        previousGroup.forEach((x) => {
            x.timeSincePreviousNonContigEvent = null
        })

        events.slice(1).forEach((currentGroup, i) => {
            if (i === events.length - 1) {
                // last set of events has nothing after it
                currentGroup.forEach((x) => {
                    x.timeUntilNextNonContigEvent = null
                })
            }

            const gapInMinutes =
                (currentGroup[0].getStartMs() - previousGroup[previousGroup.length - 1].getEndMs()) / 60000

            previousGroup.forEach((x) => {
                x.timeUntilNextNonContigEvent = gapInMinutes
            })
            currentGroup.forEach((x) => {
                x.timeSincePreviousNonContigEvent = gapInMinutes
            })

            previousGroup = currentGroup
        })
    }

    /**
     * Adds the duration between prev/next events on event models.
     */
    private createPrevNextDurationProperties = () => {
        const workGroups = this.generateContiguousEventSets(this.events.filter((x) => x.isAnyWork()))
        const sleepGroups = this.generateContiguousEventSets(this.events.filter((x) => x.isAnySleep()))
        const markerGroups = this.generateContiguousEventSets(this.events.filter((x) => x.isAnyMarker()))

        this.setPrevNextDurationsForEventsGroup(workGroups)
        this.setPrevNextDurationsForEventsGroup(sleepGroups)
        this.setPrevNextDurationsForEventsGroup(markerGroups)
    }

    /**
     * Adds the total sleep duration to contiguous sleep events.
     *
     * Adds a property named 'totalContiguousDurationMins' to contiguous sleep models.
     */
    private createContiguousDurationPropertiesForSleeps = () => {
        let previousSleep: ScheduleEvent | null = null
        let sleepContiguousMinutes = 0
        let contiguousSleepModels: ScheduleEvent[] = []

        this.events
            .filter((eventModel) => {
                return eventModel.isAnySleep()
            })
            .forEach((sleepModel) => {
                if (previousSleep === null) {
                    sleepContiguousMinutes = sleepModel.duration
                    previousSleep = sleepModel
                    return
                }
                const sleepDuration = sleepModel.duration
                const isContigousWithPreviousSleep = sleepModel.getStartMs() === previousSleep.getEndMs()
                if (isContigousWithPreviousSleep) {
                    if (contiguousSleepModels.length === 0) {
                        contiguousSleepModels.push(previousSleep)
                    }
                    contiguousSleepModels.push(sleepModel)
                } else {
                    // this item is not connected to previous sleeps, so apply any applicable contiguous minutes to previous sleeps
                    contiguousSleepModels.forEach((s) => {
                        s.continuousSleepDuration = sleepContiguousMinutes
                    })
                    contiguousSleepModels = []
                }
                sleepContiguousMinutes = isContigousWithPreviousSleep
                    ? sleepContiguousMinutes + sleepDuration
                    : sleepDuration
                previousSleep = sleepModel
            })
    }

    setContinuousDurationsAndEventGaps = (): void => {
        this.createContiguousDurationPropertiesForSleeps()
        this.createPrevNextDurationProperties()
    }
}

export default GraphSchedule
