import {
    BaseDeterminationOnImportTypeNames,
    getRecordKeyValues,
    RosterStageTypeNames,
    ZoneTypeNames,
} from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow, InputSelectFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface DataSourceCrewControlRostersProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceCrewControlRosters = (props: DataSourceCrewControlRostersProps) => {
    return (
        <>
            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <InputSelectFormRow
                labelText="Roster Planning Stage"
                subText="Use planned times or actual times"
                value={props.profile.rosterPlanningStage}
                onChange={props.onChange}
                fieldId="rosterPlanningStage"
                options={getRecordKeyValues(RosterStageTypeNames)}
            />

            <InputSelectFormRow
                labelText="Time Reference"
                subText="How the times in the data file should be interpreted"
                value={props.profile.timeReference}
                onChange={props.onChange}
                fieldId="timeReference"
                options={getRecordKeyValues(ZoneTypeNames)}
            />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />

            <InputFileFormRow
                labelText="Jeppesen Pairings File"
                fieldId="dataFieldPath1"
                subText="Local path to a rosters file (.csv, .xls, .xlsx)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceCrewControlRosters
