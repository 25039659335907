import { GridCellProps } from '@progress/kendo-react-grid'
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs'

interface GridCheckCellProps extends GridCellProps {
    enterEdit?: (dataItem: any, field: string | undefined) => void
    editField?: string
}
const GridCheckCell = (props: GridCheckCellProps) => {
    const dataItem = props.dataItem
    const cellField = props.field || ''
    const inEditField = dataItem[props.editField || '']
    const dataValue = dataItem[cellField] === true ? <i className="bi bi-check" /> : <></>

    const handleChange = (e: CheckboxChangeEvent) => {
        props.onChange?.({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
        })
    }

    return cellField && cellField === inEditField ? (
        <td style={{ textAlign: 'center' }}>
            <Checkbox value={dataItem[cellField] === true} onChange={handleChange} />
        </td>
    ) : (
        <td style={{ textAlign: 'center' }} onClick={() => props.enterEdit?.(dataItem, cellField)}>
            {dataValue}
        </td>
    )
}

export default GridCheckCell
