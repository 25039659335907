import { ColorPickerChangeEvent } from '@progress/kendo-react-inputs'
import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Select, { OnChangeValue } from 'react-select'
import tinycolor from 'tinycolor2'
import ReportingMetadata from 'types/ReportingMetadata'
import { ReportChartPlotType, Series } from 'types/Reports'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonDelete from 'views/Common/Buttons/IconButtonDelete'
import ColorPickerCustom from 'views/Common/Kendo/ColorPickerCustom'
import { Option } from 'views/Common/Widget/ReactSelectTypes'
import { getAggregationsForDataItem, getDataItemDefinitionByDataItem } from '../Components/reportConversion'
import classes from './ReportConfigSeriesSelector.module.css'
import DataCategoryItem from './ReportConfigurationTypes'

/**
 * If an item is named like "FooMax", then find "max", or "BarMin", find "min", etc.
 * If doesn't end with min, max or avg, return undefined.
 */
const tryFindAggregationBasedOnDataItemName = (dataItem: string): string => {
    // this matches up with the values in the aggregation dropdown
    // which match up with the Enum in c#
    // Just using these few because there aren't
    // any data items that end in anything else I think
    const aggregationMap = {
        min: 'Minimum',
        max: 'Maximum',
        avg: 'Average',
        sum: 'Sum',
    }
    const keys = Object.keys(aggregationMap)
    const dataItemSuffix = dataItem.substring(dataItem.length - 3).toLowerCase()
    if (keys.indexOf(dataItemSuffix) >= 0) {
        return (aggregationMap as any)[dataItemSuffix]
    }
    // in most cases it is average that they want, so lets use that as default
    return 'Average'
}

const ReportConfigDialogSeriesSelector = (props: {
    showColorPicker: boolean
    metadata: ReportingMetadata
    reportDataType: 'schedules' | 'events'
    xAxisItem: string
    series: Series[]
    setSeries: (series: Series[]) => void
    addUnusedColor: (color: string) => void
}) => {
    const iconHeights = '36px'
    const visibleSeries = props.series.filter((x) => !x.isChildSeries && x.visible)

    // selectable series items; start with the Data Category at the top of the list.
    let allSeriesItems: Option[] = [{ value: DataCategoryItem, label: DataCategoryItem }]
    allSeriesItems = allSeriesItems.concat(
        props.metadata.seriesDataItems[props.reportDataType]
            .filter(
                (x) =>
                    x.type.toLowerCase() !== 'string' &&
                    x.type.toLowerCase() !== 'datetime' &&
                    x.type.toLowerCase() !== 'date' &&
                    x.type.toLowerCase() !== 'time',
            )
            .map((item) => ({
                label: getDataItemDefinitionByDataItem(item.dataItem, props.reportDataType, props.metadata).displayName,
                value: item.dataItem,
            })),
    )

    const copySeries = () => [...props.series]

    // map each series into the row element(s)
    const seriesRowsJsx = visibleSeries.map((series, i) => {
        let seriesDataItem = series.dataItem
        if (seriesDataItem === props.xAxisItem) {
            // for display purposes, switch the item to say the generic Data Category text
            seriesDataItem = DataCategoryItem
        }

        const aggregationsForSeriesItem = getAggregationsForDataItem(
            seriesDataItem,
            props.xAxisItem,
            props.reportDataType,
            props.metadata,
        )

        const selectedAggregation = aggregationsForSeriesItem.filter((x) => x.value === series.aggregation)
        return (
            <Row key={i.toString()} style={{ marginBottom: '4px' }}>
                <Col xs={6}>
                    <Select
                        autoFocus={series.dataItem === ''}
                        name={`dataItem_${series.id}`}
                        options={allSeriesItems}
                        value={allSeriesItems.find((x) => x.value === seriesDataItem)}
                        menuPlacement="auto"
                        onChange={(newValue: OnChangeValue<Option, false>) => {
                            const updatedSeries = copySeries()
                            const currentSeries = updatedSeries.find((x) => x.seriesId === series.seriesId)!
                            const oldSeriesDataItem = currentSeries.dataItem
                            currentSeries.dataItem = newValue!.value

                            const aggregationsForThisSeries = getAggregationsForDataItem(
                                currentSeries.dataItem,
                                props.xAxisItem,
                                props.reportDataType,
                                props.metadata,
                            )

                            if (
                                !aggregationsForThisSeries.find((x) => x.value === currentSeries.aggregation) ||
                                oldSeriesDataItem === DataCategoryItem
                            ) {
                                if (currentSeries.dataItem === DataCategoryItem) {
                                    currentSeries.aggregation = 'Count'
                                } else {
                                    currentSeries.aggregation = tryFindAggregationBasedOnDataItemName(
                                        currentSeries.dataItem,
                                    )
                                }
                            }
                            props.setSeries(updatedSeries)
                        }}
                    />
                </Col>

                <Col xs={4}>
                    <Select
                        name={`seriesAggregation_${series.seriesId}`}
                        options={aggregationsForSeriesItem}
                        value={selectedAggregation}
                        menuPlacement="auto"
                        onChange={(newValue: OnChangeValue<Option, false>) => {
                            const updatedSeries = copySeries()
                            const currentSeries = updatedSeries.find((x) => x.seriesId === series.seriesId)!
                            currentSeries.aggregation = newValue!.value
                            props.setSeries(updatedSeries)
                        }}
                    />
                </Col>
                <Col xs={1}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: props.showColorPicker ? 'space-around' : 'center',
                        }}
                    >
                        {props.showColorPicker && (
                            <ColorPickerCustom
                                key={series.id.toString()}
                                value={series.color}
                                onChange={(e: ColorPickerChangeEvent) => {
                                    // we store colors consistently in hex but the kendo color picker provides rgba.
                                    // Alternatively, we could store the rgb value, but I think the db columns needs to be widened a little.
                                    const hexColor = `#${tinycolor(e.value).toHex()}`
                                    const updatedSeries = copySeries()
                                    const currentSeries = updatedSeries.find((x) => x.seriesId === series.seriesId)!
                                    currentSeries.color = hexColor
                                    props.setSeries(updatedSeries)
                                }}
                                className="customColorPicker"
                            />
                        )}
                        <PlotTypeSelector
                            series={series}
                            height={iconHeights}
                            setPlotType={(plotType) => {
                                const updatedSeries = copySeries()
                                const currentSeries = updatedSeries.find((x) => x.seriesId === series.seriesId)!
                                currentSeries.plotType = plotType
                                props.setSeries(updatedSeries)
                            }}
                        />
                    </div>
                </Col>

                <Col xs={1}>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButtonDelete
                            style={{ height: iconHeights }}
                            onClick={() => {
                                // filter out the removed series
                                const seriesToRemove = props.series.find((x) => x.seriesId === series.seriesId)!
                                const updatedSeries = [...props.series].filter((x) => x !== seriesToRemove)
                                props.addUnusedColor(seriesToRemove.color)
                                // return this color to the list to be used
                                props.setSeries(updatedSeries)
                            }}
                        />
                    </div>
                </Col>
            </Row>
        )
    })

    return (
        <Card>
            <Card.Body className={classes.cardBody}>
                {visibleSeries.length > 0 ? (
                    <>
                        <Row>
                            <Col xs={6}>
                                <small>Data Item</small>
                            </Col>

                            <Col xs={4}>
                                <small>Aggregation</small>
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center' }}>
                                <small>Style</small>
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center' }} />
                        </Row>
                        {seriesRowsJsx}
                    </>
                ) : (
                    <small className="text-muted">Please add at least 1 series</small>
                )}
            </Card.Body>
        </Card>
    )
}

/**
 * Plot type selector component; a clickable icon that produces a dropdown for selecting Line vs Column plot type.
 * @returns
 */
const PlotTypeSelector = (props: {
    series: Series
    height: string
    setPlotType: (plotType: ReportChartPlotType) => void
}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const selectRef = useRef<HTMLSelectElement>(null)

    useEffect(() => {
        if (showDropdown && selectRef.current) {
            selectRef.current.focus()
        }
    }, [showDropdown])

    return (
        <div>
            <IconButton
                className="plotTypeButton"
                tooltip={`Select Plot Type (currently "${props.series.plotType}")`}
                style={{
                    height: props.height,
                    backgroundColor: 'white',
                }}
                onClick={() => {
                    setShowDropdown(!showDropdown)
                }}
                icon={`${props.series.plotType === 'column' ? 'bi-bar-chart-line-fill' : 'bi-graph-up'}`}
            />

            {showDropdown && (
                <select
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        props.setPlotType(e.target.value as ReportChartPlotType)
                        setShowDropdown(false)
                    }}
                    onBlur={(e: React.FocusEvent<HTMLElement>) => {
                        const relatedTarget = e.relatedTarget as HTMLElement | undefined
                        if (relatedTarget) {
                            const isPlotTypeButton =
                                relatedTarget.className.split(' ').find((x) => x === 'plotTypeButton') !== undefined
                            if (isPlotTypeButton) {
                                // let the button toggle the state
                                return
                            }
                        }
                        setShowDropdown(false)
                    }}
                    ref={selectRef}
                    style={{
                        display: 'block',
                        position: 'absolute',
                        marginTop: 5,
                        zIndex: 1,
                    }}
                >
                    <option value="line" selected={props.series.plotType === 'line'}>
                        Line
                    </option>
                    <option value="column" selected={props.series.plotType === 'column'}>
                        Column
                    </option>
                </select>
            )}
        </div>
    )
}

export default ReportConfigDialogSeriesSelector
