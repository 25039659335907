// organize-imports-ignore
import { FormEvent, memo, useState } from 'react'
import '@react-awesome-query-builder/ui/css/styles.css'
import 'views/Common/QueryBuilder/QueryBuilderStylesOverride.css'
import { Form, Modal } from 'react-bootstrap'
import { ReportItemDefinition } from 'types/ReportingMetadata'
import { QueryBuilderValue } from 'types/Reports'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import QueryBuilder from 'views/Common/QueryBuilder/QueryBuilder'
// import 'react-awesome-query-builder/lib/css/compact_styles.css'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

export interface ReportFilterDialogProps {
    type: 'Events' | 'Schedules'
    dataItems: ReportItemDefinition[]
    rules?: QueryBuilderValue
    closeCallback: (status: DialogResultEnum, updatedQuery?: QueryBuilderValue) => void
}

/**
 * Main component function
 */
const FilterDialogContent = (props: ReportFilterDialogProps) => {
    const [query, setQuery] = useState<QueryBuilderValue | undefined>(props.rules)

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()
        props.closeCallback(DialogResultEnum.Completed, query)
    }

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>{props.type} Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Configure rules to filter the data in this report.</p>
                <QueryBuilder query={query} setQuery={setQuery} reportDataItems={props.dataItems} />
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom isLarge type="submit" variant="primary">
                    OK
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const ReportConfigFilterDialog = (props: ReportFilterDialogProps) => {
    return (
        <ModalWrapper
            className="DialogMediumWidth"
            closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}
        >
            <FilterDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(ReportConfigFilterDialog)
