import React, { CSSProperties } from 'react'
import IconButton from './IconButton'

export interface IconProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
    filterEnabled?: boolean
    filterIsActive?: boolean
}

const IconButtonFilter = (props: IconProps) => {
    let defaultStyle: CSSProperties = {}
    if (props.style) {
        defaultStyle = { ...defaultStyle, ...props.style }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filterEnabled, filterIsActive, ...otherProps } = props

    return (
        <IconButton
            tooltip={props.filterEnabled ? 'Disable Column Filters' : 'Enable Column Filters'}
            toolbarLeftMargin
            {...otherProps}
            style={defaultStyle}
            icon={props.filterEnabled ? 'bi-funnel-fill' : 'bi-funnel'}
            iconcolor={props.filterIsActive ? '#e16a07' : '#444'}
        />
    )
}

export default IconButtonFilter
