import useReportingMetaData from 'hooks/useReportingMetaData'
import { useState } from 'react'
import { EventFilter } from 'types/ProfileInterfaces'
import { getDefaultEventFilter } from 'types/ProfileInterfacesDefaults'
import { QueryBuilderValue } from 'types/Reports'
import IconButton from 'views/Common/Buttons/IconButton'
import EventFilterPanel from 'views/Common/EventFilter/EventFilterPanel'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import { FormControlChangeEvent, updateObjectWithFormEvent } from '../Form/FormControls/FormControlBase'
import EventFilterQueryDialog from './EventFilterQueryDialog'

export const isEventRuleValid = (eventFilter: EventFilter) => {
    const hasName = Boolean(eventFilter.name)

    const invalidEventsLabel = eventFilter.eventsEnable && !eventFilter.events
    const invalidDuration = eventFilter.durationEnable && eventFilter.durationTo === 0
    const invalidLocation = eventFilter.locationEnable && !eventFilter.locationCode
    const invalidRoute = eventFilter.routeEnable && !(eventFilter.routeFrom && eventFilter.routeTo)
    // todo review this one...
    const invalidTimeOfDay = eventFilter.timeOfDayEnable && !(eventFilter.timeOfDayFrom && eventFilter.timeOfDayTo)
    const invalidEquipmentType = eventFilter.equipmentTypesEnable && !eventFilter.equipmentTypes
    const invalidCrewComplement = eventFilter.crewComplementEnable && eventFilter.crewComplementTo === 0
    const invalidPositions = eventFilter.crewPositionsEnable && !eventFilter.crewPositions
    return (
        hasName &&
        !(
            invalidLocation ||
            invalidEventsLabel ||
            invalidDuration ||
            invalidRoute ||
            invalidTimeOfDay ||
            invalidEquipmentType ||
            invalidCrewComplement ||
            invalidPositions
        )
    )
}

interface EventFilterDialogProps {
    eventFilter?: EventFilter
    onClose: (result: DialogResultEnum, eventFilter?: EventFilter) => void
}

const EventFilterDialog = (props: EventFilterDialogProps) => {
    const reportingMetadata = useReportingMetaData()
    const [state, setState] = useState<EventFilter>(props.eventFilter ?? getDefaultEventFilter())
    const [showQueryBuilder, setShowQueryBuilder] = useState(false)
    const [validated, setValidated] = useState(false)

    // filter layout
    const onFilterCancel = () => {
        props.onClose(DialogResultEnum.Cancelled)
    }
    const onFilterOk = () => {
        if (!isEventRuleValid(state)) {
            setValidated(true)
            return
        }
        props.onClose(DialogResultEnum.Completed, state)
    }
    const onFilterChange = (e: FormControlChangeEvent) => {
        setState((prev) => updateObjectWithFormEvent(prev, e))
    }
    const filterHeader = (
        <>
            <IconButton
                tooltip="Back"
                onClick={onFilterCancel}
                style={{ fontSize: '20px', marginRight: '20px' }}
                icon="bi-arrow-left"
            />
            Add Event Filter
        </>
    )
    const filterFooter = <FormFooter onCancel={onFilterCancel} onOk={onFilterOk} />
    const onQueryDialogClose = (result: DialogResultEnum, value?: QueryBuilderValue) => {
        if (result === DialogResultEnum.Completed && value) {
            setState((prev) => ({ ...prev, eventQuery: value }))
        }
        setShowQueryBuilder(false)
    }
    return showQueryBuilder ? (
        <EventFilterQueryDialog queryBuilderValue={state.eventQuery} onClose={onQueryDialogClose} />
    ) : (
        <DialogLayout headerContent={filterHeader} footerContent={filterFooter} onClose={onFilterCancel}>
            <EventFilterPanel
                eventFilter={state}
                itemDefinitions={reportingMetadata!.seriesDataItems.events}
                onFilterChange={onFilterChange}
                onQueryOpen={() => setShowQueryBuilder(true)}
                validated={validated}
            />
        </DialogLayout>
    )
}

export default EventFilterDialog
