import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from 'views/Login/LoginPage'
import ResetPassword from 'views/ResetPassword/ResetPassword'

const PublicRoutes = (props: {
    isLoggedIn: boolean | null
    loginHandler: (emailAddress: string, password: string, keepMeLoggedIn: boolean) => void
}) => {
    const login = props.isLoggedIn === null ? null : <Login onLogin={props.loginHandler} />
    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Routes>
                <Route path="/setpasswordrequest" element={<ResetPassword mode="RequestReset" />}></Route>
                <Route path="/setpassword/:token" element={<ResetPassword mode="SetNewPassword" />}></Route>
                <Route path="*" element={login}></Route>
            </Routes>
        </Suspense>
    )
}

export default PublicRoutes
