import { useEffect, useState } from 'react'
import globals from 'services/global/globals'
import { SystemSetting } from 'types/SystemSetting'

export default function useSettingValue<T = string>(name: string): T {
    const [value, setValue] = useState('')
    useEffect(() => {
        const getGetSettingValue = async () => {
            const data = await globals.getApi().getSystemSettingApi().getSettingValue(name)
            setValue(data)
        }
        getGetSettingValue()
    }, [name])
    return value as T
}
export function useSettingValues(names: string[]) {
    const [value, setValue] = useState([''])
    const dep = JSON.stringify(names)
    useEffect(() => {
        const getGetSettingValues = async () => {
            let data = await globals.getApi().getSystemSettingApi().getSettingValues(JSON.parse(dep))
            data = data.map((v: string) => {
                if (v.toLowerCase() === 'true') return 'true'
                return v
            })
            setValue(data)
        }
        getGetSettingValues()
    }, [dep])
    return value
}

export function useSystemSettings(
    name: string,
    dontLoad?: boolean,
): [SystemSetting[], (value: SystemSetting[]) => void] {
    const [value, setValue] = useState<SystemSetting[]>([])
    useEffect(() => {
        const loadData = async () => {
            const data = await globals.getApi().getSystemSettingApi().getSystemSettings(name)
            setValue(data)
        }
        if (!dontLoad) {
            loadData()
        }
    }, [name, dontLoad])
    return [value, setValue]
}
