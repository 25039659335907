import useReportingMetaData from 'hooks/useReportingMetaData'
import { useState } from 'react'
import { QueryBuilderValue } from 'types/Reports'
import IconButton from 'views/Common/Buttons/IconButton'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import QueryBuilder from '../QueryBuilder/QueryBuilder'

interface EventFilterQueryDialogProps {
    queryBuilderValue?: QueryBuilderValue
    onClose: (result: DialogResultEnum, value?: QueryBuilderValue) => void
}
const EventFilterQueryDialog = (props: EventFilterQueryDialogProps) => {
    const reportingMetadata = useReportingMetaData()
    const [state, setState] = useState<QueryBuilderValue | undefined>(props.queryBuilderValue)

    const onQueryChange = (qb: QueryBuilderValue) => {
        setState(qb)
    }
    const onQueryClose = () => {
        props.onClose(DialogResultEnum.Cancelled)
    }
    const onQueryOk = () => {
        props.onClose(DialogResultEnum.Completed, state)
    }
    const queryHeader = (
        <>
            <IconButton
                tooltip="Back"
                onClick={onQueryClose}
                style={{ fontSize: '20px', marginRight: '20px' }}
                icon="bi-arrow-left"
            />
            Add Rule - Query Builder
        </>
    )
    const queryFooter = <FormFooter onCancel={onQueryClose} onOk={onQueryOk} />

    return (
        <DialogLayout headerContent={queryHeader} footerContent={queryFooter} onClose={onQueryClose}>
            <QueryBuilder
                query={state}
                setQuery={onQueryChange}
                reportDataItems={reportingMetadata!.seriesDataItems.events}
            />
        </DialogLayout>
    )
}

export default EventFilterQueryDialog
