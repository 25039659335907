import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions, DialogPosition } from 'store/dialogsStore'
import { RootState } from 'store/store'

export type ModalWrapperClassName = 'ShiftsDialog' | 'DutiesListDialog' | 'DialogMediumWidth' | 'DialogSmallWidth'

const ModalWrapper = (props: {
    size?: 'sm' | 'lg'
    className?: ModalWrapperClassName
    /**
     * Body of the dialog
     */
    children?: React.ReactElement
    /**
     * Fires when the dialog is closed (confirm or cancel, etc)
     */
    closeCallback?: () => void

    hide?: boolean
}) => {
    const [show, setShow] = useState(true)
    const dispatch = useDispatch()
    const dialogPosition = useSelector<RootState, DialogPosition>((state) => state.dialogs.position)

    const handleClose = () => {
        setShow(false)
        dispatch(dialogActions.reset())
        if (props.closeCallback) props.closeCallback()
    }

    const nodeRef = React.useRef(null)

    const DraggableDialogComponent = (props1: any) => (
        <Draggable
            handle=".modal-header"
            nodeRef={nodeRef}
            position={dialogPosition}
            onStop={(e: DraggableEvent, data: DraggableData) => {
                const dragTarget = e.target as HTMLElement
                if (dragTarget.className === 'btn-close') {
                    // don't update the position if the user is closing the dialog
                    // as it prevents the dialog from actually closing
                    return
                }
                dispatch(dialogActions.setPosition({ x: data.x, y: data.y }))
            }}
        >
            <ModalDialog ref={nodeRef} {...props1} />
        </Draggable>
    )

    return (
        <Modal
            scrollable
            show={show && !props.hide}
            size={props.size}
            backdrop="static"
            onHide={handleClose}
            dialogClassName={props.className}
            animation={false}
            centered
            enforceFocus={false} // https://www.telerik.com/forums/datepicker-closes-directly-when-used-in-material-ui-dialog
            dialogAs={DraggableDialogComponent}
        >
            {props.children}
        </Modal>
    )
}

export default ModalWrapper
