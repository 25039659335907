import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-react-grid'
import { uniq } from 'lodash'

/**
 * Get fields used in the filter of the grid data state
 * @param dataState
 * @returns
 */
const parseFilterFieldsFromGridDataState = (dataState: State): string[] => {
    if (!dataState.filter) {
        return []
    }
    return getFilterFields(dataState.filter!.filters)
}

export const getFilterFields = (filters: (CompositeFilterDescriptor | FilterDescriptor)[]): string[] => {
    const fields: string[] = []
    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i]
        if ('filters' in filter) {
            // is CompositeFilterDescriptor
            fields.push(...getFilterFields(filter.filters))
        } else {
            // bottom-level filter descriptor
            fields.push(filter.field! as string)
        }
    }
    return uniq(fields)
}

const DefaultFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
}

/**
 * Scroll so the given row number (1-based), is in view
 * @param gridRef
 * @param rowNumber
 */
const scrollToRow = (gridRef: React.RefObject<Grid>, rowNumber: number) => {
    if (rowNumber >= 1) {
        // timeout/delay makes this work whilst in a dialog
        setTimeout(() => {
            const allRows: HTMLTableRowElement[] = Array.from(gridRef.current!.element!.querySelectorAll('tr'))
            if (allRows.length > 1) {
                const allRowsAfterHeader = allRows.slice(1)
                allRowsAfterHeader[rowNumber - 1].scrollIntoView({ behavior: 'smooth' })
            }
        })
    }
}

export { parseFilterFieldsFromGridDataState, DefaultFilter, scrollToRow }
