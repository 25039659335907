import useKendoGridSelectionState from 'hooks/useKendoGridSelectionState'
import { useMemo } from 'react'
import { SleepPeriods } from 'types/ProfileInterfaces'
import { v4 as uuidv4 } from 'uuid'
import IconButton from 'views/Common/Buttons/IconButton'
import KendoGridCustom, { SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import {
    getCustomCellRenderer,
    getCustomRowRenderer,
    getItemEditedHandler,
} from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'

export interface PWSSleepRuleDetailsPeriodsProps {
    sleepPeriods: SleepPeriods[]
    updateSleepPeriods: (sleepPeriods: SleepPeriods[]) => void
    validated: boolean
}

const PWSSleepRuleDetailsPeriods = ({
    sleepPeriods,
    updateSleepPeriods,
    validated,
}: PWSSleepRuleDetailsPeriodsProps) => {
    const [selectedRowsState, setSelectedRowsState, selectedIds] = useKendoGridSelectionState()

    const invalidFields = useMemo(() => {
        if (!validated) {
            return []
        }
        const totalPercentOfSleep = sleepPeriods.reduce((acc, x) => acc + x.percentOfSleep, 0)
        if (totalPercentOfSleep !== 100) {
            return ['percentOfSleep']
        }
        return []
    }, [sleepPeriods, validated])

    const EDIT_FIELD = 'kendo_inEdit'
    const toolbarButtons = (
        <>
            <IconButton
                tooltip="Add"
                onClick={() =>
                    updateSleepPeriods([
                        ...sleepPeriods,
                        { id: uuidv4(), position: sleepPeriods.length + 1, percentOfSleep: 0 },
                    ])
                }
                icon="bi-file-plus"
                toolbarLeftMargin
            />
            <IconButton
                tooltip="Delete"
                disabled={!selectedIds.length}
                onClick={() => {
                    const remainingPeriods = sleepPeriods.filter((x) => !selectedIds.includes(x.position.toString()))
                    remainingPeriods.forEach((x, i) => {
                        x.position = i + 1
                    })
                    updateSleepPeriods(remainingPeriods)
                    setSelectedRowsState({})
                }}
                style={{ fontSize: '20px' }}
                icon="bi-trash"
                toolbarLeftMargin
            />
        </>
    )

    return (
        <>
            <div className="d-flex justify-content-end">{toolbarButtons}</div>
            <KendoGridCustom
                sortable={false}
                defaultEmptyGridText={
                    validated ? 'You must define at least 1 sleep period' : 'No sleep periods defined'
                }
                emptyGridIsInvalid={validated}
                dataItemKey="position"
                data={sleepPeriods}
                columns={[
                    { field: 'position', title: 'Period Position', width: '100px' },
                    { field: 'percentOfSleep', title: 'Percent of Sleep' },
                ]}
                pageable={false}
                selectedRowsState={selectedRowsState}
                editField={EDIT_FIELD}
                onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                cellRender={getCustomCellRenderer(EDIT_FIELD, sleepPeriods, updateSleepPeriods, setSelectedRowsState, {
                    readonlyFields: ['position'],
                    invalidText: 'Total percent of sleep must equal 100',
                    invalidFields,
                })}
                rowRender={getCustomRowRenderer(EDIT_FIELD, sleepPeriods, updateSleepPeriods, {
                    numericColumns: ['percentOfSleep'],
                })}
                onItemChange={getItemEditedHandler(sleepPeriods, updateSleepPeriods)}
            />
        </>
    )
}

export default PWSSleepRuleDetailsPeriods
