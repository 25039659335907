import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface DataSourceAltitudeProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
}
const DataSourceAltitude = (props: DataSourceAltitudeProps) => {
    return (
        <InputFileFormRow
            labelText="Altitude Pairings File"
            fieldId="dataFieldPath1"
            subText="Local path to a pairings file (.txt)"
            value={props.profile.dataFieldPath1}
            onChange={props.onChange}
            disabled={true} // Temporarily disabled
        />
    )
}

export default DataSourceAltitude
