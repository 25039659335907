import {
    BasesFilterTypeNames,
    EquipmentFilterTypeNames,
    getRecordKeyValues,
    LocationFilterTypeNames,
} from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    InputSelectFormRow,
    MultiColumnFormRow,
    MultiColumnFormRowProps,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'

interface FilterParameterProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const FilterParameters = (props: FilterParameterProps) => {
    const filterDateStartMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Date Start',
        subText: 'Include events starting on or after',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.dateStartFilterEnable,
                    onChange: props.onChange,
                    id: 'dateStartFilterEnable',
                },
            },
            {
                colType: 'datePickerInput',
                colWidth: 6,
                datePickerProps: {
                    value: props.profile.dateStartFilter,
                    onChange: props.onChange,
                    id: 'dateStartFilter',
                    required: true,
                },
            },
        ],
    }

    const filterDateEndMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Date End',
        subText: 'Include events ending on or before',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.dateEndFilterEnable,
                    onChange: props.onChange,
                    id: 'dateEndFilterEnable',
                },
            },
            {
                colType: 'datePickerInput',
                colWidth: 6,
                datePickerProps: {
                    value: props.profile.dateEndFilter,
                    onChange: props.onChange,
                    id: 'dateEndFilter',
                    required: true,
                },
            },
        ],
    }

    const filterBasesMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Bases',
        subText: 'Include events with specific base stations',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: props.profile.baseFilterType,
                    onChange: props.onChange,
                    id: 'baseFilterType',
                    options: getRecordKeyValues(BasesFilterTypeNames),
                },
            },
            {
                colType: 'textInput',
                inputTextProps: {
                    value: props.profile.baseFilterList,
                    onChange: props.onChange,
                    id: 'baseFilterList',
                },
            },
        ],
    }

    const filterLocationsMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Locations',
        subText: 'Include events with specific locations',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: props.profile.locationFilterType,
                    onChange: props.onChange,
                    id: 'locationFilterType',
                    options: getRecordKeyValues(LocationFilterTypeNames),
                },
            },
            {
                colType: 'textInput',
                inputTextProps: {
                    value: props.profile.locationFilterList,
                    onChange: props.onChange,
                    id: 'locationFilterList',
                },
            },
        ],
    }

    const filterEquipmentTypesMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Equipment Types',
        subText: 'Include events with specific equipment types',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: props.profile.equipmentFilterType,
                    onChange: props.onChange,
                    id: 'equipmentFilterType',
                    options: getRecordKeyValues(EquipmentFilterTypeNames),
                },
            },
            {
                colType: 'textInput',
                inputTextProps: {
                    value: props.profile.equipmentFilterList,
                    onChange: props.onChange,
                    id: 'equipmentFilterList',
                },
            },
        ],
    }

    return (
        <IndentedBlock headingText="Filters" subheadingText="Which events to include in the schedules">
            {props.profile.dateStartFilterEnable ? (
                <MultiColumnFormRow {...filterDateStartMultiColumn} />
            ) : (
                <SwitchFormRow
                    labelText="Date Start"
                    subText="Include events starting on or after"
                    value={props.profile.dateStartFilterEnable}
                    onChange={props.onChange}
                    fieldId="dateStartFilterEnable"
                />
            )}

            {props.profile.dateEndFilterEnable ? (
                <MultiColumnFormRow {...filterDateEndMultiColumn} />
            ) : (
                <SwitchFormRow
                    labelText="Date End"
                    subText="Include events ending on or before"
                    value={props.profile.dateEndFilterEnable}
                    onChange={props.onChange}
                    fieldId="dateEndFilterEnable"
                />
            )}

            {props.profile.baseFilterType !== 'IncludeAll' ? (
                <MultiColumnFormRow {...filterBasesMultiColumn} />
            ) : (
                <InputSelectFormRow
                    labelText="Bases"
                    subText="Include events with specific base stations"
                    value={props.profile.baseFilterType}
                    onChange={props.onChange}
                    fieldId="baseFilterType"
                    options={getRecordKeyValues(BasesFilterTypeNames)}
                />
            )}

            {props.profile.locationFilterType !== 'IncludeAll' ? (
                <MultiColumnFormRow {...filterLocationsMultiColumn} />
            ) : (
                <InputSelectFormRow
                    labelText="Locations"
                    subText="Include events with specific locations"
                    value={props.profile.locationFilterType}
                    onChange={props.onChange}
                    fieldId="locationFilterType"
                    options={getRecordKeyValues(LocationFilterTypeNames)}
                />
            )}

            {props.profile.equipmentFilterType !== 'IncludeAll' ? (
                <MultiColumnFormRow {...filterEquipmentTypesMultiColumn} />
            ) : (
                <InputSelectFormRow
                    labelText="Equipment Types"
                    subText="Include events with specific equipment types"
                    value={props.profile.equipmentFilterType}
                    onChange={props.onChange}
                    fieldId="equipmentFilterType"
                    options={getRecordKeyValues(EquipmentFilterTypeNames)}
                />
            )}
        </IndentedBlock>
    )
}

export default FilterParameters
