import { Dropdown } from 'react-bootstrap'
import { AccessLevel, GridLayout } from 'types/GridLayout'
import CustomDropdown from 'views/Common/Inputs/RichDropdown'
import { EllipsisDropdownHeader, EllipsisDropdownItem, IndentedDropdownItem } from '../../Buttons/EllipsisDropdown'

export interface Props {
    open?: boolean
    selectedLayout: GridLayout
    allGridLayouts: GridLayout[]
    onLayoutSelected: (layout: GridLayout) => void
    onSaveClick: () => void
    onEditClick: () => void
    onDeleteClick: () => void
}

const LayoutsMenuSection = ({
    title,
    topDivider,
    accessLevel,
    allGridLayouts,
    selectedLayout,
    onSelected,
}: {
    title: string
    topDivider?: boolean
    accessLevel: AccessLevel
    allGridLayouts: GridLayout[]
    selectedLayout: GridLayout
    onSelected: (layout: GridLayout, type: AccessLevel) => void
}) => {
    const filteredGridLayouts = allGridLayouts.filter((x) => x.accessLevel === accessLevel && x.unsaved !== true)
    if (!filteredGridLayouts.length) return null
    return (
        <>
            {topDivider && <Dropdown.Divider />}
            <EllipsisDropdownHeader>{title}</EllipsisDropdownHeader>
            {filteredGridLayouts.map((gridLayout) => (
                <EllipsisDropdownItem key={gridLayout.name} onClick={() => onSelected(gridLayout, accessLevel)}>
                    <IndentedDropdownItem checked={gridLayout.name === selectedLayout.name && !selectedLayout.unsaved}>
                        {gridLayout.name}
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
            ))}
        </>
    )
}

export const ActionMenuItem = ({
    text,
    iconClass,
    disabled,
    onClick,
}: {
    text: string
    iconClass: string
    disabled?: boolean
    onClick: () => void
}) => {
    return (
        <EllipsisDropdownItem disabled={disabled} onClick={onClick}>
            <div className="d-flex">
                <i
                    className={`${iconClass} me-1`}
                    style={{
                        fontSize: '14px',
                        color: '#444',
                    }}
                />
                <div aria-disabled={disabled}>{text}</div>
            </div>
        </EllipsisDropdownItem>
    )
}

const ColumnPickerLayoutMenu = ({
    open,
    allGridLayouts: layouts,
    selectedLayout,
    onLayoutSelected,
    onSaveClick,
    onEditClick,
    onDeleteClick,
}: Props) => {
    const displayName = selectedLayout.unsaved ? '<Custom>' : selectedLayout.name
    return (
        <CustomDropdown
            open={open}
            displayText={displayName}
            scrollingSectionStyling={{
                maxHeight: 'calc(100vh - 550px)',
            }}
            footerDivider
            scrollableContent={
                <>
                    <LayoutsMenuSection
                        title="Defaults"
                        accessLevel="System"
                        allGridLayouts={layouts}
                        selectedLayout={selectedLayout}
                        onSelected={onLayoutSelected}
                    />
                    <LayoutsMenuSection
                        title="Global Layouts"
                        accessLevel="UserShared"
                        allGridLayouts={layouts}
                        selectedLayout={selectedLayout}
                        onSelected={onLayoutSelected}
                        topDivider
                    />
                    <LayoutsMenuSection
                        title="Personal Layouts"
                        accessLevel="UserPrivate"
                        allGridLayouts={layouts}
                        selectedLayout={selectedLayout}
                        onSelected={onLayoutSelected}
                        topDivider
                    />
                </>
            }
            fixedFooterContent={
                <div style={{ fontSize: '0.85em' }}>
                    <ActionMenuItem text="Save Layout As..." iconClass="bi-save" onClick={() => onSaveClick()} />
                    <ActionMenuItem
                        text="Edit Layout..."
                        iconClass="bi-pencil"
                        disabled={selectedLayout.unsaved || selectedLayout.accessLevel === 'System'}
                        onClick={onEditClick}
                    />
                    <ActionMenuItem
                        text="Delete Layout..."
                        iconClass="bi-trash"
                        disabled={selectedLayout.unsaved || selectedLayout.accessLevel === 'System'}
                        onClick={onDeleteClick}
                    />
                </div>
            }
        />
    )
}

export default ColumnPickerLayoutMenu
