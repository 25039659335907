import { AxiosRequestConfig } from 'axios'
import { Dispatch } from 'react'
import { insightsRecalculationActions, reportRecalculationActions } from 'store/recalculationStore'
import { ExportSchedulesRequest } from 'types/ExportSchedulesRequest'
import { RecalculationRequest } from 'types/RecalculationRequest'
import { RecalculationRequestStatus } from 'types/RecalculationStatus'
import Axios from '../axios/axios-sfc'
import SFApiBase from './sfApiBase'

// todo: move this into RecalculationRequest.ts so it can be used there.
export type RecalculateItemType = 'Report' | 'Insights'

class SFProcessingApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    private requestRecalculateItem = async (
        itemIds: number[],
        itemType: RecalculateItemType,
    ): Promise<RecalculationRequest> => {
        return (
            await this.axios.post(
                this.baseUrl + '/Api/FileProcessing/Recalculate',
                { itemIds, itemType },
                this.getConfig(),
            )
        ).data
    }
    /**
     * Begin asynchronous recalculation of the given item.
     */
    beginItemRefresh = async (ids: number[], itemType: RecalculateItemType, dispatch: Dispatch<any>) => {
        // call api to start recalculation on the server
        const recalculationRequest = await this.requestRecalculateItem(ids, itemType)

        if (itemType === 'Report') {
            dispatch(reportRecalculationActions.setRecalculationRequestIds([recalculationRequest.id]))
        }
        if (itemType === 'Insights') {
            dispatch(insightsRecalculationActions.setRecalculationRequestIds([recalculationRequest.id]))
        }
    }

    getExistingRecalculationRequests = async (): Promise<RecalculationRequest[]> => {
        return (
            await this.axios.get(
                this.baseUrl + '/Api/FileProcessing/AnyRecalculationRecordForUser?itemType=Any',
                this.getConfig(),
            )
        ).data
    }

    getRecalculationRequestStatus = async (requestIds: number[]): Promise<RecalculationRequestStatus[]> => {
        return (
            await this.axios.get(
                this.baseUrl + `/Api/FileProcessing/RecalculationStatus?recalculateRequestId=${requestIds.join(',')}`,
                this.getConfig(),
            )
        ).data
    }

    /**
     * Upload a data-file to be imported as a new Scenario.  Return the importRecordId.
     */
    importScenario = async (formData: FormData, uploadProgressHandler: (progressEvent: any) => void): Promise<void> => {
        const config: AxiosRequestConfig = {
            onUploadProgress: uploadProgressHandler,
            ...this.getConfig(),
        }
        await this.axios.post(this.baseUrl + '/Api/FileProcessing/ImportScenario', formData, config)
    }

    exportSchedules = async (exportRequest: ExportSchedulesRequest): Promise<void> => {
        await this.axios.post(this.baseUrl + '/Api/FileProcessing/ExportSchedules', exportRequest, this.getConfig())
    }
}

export default SFProcessingApi
