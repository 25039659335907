import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { BaseInputProps } from './FormControlBase'

export interface FormControlDatePickerProps extends BaseInputProps {
    value: Date
    onChange: (e: any) => void
}

const FormControlDatePicker = (props: FormControlDatePickerProps) => {
    const handleChange = (event: DatePickerChangeEvent) => {
        if (event.value) {
            props.onChange({ target: { name: props.id, value: event.value } })
        }
    }
    return (
        <DatePicker
            onChange={handleChange}
            value={new Date(props.value)}
            defaultValue={new Date()}
            id={props.id}
            name={props.id}
        />
    )
}

export default FormControlDatePicker
