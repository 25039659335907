import { ReactNode, useState } from 'react'
import { Form } from 'react-bootstrap'
import FormContent from 'views/Common/Form/FormContent'
import FormFooter from 'views/Common/Form/FormFooter'
import PageLayout from 'views/Common/Layout/PageLayout'

interface FormPageProps {
    headingContent: ReactNode
    children: React.ReactNode
    footerContent?: ReactNode
    onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
    onCancel?: () => void
    disabledSave?: boolean
    validatedForm?: boolean
}

const FormPage = (props: FormPageProps) => {
    const [configHeight, setConfigHeight] = useState(0)

    const footer = props.footerContent ? (
        props.footerContent
    ) : (
        <FormFooter disabledSave={props.disabledSave} onCancel={() => props.onCancel} />
    )

    return (
        <PageLayout headingContent={props.headingContent} onMainContentHeightChange={setConfigHeight}>
            <Form key="form" aria-label="form" noValidate validated={props.validatedForm} onSubmit={props.onSubmit}>
                <FormContent configHeight={configHeight}>{props.children}</FormContent>
                {footer}
            </Form>
        </PageLayout>
    )
}

export default FormPage
