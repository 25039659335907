import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow, SwitchFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import CommonDataSourceParameters from './CommonDataSourceParameters'
import FilterParameters from './FilterParameters'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface DataSourceSafteFastXMLProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceSafteFastCSV = (props: DataSourceSafteFastXMLProps) => {
    return (
        <>
            <CommonDataSourceParameters profile={props.profile} onChange={props.onChange} />

            <SwitchFormRow
                labelText="Ignore Planned Work Sleep Rules"
                subText="Do not load PWS rules from the data file"
                value={props.profile.ignorePlannedWorkSleepRules}
                onChange={props.onChange}
                fieldId="ignorePlannedWorkSleepRules"
            />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />
            <FilterParameters profile={props.profile} onChange={props.onChange} validatedForm={props.validatedForm} />
            <InputFileFormRow
                labelText="SAFTE-FAST XML FILE"
                fieldId="dataFieldPath1"
                subText="Local path to a schedules file (.csv, .xls, .xlsx)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceSafteFastCSV
