import { BaseDeterminationOnImportTypeNames, getRecordKeyValues } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    InputFileFormRow,
    InputNumericFormRow,
    InputSelectFormRow,
    MultiColumnFormRow,
    MultiColumnFormRowProps,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'

interface DataSourceZuluProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceZulu = (props: DataSourceZuluProps) => {
    const filterDateStartMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Date Start',
        subText: 'Include events starting on or after',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.dateStartFilterEnable,
                    onChange: props.onChange,
                    id: 'dateStartFilterEnable',
                },
            },
            {
                colType: 'datePickerInput',
                colWidth: 6,
                datePickerProps: {
                    value: props.profile.dateStartFilter,
                    onChange: props.onChange,
                    id: 'dateStartFilter',
                    required: true,
                },
            },
        ],
    }

    const filterDateEndMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Date End',
        subText: 'Include events ending on or before',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.dateEndFilterEnable,
                    onChange: props.onChange,
                    id: 'dateEndFilterEnable',
                },
            },
            {
                colType: 'datePickerInput',
                colWidth: 6,
                datePickerProps: {
                    value: props.profile.dateEndFilter,
                    onChange: props.onChange,
                    id: 'dateEndFilter',
                    required: true,
                },
            },
        ],
    }
    return (
        <>
            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <InputNumericFormRow
                labelText="Timezone Offset"
                subText="The offset between the watch timezone and the base station timezone"
                value={props.profile.timezoneOffset}
                onChange={props.onChange}
                fieldId="timezoneOffset"
                minValue={0}
                step={1}
                invalidText="Enter a value of 0 or greater"
            />

            <IndentedBlock headingText="Filters" subheadingText="Which events to include in the schedules">
                {props.profile.dateStartFilterEnable ? (
                    <MultiColumnFormRow {...filterDateStartMultiColumn} />
                ) : (
                    <SwitchFormRow
                        labelText="Date Start"
                        subText="Include events starting on or after"
                        value={props.profile.dateStartFilterEnable}
                        onChange={props.onChange}
                        fieldId="dateStartFilterEnable"
                    />
                )}

                {props.profile.dateEndFilterEnable ? (
                    <MultiColumnFormRow {...filterDateEndMultiColumn} />
                ) : (
                    <SwitchFormRow
                        labelText="Date End"
                        subText="Include events ending on or before"
                        value={props.profile.dateEndFilterEnable}
                        onChange={props.onChange}
                        fieldId="dateEndFilterEnable"
                    />
                )}
            </IndentedBlock>

            <InputFileFormRow
                labelText="Zulu Sleep File"
                fieldId="dataFieldPath1"
                subText="Local path to a Zulu file (.zip, .csv)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceZulu
