import { Card, ListGroup } from 'react-bootstrap'
import formatting from 'services/formatting/dateTimeFormatting'
import { SleepQualityEnum, TimeModeEnum } from 'types/interfaces'
import ScheduleEvent from 'types/ScheduleEvent'
import { getScheduleEventMetricField, PalettedValue } from 'views/Common/Palette/PalettedMetric'
import FixedWidthLabel from 'views/Common/Widget/FixedWidthLabel'
import { EventHoverArgs } from './EffectivenessGraphTypes'
import classes from './EventTimelineTooltip.module.css'

const createCell = (field: PalettedValue) => {
    return (
        <span className={classes.metricCell} style={{ color: field.foreColor, backgroundColor: field.backColor }}>
            {field.node}
        </span>
    )
}

// exporting the width so the graph timeline knows it
export const TooltipWidth = 350

const EventTimelineTooltip = (props: { hoverArgs: EventHoverArgs }) => {
    const basicEvent = props.hoverArgs.basicEvent
    const schedule = props.hoverArgs.schedule
    const left = `${props.hoverArgs.left}px`
    const top = `${props.hoverArgs.top}px`
    const startFormatted = formatting.formatDateTimeLongLocal(
        basicEvent.getStartMsForDisplay(props.hoverArgs.timeMode),
        true,
    )
    const endFormatted = formatting.formatDateTimeLongLocal(
        basicEvent.getEndMsForDisplay(props.hoverArgs.timeMode),
        true,
    )

    const getMetricField = (metricName: string) =>
        getScheduleEventMetricField(schedule.palettes, basicEvent, metricName)

    let continousSleepDurationSection = null
    let coloredTypeStrip = null
    let dutyNumber = null
    let metricsSection = null
    let previousNextSection = null
    let sleepDescription = null

    if (basicEvent.getBasicEventType() !== 'interval') {
        const scheduleEvent = basicEvent as ScheduleEvent
        dutyNumber =
            scheduleEvent.isWorkEvent() && scheduleEvent.dutyNumber > 0 ? (
                <strong style={{ whiteSpace: 'nowrap' }}>
                    Duty: <span className={classes.normalFont}>{scheduleEvent.dutyNumber}</span>
                </strong>
            ) : null

        let sleepQualityDescription = ''
        if (scheduleEvent.isAnySleep()) {
            sleepQualityDescription = ` - ${SleepQualityEnum[scheduleEvent.quality!]}`

            if (scheduleEvent.isAutoSleep()) {
                sleepDescription = `${scheduleEvent.getSleepCodeDescription()} - ${scheduleEvent.sleepCode}`
            }
        }

        const typeColorClass = `typeColor_${scheduleEvent.getDataType()}`
        const colorStripClass = `${classes.typeColor} ${classes[typeColorClass]}`
        coloredTypeStrip = (
            <ListGroup.Item className={colorStripClass}>
                {scheduleEvent.getDataTypeDescription()} {sleepQualityDescription}
            </ListGroup.Item>
        )

        const previousSleepGap = scheduleEvent.timeSincePreviousNonContigEvent
        const previousSleepGapFormatted =
            previousSleepGap !== null ? formatting.formatDurationHoursAndMinutes(previousSleepGap) : '-'
        const nextSleepGap = scheduleEvent.timeUntilNextNonContigEvent
        const nextSleepGapFormatted =
            nextSleepGap !== null ? formatting.formatDurationHoursAndMinutes(nextSleepGap) : '-'
        const fundamentalType = scheduleEvent.getFundamentalType()
        previousNextSection = (
            <>
                <div className={classes.bodySection}>
                    <FixedWidthLabel width={140}>Previous {fundamentalType}:</FixedWidthLabel>
                    <span>{previousSleepGapFormatted}</span>
                </div>
                <div>
                    <FixedWidthLabel width={140}>Next {fundamentalType}:</FixedWidthLabel>
                    <span>{nextSleepGapFormatted}</span>
                </div>
            </>
        )

        if (scheduleEvent.isAnySleep()) {
            const contDuration = scheduleEvent.continuousSleepDuration
            if (contDuration) {
                continousSleepDurationSection = (
                    <div>
                        <strong>Continuous Sleep: </strong>
                        <span>{formatting.formatDurationHoursAndMinutes(contDuration)}</span>
                    </div>
                )
            }
        }

        metricsSection = !scheduleEvent.isAnySleep() && (
            <>
                <div className={classes.bodySection}>
                    <FixedWidthLabel width={180}>Effectiveness Average:</FixedWidthLabel>
                    {createCell(getMetricField('effectivenessAvg'))}
                </div>
                <div>
                    <FixedWidthLabel width={180}>Effectiveness Minimum:</FixedWidthLabel>
                    {createCell(getMetricField('effectivenessMin'))}
                </div>
            </>
        )
    }

    return (
        <Card className="eventTimelineTooltip" style={{ left, top, width: TooltipWidth }}>
            <Card.Header>
                {sleepDescription != null ? (
                    // Auto Sleep Output Label
                    <>
                        {continousSleepDurationSection}
                        <div>
                            <strong>{sleepDescription}: </strong>
                            <span className={classes.normalFont}>
                                {formatting.formatDurationHoursAndMinutes(basicEvent.duration)}
                            </span>
                        </div>
                    </>
                ) : (
                    <div className={classes.header}>
                        <strong style={{ marginRight: '10px' }}>
                            <span>{basicEvent.label}: </span>
                            <span className={classes.normalFont}>
                                {formatting.formatDurationHoursAndMinutes(basicEvent.duration)}
                            </span>
                        </strong>
                        {dutyNumber}
                    </div>
                )}
            </Card.Header>
            <ListGroup variant="flush">
                {coloredTypeStrip}
                <ListGroup.Item>
                    <div>
                        <FixedWidthLabel width={50}>Start:</FixedWidthLabel>
                        {startFormatted} ({TimeModeEnum[props.hoverArgs.timeMode]})
                    </div>
                    <div>
                        <FixedWidthLabel width={50}>End:</FixedWidthLabel>
                        {endFormatted} ({TimeModeEnum[props.hoverArgs.timeMode]})
                    </div>
                    {metricsSection}
                    {previousNextSection}
                </ListGroup.Item>
            </ListGroup>
        </Card>
    )
}

export default EventTimelineTooltip
