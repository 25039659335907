import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { fetchAndSetProfileByType, selectProfileByType, setProfileFromApiCopy } from 'store/actions/profileActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { getProfileTitleByType, Profile } from 'types/ProfileInterfaces'
import { OperationMode } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'

export interface ProfileDetailsPageProps {
    type: ProfileModuleTypes
    detailsPanel: React.ComponentType<{
        operationMode: OperationMode
        enableCommonFields: boolean
        validatedForm: boolean
        id?: string
    }>
}

const getUrlPath = (type: ProfileModuleTypes) => {
    switch (type) {
        case 'Sleep':
            return '/settings/profiles/sleep/'
        case 'Metrics':
            return '/settings/profiles/metrics/'
        case 'Workload':
            return '/settings/profiles/workload/'
        case 'Work':
            return '/settings/profiles/work/'
        case 'Markers':
            return '/settings/profiles/markers/'
        case 'DataSource':
            return '/settings/profiles/datasource/'
        default:
            throw new Error('Invalid Profile Type')
    }
}

const ProfileDetailsPage = ({ type, detailsPanel: DetailsPanel }: ProfileDetailsPageProps) => {
    const { id, operationMode } = useDetailPageParams()
    const [validatedForm, setValidatedForm] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const idForGetProfile = operationMode === 'Copy' ? 'new' : id
    const profile = useAppSelector((state) => selectProfileByType(state, type, idForGetProfile)) as Profile

    // Set the profile state based on the operation mode
    useEffect(() => {
        const asyncCall = async () => {
            try {
                if (operationMode === 'Copy') {
                    await dispatch(setProfileFromApiCopy(id, type))
                } else {
                    const idToUse = operationMode === 'Create' ? 'new' : id
                    await dispatch(fetchAndSetProfileByType({ id: idToUse, type, forceReset: true }))
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        asyncCall()
    }, [operationMode, dispatch, id, type])

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            return
        }

        try {
            if (operationMode === 'Edit') {
                await globals.getApi().getProfileApi().updateProfile(profile, type)
            } else {
                await globals.getApi().getProfileApi().createProfile(profile, type)
            }
        } catch (error: any) {
            toast.error(error.message)
            return
        }

        navigate(getUrlPath(type))
    }

    const onCancelClick = () => {
        navigate(getUrlPath(type))
    }

    const title = `${operationMode} ${getProfileTitleByType(type)} Profile`
    const footer = <FormFooter disabledSave={false} onCancel={onCancelClick} />
    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            <DetailsPanel id={id} operationMode={operationMode} enableCommonFields validatedForm={validatedForm} />
        </FormPage>
    )
}

export default ProfileDetailsPage
