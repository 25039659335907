import { Col, Form, Row } from 'react-bootstrap'
import { SystemSetting } from 'types/SystemSetting'
import ColorPickerCustom from 'views/Common/Kendo/ColorPickerCustom'

interface Props {
    settings: SystemSetting[]
    handleInputChange: (name: string, value: string) => void
}
const ColorPaletteRow = ({ settings, handleInputChange }: Props) => {
    return (
        <Form.Group className="mb-3">
            <Row>
                <Col className="paletteColorPickerColumnLeft">
                    <ColorPickerCustom
                        value={settings[0].value}
                        id={settings[0].name}
                        onChange={(e) => handleInputChange(settings[0].name, e.value)}
                        className="paletteColorPickerLeft"
                    />
                </Col>
                <Col className="paletteColorPickerColumnInner">
                    <ColorPickerCustom
                        value={settings[1].value}
                        id={settings[1].name}
                        onChange={(e) => handleInputChange(settings[1].name, e.value)}
                        className="paletteColorPickerInner"
                    />
                </Col>
                <Col className="paletteColorPickerColumnInner">
                    <ColorPickerCustom
                        value={settings[2].value}
                        id={settings[2].name}
                        onChange={(e) => handleInputChange(settings[2].name, e.value)}
                        className="paletteColorPickerInner"
                    />
                </Col>
                <Col className="paletteColorPickerColumnRight">
                    <ColorPickerCustom
                        value={settings[3].value}
                        id={settings[3].name}
                        onChange={(e) => handleInputChange(settings[3].name, e.value)}
                        className="paletteColorPickerRight"
                    />
                </Col>
            </Row>
        </Form.Group>
    )
}

export default ColorPaletteRow
