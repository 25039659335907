import { GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { SleepQualityTypeNames } from 'types/EnumTypes'
import { EventFilter, SleepQualityRules } from 'types/ProfileInterfaces'
import { getDefaultSleepQualityRules } from 'types/ProfileInterfacesDefaults'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import EventFilterDialog from 'views/Common/EventFilter/EventFilterDialog'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'
import GridDropDownCell from 'views/Common/Kendo/GridCellComponents/GridDropDownCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'

interface SleepQualityRulesDialogProps {
    profileId: string
    onClose: () => void
}

const SleepQualityRulesDialog = (props: SleepQualityRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const profile = useAppSelector((s) => s.profile.profileAutoSleep)
    const [tempRules, setTempRules] = useState<SleepQualityRules[]>(profile?.sleepQualityRules ?? [])
    const [tempRule, setTempRule] = useState<SleepQualityRules | undefined>(undefined)
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    const [showEventFilter, setShowEventFilter] = useState(false)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    // operations
    const doNewRule = (existRecord?: SleepQualityRules) => {
        // clone defaults to create new instances
        const newRule = getDefaultSleepQualityRules(existRecord, tempRules)
        setTempRule(newRule)
        setShowEventFilter(true)
        setSelectedRowsState({ [newRule.id]: true })
    }
    const doCopyRule = () => {
        if (selectedIds.length !== 1) return

        // get selected rule to copy as new
        const item = tempRules.find((i) => i.id === selectedIds[0])
        if (item) {
            doNewRule(item)
        }
    }
    const doDeleteRules = () => {
        setShowDialog(deleteDialog)
    }

    function changeOrder(direction: MoveDirection) {
        setTempRules(reorderItemsList(selectedIds, tempRules, direction))
    }

    // grid
    const EDIT_FIELD = 'inEdit'
    const enterEditHandler = useCallback(
        (dataItem: SleepQualityRules, field: string | undefined) => {
            const newList = tempRules.map((item) => ({
                ...item,
                [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
            }))
            setTempRules(newList)
            setSelectedRowsState({ [dataItem.id]: true })
        },
        [tempRules],
    )
    const exitEditHandler = () => {
        const newData = tempRules.map((item) => ({ ...item, [EDIT_FIELD]: undefined }))
        setTempRules(newData)
    }
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        if (event.value < -1) return
        const field = event.field || ''
        const newList = tempRules.map((item) => {
            if (item.id === event.dataItem.id) {
                return { ...item, [field]: event.value }
            }
            return item
        })
        setTempRules(newList)
    }

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender originalProps={propsThe} td={td} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
    )

    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitEditHandler} editField={EDIT_FIELD} />
    )

    const getColumns = useMemo((): Array<KendoGridColumn> => {
        const getDropdownCell = (
            gridCellProps: GridCellProps,
            type: {
                [key in string]: string
            },
        ) => <GridDropDownCell {...gridCellProps} type={type} enterEdit={enterEditHandler} editField={EDIT_FIELD} />

        const columns: Array<KendoGridColumn> = [
            {
                title: 'Name',
                field: 'eventFilter.name',
                cell: (cellProps: GridCellProps) => {
                    const rule = cellProps.dataItem as SleepQualityRules
                    const cellContent = (
                        <TransparentButton
                            onClick={() => {
                                setTempRule(rule)
                                setShowEventFilter(true)
                            }}
                        >
                            {rule.eventFilter.name}
                        </TransparentButton>
                    )
                    return FormattedNameCell(cellProps, cellContent)
                },
            },
            {
                title: 'Sleep Quality',
                headerClassName: 'MultiLineCellHeader',
                children: [
                    {
                        title: 'Home',
                        field: 'home',
                        cell: (c: GridCellProps) => getDropdownCell(c, SleepQualityTypeNames),
                    },
                    {
                        title: 'Hotel',
                        field: 'hotel',
                        cell: (c: GridCellProps) => getDropdownCell(c, SleepQualityTypeNames),
                    },
                    {
                        title: 'Rest',
                        field: 'restFacility',
                        cell: (c: GridCellProps) => getDropdownCell(c, SleepQualityTypeNames),
                    },
                ],
            },
        ]
        return columns
    }, [enterEditHandler])

    // event filter
    const handleEventFilterClose = (result: DialogResultEnum, eventFilter?: EventFilter) => {
        if (result === DialogResultEnum.Completed && eventFilter && tempRule) {
            const itemIndex = tempRules.findIndex((x) => x.id === tempRule.id)
            if (itemIndex >= 0) {
                const newList = tempRules.map((s) => {
                    if (s.id === tempRule.id) return { ...s, eventFilter }
                    return s
                })
                setTempRules(newList)
            } else {
                setTempRules([...tempRules, { ...tempRule, eventFilter }])
            }
        }
        setShowEventFilter(false)
    }

    // header
    const headerContent = 'Sleep Quality Override Rules'

    // grid layout
    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={tempRules}
                                selectedIds={selectedIds}
                                onAddClick={doNewRule}
                                onCopyClick={doCopyRule}
                                onDeleteClick={doDeleteRules}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={tempRules}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        onItemChange={itemEditedHandler}
                    />
                </Col>
            </Row>
        </Container>
    )

    // dialogs
    const deleteDialog = useMemo(
        () => (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Sleep Quality Rule"
                setTempItems={setTempRules}
                tempItems={tempRules}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        ),
        [selectedIds, tempRules],
    )

    if (!profile) {
        return <> </>
    }

    const footerContent = (
        <FormFooter
            onCancel={props.onClose}
            onOk={() => {
                // update profile with temp rules
                const updated = { ...profile, sleepQualityRules: tempRules }
                dispatch(profileActions.setProfileByType({ profile: updated, type: 'Sleep' }))
                props.onClose()
            }}
        />
    )

    // layout components
    return (
        <>
            {showEventFilter ? (
                <EventFilterDialog eventFilter={tempRule?.eventFilter} onClose={handleEventFilterClose} />
            ) : (
                <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={props.onClose}>
                    {gridContent}
                </DialogLayout>
            )}
            {showDialog}
        </>
    )
}

export default SleepQualityRulesDialog
