const SessionTokenId = 'session_token'

function setSessionToken(token: string): void {
    window.localStorage.setItem(SessionTokenId, token)
}

function deleteSessionToken(): void {
    window.localStorage.removeItem(SessionTokenId)
}
function getSessionToken(): string | null {
    return window.localStorage.getItem(SessionTokenId)
}

export { setSessionToken, getSessionToken, deleteSessionToken }
