import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GridLayout, GridLayoutIdentifier } from 'types/GridLayout'

export type GridLayoutState = {
    items: GridLayout[]
}

const initialGridLayoutState: GridLayoutState = { items: [] }

const gridLayoutsReducer = createSlice({
    name: 'gridLayouts',
    initialState: initialGridLayoutState,
    reducers: {
        setGridLayouts(state, action: PayloadAction<GridLayout[]>) {
            state.items = action.payload
        },
        setGridDataStateExceptFilters(
            state,
            action: PayloadAction<{ gridLayoutName: GridLayoutIdentifier; dataState: State }>,
        ) {
            const gridLayout = state.items.find((x: GridLayout) => x.gridId === action.payload.gridLayoutName)!
            const filter = gridLayout.configurationJson.dataState!.filter
            gridLayout.configurationJson.dataState! = { ...action.payload.dataState, filter }
        },
        setGridFilteringEnabled(
            state,
            action: PayloadAction<{ gridLayoutName: GridLayoutIdentifier; filteringEnabled: boolean }>,
        ) {
            const gridLayout = state.items.find((x: GridLayout) => x.gridId === action.payload.gridLayoutName)!
            gridLayout.configurationJson.filtersEnabled = action.payload.filteringEnabled
        },
        setGridFilters(
            state,
            action: PayloadAction<{ gridLayoutName: GridLayoutIdentifier; filters: CompositeFilterDescriptor }>,
        ) {
            const gridLayout = state.items.find((x: GridLayout) => x.gridId === action.payload.gridLayoutName)!
            gridLayout.configurationJson.dataState!.filter = action.payload.filters
        },
    },
})

export default gridLayoutsReducer.reducer
export const gridLayoutActions = gridLayoutsReducer.actions
