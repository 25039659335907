export type WorkTypes = 'Preparation' | 'CommuteToWork' | 'Brief' | 'Debrief' | 'CommuteFromWork' | 'Unwind'

export interface DutyAutoWorkType {
    workType: WorkTypes
    label: string
}

interface MetaData {
    refreshReportsSynchronously: boolean
    refreshInsightsSynchronously: boolean
    userCustomFields: string | null
    industry: string
    dutyAutoWorkTypes: DutyAutoWorkType[]
    plannedWorkSleepQualities: string[]
    sleepQualities: string[]
    regionsCodesList: string[]
    countryCodesList: string[]
}
/**
 * Convert from any to MetaData type
 * @param data
 * @returns
 */
export const parseMetadata = (data: any): MetaData => {
    if (!data) {
        throw new Error('Unable to parse null or undefined metadata object from api')
    }
    return {
        refreshReportsSynchronously: Boolean(data.refreshReportsSynchronously),
        refreshInsightsSynchronously: Boolean(data.refreshInsightsSynchronously),
        industry: data.industry,
        userCustomFields: data.userCustomFields,
        plannedWorkSleepQualities: data.plannedWorkSleepQualities,
        dutyAutoWorkTypes: data.dutyAutoWorkTypes,
        sleepQualities: data.sleepQualities,
        regionsCodesList: data.regionsCodesList,
        countryCodesList: data.countryCodesList,
    }
}

export default MetaData
