import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import globals from 'services/global/globals'
import { selectProfileByType } from 'store/actions/profileActions'
import { useAppSelector } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { Profile } from 'types/ProfileInterfaces'
import { EllipsisDropdownItem, IndentedDropdownItem } from 'views/Common/Buttons/EllipsisDropdown'
import { ActionMenuItem } from 'views/Common/GenericDialogs/ColumnPickerDialog/ColumnPickerLayoutMenu'
import CustomDropdown from 'views/Common/Inputs/RichDropdown'

export interface ProfileSelectorToolProps {
    profileType: ProfileModuleTypes
    dataSourceId?: string
    onSelected: (profileId: string, oldId: string) => void
    onSave: () => void
}
const ProfileSelectorTool = ({ dataSourceId, profileType, onSelected, onSave }: ProfileSelectorToolProps) => {
    const [profilesList, setProfilesList] = useState<Profile[]>([])
    const profile = useAppSelector((rs) => selectProfileByType(rs, profileType, dataSourceId)) as Profile | null
    const navigate = useNavigate()
    useEffect(() => {
        const loadData = async () => {
            setProfilesList(await globals.getApi().getProfileApi().getProfileList(profileType))
        }
        loadData()
    }, [profileType])

    const profileName = profile ? profile.name : ''
    const profileId = profile ? profile.id : ''

    return (
        <CustomDropdown
            displayText={profileName}
            scrollableContent={
                <>
                    {profilesList.map((profileOption) => (
                        <EllipsisDropdownItem
                            key={profileOption.id}
                            onClick={() => onSelected(profileOption.id, profileId)}
                        >
                            <IndentedDropdownItem checked={profileOption.name === profileName}>
                                {profileOption.name}
                            </IndentedDropdownItem>
                        </EllipsisDropdownItem>
                    ))}
                </>
            }
            fixedFooterContent={
                <div style={{ fontSize: '0.85em' }}>
                    <ActionMenuItem text="Save Custom Profile As..." iconClass="bi-save" onClick={() => onSave()} />
                    <ActionMenuItem
                        text="Manage Profiles"
                        iconClass="bi-pencil"
                        onClick={() => navigate(`/settings/profiles/${profileType.toLowerCase()}`)}
                    />
                </div>
            }
        />
    )
}

export default ProfileSelectorTool
