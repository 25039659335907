/* eslint-disable no-case-declarations */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileModuleTypes } from 'types/EnumTypes'
import {
    Profile,
    ProfileAutoMarker,
    ProfileAutoSleep,
    ProfileDataSource,
    ProfileMetricsConfiguration,
    ProfileWork,
    ProfileWorkload,
} from 'types/ProfileInterfaces'
import TemplateDetail from 'types/TemplateDetail'

type ProfileDataSourceState = {
    profileDataSource: ProfileDataSource
    modified: boolean
}

export type ProfileStoreState = {
    profileWork: ProfileWork | null
    profileWorkModified: boolean
    profileAutoSleep: ProfileAutoSleep | null
    profileAutoSleepModified: boolean
    profileMetricsConfigurations: ProfileMetricsConfiguration | null
    profileMetricsConfigurationsModified: boolean
    profileWorkload: ProfileWorkload | null
    profileWorkloadModified: boolean
    profileAutoMarkers: ProfileAutoMarker | null
    profileAutoMarkersModified: boolean
    profileDataSources: ProfileDataSourceState[]
    template: TemplateDetail | null
    templateModified: boolean
}

const initialProfileState: ProfileStoreState = {
    profileWork: null,
    profileWorkModified: false,
    profileAutoSleep: null,
    profileAutoSleepModified: false,
    profileMetricsConfigurations: null,
    profileMetricsConfigurationsModified: false,
    profileWorkload: null,
    profileWorkloadModified: false,
    profileAutoMarkers: null,
    profileAutoMarkersModified: false,
    profileDataSources: [],
    template: null,
    templateModified: false,
}

const profileReducer = createSlice({
    name: 'profile',
    initialState: initialProfileState,
    reducers: {
        clearProfilesAndTemplate(state) {
            state.profileWork = null
            state.profileWorkModified = false

            state.profileAutoSleep = null
            state.profileAutoSleepModified = false

            state.profileMetricsConfigurations = null
            state.profileMetricsConfigurationsModified = false

            state.profileWorkload = null
            state.profileWorkloadModified = false

            state.profileAutoMarkers = null
            state.profileAutoMarkersModified = false

            state.profileDataSources = []

            state.template = null
            state.templateModified = false
        },

        setProfileByType(
            state,
            action: PayloadAction<{ type: ProfileModuleTypes; profile: Profile | null; isModified?: boolean }>,
        ) {
            const isModified = action.payload.isModified ?? false
            switch (action.payload.type) {
                case 'Work':
                    state.profileWork = action.payload.profile as ProfileWork
                    state.profileWorkModified = isModified
                    break
                case 'Sleep':
                    state.profileAutoSleep = action.payload.profile as ProfileAutoSleep
                    state.profileAutoSleepModified = isModified
                    break
                case 'Metrics':
                    state.profileMetricsConfigurations = action.payload.profile as ProfileMetricsConfiguration
                    state.profileMetricsConfigurationsModified = isModified
                    break
                case 'Workload':
                    state.profileWorkload = action.payload.profile as ProfileWorkload
                    state.profileWorkloadModified = isModified
                    break
                case 'Markers':
                    state.profileAutoMarkers = action.payload.profile as ProfileAutoMarker
                    state.profileAutoMarkersModified = isModified
                    break
                case 'DataSource':
                    const profileDatasource = action.payload.profile as ProfileDataSource
                    const index = state.profileDataSources.findIndex(
                        (i) => i.profileDataSource.id === profileDatasource.id,
                    )
                    if (index >= 0) {
                        state.profileDataSources[index].profileDataSource = profileDatasource
                        state.profileDataSources[index].modified = isModified
                    } else {
                        state.profileDataSources.push({ profileDataSource: profileDatasource, modified: false })
                    }
                    break
                default:
                    break
            }

            // only update the modified flag if there's a template; if there's no template,
            // we must be on a Profile Details Page, so there's no template.
            if (state.template && isModified) {
                state.templateModified = isModified
            }
        },
        setTemplate(state, action: PayloadAction<{ template: TemplateDetail | null; modified?: boolean }>) {
            if (!action.payload.template) {
                state.template = null
                return
            }
            state.template = action.payload.template
            state.templateModified = action.payload.modified ?? false
        },
        addNewDataSourceToTemplate(state, action: PayloadAction<ProfileDataSource>) {
            if (!state.template) {
                throw new Error('Template is not set')
            }
            const newDataSource = action.payload
            state.profileDataSources.push({ profileDataSource: newDataSource, modified: false })
            if (!state.template.profileDataSourceIds) {
                state.template.profileDataSourceIds = []
            }
            state.template.profileDataSourceIds.push(newDataSource.id)
            state.templateModified = true
        },
        removeDataSourceFromTemplate(state, action: PayloadAction<string>) {
            if (!state.template) {
                throw new Error('Template is not set')
            }
            const id = action.payload
            const index = state.profileDataSources.findIndex((i) => i.profileDataSource.id === id)
            if (index < 0) {
                throw new Error('Profile Data Source not found')
            }
            state.profileDataSources.splice(index, 1)
            state.template.profileDataSourceIds = state.template.profileDataSourceIds!.filter((i) => i !== id)
        },
    },
})

export const profileActions = profileReducer.actions

export default profileReducer.reducer
