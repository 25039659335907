import { BaseDeterminationOnImportTypeNames, getRecordKeyValues } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputNumericFormRow, InputSelectFormRow, SwitchFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface CommonDataSourceParameterProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
}
const CommonDataSourceParameters = (props: CommonDataSourceParameterProps) => {
    return (
        <>
            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <SwitchFormRow
                labelText="Combine Schedules With Operating Dates"
                subText="Schedules with identical events but operating on different dates will be combined"
                value={props.profile.combineSchedulesWithOperatingDates}
                onChange={props.onChange}
                fieldId="combineSchedulesWithOperatingDates"
            />

            <InputNumericFormRow
                labelText="Combine Events into Duties within (Max Break)"
                subText="Events that occur within the defined duration will be combined into a duty. Ie. maximum break between duties"
                value={props.profile.combineEventsIntoDutiesWithin}
                onChange={props.onChange}
                fieldId="combineEventsIntoDutiesWithin"
                minValue={0}
                invalidText="Enter a value of 0 or greater"
            />
        </>
    )
}

export default CommonDataSourceParameters
