import _ from 'lodash'
import { useLayoutEffect, useState } from 'react'

/**
 * Custom hook for window resizing.
 */
const useWindowSize = (throttleMs?: number) => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight])
    useLayoutEffect(() => {
        let isMounted = true
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener(
            'resize',
            _.throttle(() => {
                if (isMounted) {
                    updateSize()
                }
            }, throttleMs || 0),
        )

        updateSize()
        return () => {
            isMounted = false
            window.removeEventListener('resize', updateSize)
        }
    }, [throttleMs])
    return size
}

export default useWindowSize
