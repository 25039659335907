import { GridCellProps } from '@progress/kendo-react-grid'
import { useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch } from 'store/store'
import { EventRules, ProfileAutoSleep, SleepRules } from 'types/ProfileInterfaces'
import { getDefaulSleepRules } from 'types/ProfileInterfacesDefaults'
import IconButton from 'views/Common/Buttons/IconButton'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'
import PWSSleepRuleDetails, { isPwsSleepRuleValid } from './PWSSleepRuleDetails'

interface PWSSleepRulesDialogProps {
    profile: ProfileAutoSleep
    onClose: () => void
}

const PWSSleepRulesDialog = ({ profile, onClose }: PWSSleepRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const [tempRules, setTempRules] = useState<SleepRules[]>(() => profile.sleepRules)
    const [tempRulesEvent, setTempRulesEvent] = useState<EventRules[]>(() => profile.eventRules)
    const [tempRule, setTempRule] = useState<SleepRules | null>(null)
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    const [showSleepRuleDetails, setShowSleepRuleDetails] = useState(false)
    const [validated, setValidated] = useState(false)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    useEffect(() => {
        // if the displayed dialog changes, we can reset validation
        setValidated(false)
    }, [showSleepRuleDetails])

    // operations
    const addNewRecord = (existRecord?: SleepRules) => {
        const newRule = getDefaulSleepRules(existRecord, tempRules)
        setTempRule(newRule)
        setShowSleepRuleDetails(true)
        setSelectedRowsState({ [newRule.id]: true })
    }
    const addCopyRecord = () => {
        if (selectedIds.length !== 1) return

        // get selected rule to copy as new
        const item = tempRules.find((i) => i.id === selectedIds[0])
        if (item) {
            addNewRecord(item)
        }
    }
    const deleteRules = () => {
        setShowDialog(deleteDialog)
    }
    function changeOrder(direction: MoveDirection) {
        setTempRules(reorderItemsList(selectedIds, tempRules, direction))
    }

    // grid
    const getColumns = useMemo((): Array<KendoGridColumn> => {
        const columns: Array<KendoGridColumn> = [
            { title: 'Sleep Quality', field: 'quality' },
            { title: 'Duration Type', field: 'durationTypeText' },
            { title: 'Start Exclude', field: 'durationS' },
            { title: 'End Exclude', field: 'durationE' },
            { title: 'Sleep Periods', field: 'sleepPeriods.length' },
        ]
        columns.unshift({
            title: 'Name',
            field: 'name',
            cell: (cellProps: GridCellProps) => {
                const rule = cellProps.dataItem as SleepRules
                const cellContent = (
                    <TransparentButton
                        onClick={() => {
                            setTempRule(rule)
                            setShowSleepRuleDetails(true)
                        }}
                    >
                        {rule.name}
                    </TransparentButton>
                )
                return FormattedNameCell(cellProps, cellContent)
            },
        })
        return columns
    }, [])

    // sleep rule details
    const sleepRuleDetailsDialogHeaderContent = (
        <>
            <IconButton
                tooltip="Back"
                onClick={() => setShowSleepRuleDetails(false)}
                style={{ fontSize: '20px', marginRight: '20px' }}
                icon="bi-arrow-left"
            />
            Planned Work - Sleep Rule
        </>
    )

    const sleepRuleDetailsContent = (
        <PWSSleepRuleDetails
            sleepRule={tempRule!}
            onChange={(e: FormControlChangeEvent) => {
                setTempRule((prev) => updateObjectWithFormEvent(prev, e))
            }}
            onChangeSleepPeriods={(sleepPeriods) => {
                setTempRule((prev) => ({
                    ...prev!,
                    sleepPeriods,
                }))
            }}
            validated={validated}
        />
    )

    const sleepRuleDetailsDialogFooter = (
        <FormFooter
            onCancel={() => setShowSleepRuleDetails(false)}
            onOk={() => {
                if (tempRules && tempRule) {
                    if (!isPwsSleepRuleValid(tempRule)) {
                        setValidated(true)
                        return
                    }

                    // update temp rules with the selected rule
                    if (tempRules.find((r) => r.id === tempRule.id)) {
                        const updatedRules = tempRules.map((rule) => (rule.id === tempRule.id ? tempRule : rule))
                        setTempRules(updatedRules)
                    } else {
                        setTempRules([...tempRules, tempRule])
                    }
                }

                // clear the dialog
                setShowSleepRuleDetails(false)
                setTempRule(null)
            }}
        />
    )

    // header
    const headerContent = 'Planned Work - Sleep Rules'

    // grid
    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={tempRules}
                                selectedIds={selectedIds}
                                onAddClick={addNewRecord}
                                onCopyClick={addCopyRecord}
                                onDeleteClick={deleteRules}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        // adjust the data to include calculated fields like durationTypeText
                        data={tempRules.map((x) => ({
                            ...x,
                            durationTypeText: x.durationType === 'Fixed' ? 'Fixed' : 'Formula',
                        }))}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        defaultEmptyGridText="No Rules Configured"
                        pageable={false}
                    />
                </Col>
            </Row>
        </Container>
    )

    // footerContent
    const footerContent = (
        <FormFooter
            onCancel={onClose}
            onOk={() => {
                // update profile with temp rules
                const updated = { ...profile, sleepRules: tempRules, eventRules: tempRulesEvent }
                dispatch(profileActions.setProfileByType({ profile: updated, type: 'Sleep' }))
                onClose()
            }}
        />
    )

    // dialogs
    const deleteDialog = useMemo(() => {
        const isRuleUsed = (factorIds: string[]) => {
            return tempRulesEvent.find((x) => factorIds.includes(x.sleepRuleId)) !== undefined
        }

        return (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Planned Work Sleep - Sleep Rule"
                message={isRuleUsed(selectedIds) ? 'The Sleep Rule has been used in Event Rules.' : undefined}
                setTempItems={setTempRules}
                tempItems={tempRules}
                selectedIdsForDelete={selectedIds}
                onClose={(dialogResult: DialogResultEnum) => {
                    if (dialogResult === DialogResultEnum.Completed) {
                        // delete the corresponding rules
                        setTempRulesEvent(tempRulesEvent.filter((x) => !selectedIds.includes(x.sleepRuleId)))
                    }

                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        )
    }, [selectedIds, tempRules, tempRulesEvent])

    return (
        <>
            {showSleepRuleDetails ? (
                <DialogLayout
                    headerContent={sleepRuleDetailsDialogHeaderContent}
                    footerContent={sleepRuleDetailsDialogFooter}
                    onClose={onClose}
                >
                    {sleepRuleDetailsContent}
                </DialogLayout>
            ) : (
                <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={onClose}>
                    {gridContent}
                </DialogLayout>
            )}
            {showDialog}
        </>
    )
}

export default PWSSleepRulesDialog
