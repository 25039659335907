import { useMemo } from 'react'
import { convertQueryBuilderToSql } from 'services/queryBuilder/queryBuilderService'
import { CrewingTypeNames, getRecordKeyValues, OverlapTypeNames, ZoneTypeNames } from 'types/EnumTypes'
import { EventFilter } from 'types/ProfileInterfaces'
import { ReportItemDefinition } from 'types/ReportingMetadata'
import {
    DrillDownFormRow,
    InputSelectFormRow,
    InputTextFormRow,
    MultiColumnFormRow,
    MultiColumnFormRowProps,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'
import ProfileCommonFields from 'views/Settings/ParameterPanels/ProfileCommonFields'
import { FormControlChangeEvent } from '../Form/FormControls/FormControlBase'

interface EventFilterPanelProps {
    eventFilter: EventFilter
    itemDefinitions: ReportItemDefinition[]
    onQueryOpen: () => void
    onFilterChange: (e: FormControlChangeEvent) => void
    validated?: boolean // todo: make non-optional and implement in other usages
}
const EventFilterPanel = ({
    eventFilter,
    validated,
    onQueryOpen,
    onFilterChange,
    itemDefinitions,
}: EventFilterPanelProps) => {
    const ruleSql = useMemo(() => {
        return convertQueryBuilderToSql(eventFilter.eventQuery, itemDefinitions)
    }, [eventFilter.eventQuery, itemDefinitions])

    const durationMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Duration',
        subText: 'Value between',
        labelColWidth: 3,
        columns: [
            {
                colWidth: 3,
                colType: 'numericInput',
                inputNumericProps: {
                    value: eventFilter.durationFrom,
                    id: 'durationFrom',
                    onChange: onFilterChange,
                },
            },
            {
                colWidth: 1,
                value: 'and',
            },
            {
                colWidth: 3,
                colType: 'numericInput',
                inputNumericProps: {
                    value: eventFilter.durationTo,
                    id: 'durationTo',
                    onChange: onFilterChange,
                    required: true,
                    min: 1,
                    validated,
                },
            },
        ],
    }

    const routeMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Stations',
        subText: 'The station code(s)',
        labelColWidth: 3,
        columns: [
            {
                colWidth: 3,
                colType: 'textInput',
                inputTextProps: {
                    value: eventFilter.routeFrom,
                    id: 'routeFrom',
                    onChange: onFilterChange,
                    required: true,
                    invalidText: 'Station code is required',
                    validated,
                },
            },
            {
                colWidth: 1,
                value: 'to',
            },
            {
                colWidth: 3,
                colType: 'textInput',
                inputTextProps: {
                    value: eventFilter.routeTo,
                    id: 'routeTo',
                    onChange: onFilterChange,
                    required: true,
                    invalidText: 'Station code is required',
                    validated,
                },
            },
        ],
    }

    const timeOfDayMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Time',
        subText: 'Value between',
        labelColWidth: 5,
        columns: [
            {
                colWidth: 3,
                colType: 'timePickerInput',
                timePickerProps: {
                    value: eventFilter.timeOfDayFrom,
                    id: 'timeOfDayFrom',
                    onChange: onFilterChange,
                },
            },
            {
                colWidth: 1,
                value: 'and',
            },
            {
                colWidth: 3,
                colType: 'timePickerInput',
                timePickerProps: {
                    value: eventFilter.timeOfDayTo,
                    id: 'timeOfDayTo',
                    onChange: onFilterChange,
                },
            },
        ],
    }

    const crewComplementMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Complement',
        subText: 'Value between',
        labelColWidth: 3,
        columns: [
            {
                colWidth: 3,
                colType: 'numericInput',
                inputNumericProps: {
                    value: eventFilter.crewComplementFrom,
                    id: 'crewComplementFrom',
                    onChange: onFilterChange,
                },
            },
            {
                colWidth: 1,
                value: 'and',
            },
            {
                colWidth: 3,
                colType: 'numericInput',
                inputNumericProps: {
                    value: eventFilter.crewComplementTo,
                    id: 'crewComplementTo',
                    onChange: onFilterChange,
                    required: true,
                    min: 1,
                    invalidText: 'Value must be greater than 0',
                    validated,
                },
            },
        ],
    }

    return (
        <>
            <FormSection title="Details">
                <ProfileCommonFields
                    nameField={eventFilter.name}
                    descriptionField={eventFilter.description}
                    onChange={onFilterChange}
                />
            </FormSection>
            <FormSection title="Filters">
                <DrillDownFormRow
                    labelText="Events Query"
                    subText="Filter using dynamic fields and logical operators"
                    data={eventFilter.eventQuery}
                    value={ruleSql}
                    onClick={onQueryOpen}
                />

                <IndentedBlock headingText="Event Conditions" subheadingText="Filter using specific conditions">
                    <SwitchFormRow
                        labelText="Event Type"
                        subText="Select events by type"
                        value={eventFilter.crewingEnable}
                        onChange={onFilterChange}
                        fieldId="crewingEnable"
                    />

                    {eventFilter.crewingEnable && (
                        <IndentedBlock headingText="">
                            <InputSelectFormRow
                                labelText="Type"
                                subText="The event type or context"
                                value={eventFilter.crewingType}
                                onChange={onFilterChange}
                                fieldId="crewingType"
                                options={getRecordKeyValues(CrewingTypeNames)}
                            />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Label"
                        subText="Select events by label"
                        value={eventFilter.eventsEnable}
                        onChange={onFilterChange}
                        fieldId="eventsEnable"
                    />

                    {eventFilter.eventsEnable && (
                        <IndentedBlock headingText="">
                            <InputTextFormRow
                                labelText="Label"
                                fieldId="events"
                                subText="The event label(s)"
                                value={eventFilter.events}
                                onChange={onFilterChange}
                                required
                                validated={validated}
                                invalidText="Enter labels separated by commas"
                            />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Duration"
                        subText="Select events by duration"
                        value={eventFilter.durationEnable}
                        onChange={onFilterChange}
                        fieldId="durationEnable"
                    />

                    {eventFilter.durationEnable && (
                        <IndentedBlock headingText="">
                            <MultiColumnFormRow {...durationMultiColumn} />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Location"
                        subText="Select events by location"
                        value={eventFilter.locationEnable}
                        onChange={onFilterChange}
                        fieldId="locationEnable"
                    />

                    {eventFilter.locationEnable && (
                        <IndentedBlock headingText="">
                            <InputTextFormRow
                                labelText="Stations"
                                fieldId="locationCode"
                                subText="The station code(s)"
                                value={eventFilter.locationCode}
                                onChange={onFilterChange}
                                required
                                validated={validated}
                                invalidText="Enter locations separated by commas"
                            />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Route"
                        subText="Select events by location route"
                        value={eventFilter.routeEnable}
                        onChange={onFilterChange}
                        fieldId="routeEnable"
                    />

                    {eventFilter.routeEnable && (
                        <IndentedBlock headingText="">
                            <MultiColumnFormRow {...routeMultiColumn} />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Time of Day"
                        subText="Selects events by time"
                        value={eventFilter.timeOfDayEnable}
                        onChange={onFilterChange}
                        fieldId="timeOfDayEnable"
                    />

                    {eventFilter.timeOfDayEnable && (
                        <IndentedBlock headingText="">
                            <MultiColumnFormRow {...timeOfDayMultiColumn} />
                            <InputSelectFormRow
                                labelText="Reference"
                                subText="The time reference"
                                value={eventFilter.timeOfDayZoneType}
                                onChange={onFilterChange}
                                fieldId="timeOfDayZoneType"
                                options={getRecordKeyValues(ZoneTypeNames)}
                            />
                            <InputSelectFormRow
                                labelText="Overlap"
                                subText="How to interpret overlapping ranges"
                                value={eventFilter.overlapType}
                                onChange={onFilterChange}
                                fieldId="overlapType"
                                options={getRecordKeyValues(OverlapTypeNames)}
                            />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Equipment Type"
                        subText="Select events by equipment type"
                        value={eventFilter.equipmentTypesEnable}
                        onChange={onFilterChange}
                        fieldId="equipmentTypesEnable"
                    />

                    {eventFilter.equipmentTypesEnable && (
                        <IndentedBlock headingText="">
                            <InputTextFormRow
                                labelText="Type"
                                fieldId="equipmentTypes"
                                subText="The equipment type(s)"
                                value={eventFilter.equipmentTypes}
                                onChange={onFilterChange}
                                required
                                validated={validated}
                                invalidText="Enter equipment types separated by commas"
                            />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Crew Complement"
                        subText="Select events by crew complement"
                        value={eventFilter.crewComplementEnable}
                        onChange={onFilterChange}
                        fieldId="crewComplementEnable"
                    />

                    {eventFilter.crewComplementEnable && (
                        <IndentedBlock headingText="">
                            <MultiColumnFormRow {...crewComplementMultiColumn} />
                        </IndentedBlock>
                    )}

                    <SwitchFormRow
                        labelText="Crew Positions"
                        subText="Select events by crew positions"
                        value={eventFilter.crewPositionsEnable}
                        onChange={onFilterChange}
                        fieldId="crewPositionsEnable"
                    />

                    {eventFilter.crewPositionsEnable && (
                        <IndentedBlock headingText="">
                            <InputTextFormRow
                                labelText="Position"
                                fieldId="crewPositions"
                                subText="The position code(s)"
                                value={eventFilter.crewPositions}
                                onChange={onFilterChange}
                                required
                                validated={validated}
                                invalidText="Enter crew position codes separated by commas"
                            />
                        </IndentedBlock>
                    )}
                </IndentedBlock>
            </FormSection>
        </>
    )
}

export default EventFilterPanel
