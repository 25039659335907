import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query'
import { AnyAction, Dispatch, ThunkAction } from '@reduxjs/toolkit'
import { getActiveGridLayout } from 'hooks/useGridLayout'
import globals from 'services/global/globals'
import { gridLayoutActions } from 'store/gridLayoutStore'
import { RootState } from 'store/store'
import { GridLayout, GridLayoutIdentifier } from 'types/GridLayout'

/**
 * fetch grid layouts into redux
 * @returns
 */
const getGridLayoutData = (): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        if (state.gridLayouts.items.length > 0) {
            // grid layouts are already loaded into redux
            return
        }
        const layouts = await globals.getApi().getGridLayouts()
        dispatch(gridLayoutActions.setGridLayouts(layouts))
    }
}

const refetchGridLayouts = async (dispatch: Dispatch) => {
    const api = globals.getApi()
    const layouts = await api.getGridLayouts()
    dispatch(gridLayoutActions.setGridLayouts(layouts))
}

const saveAndRefetchGridLayouts = async (dispatch: Dispatch, gridLayoutId: string, gridLayoutJson: string) => {
    const api = globals.getApi()
    await api.updateGridLayout(gridLayoutId, gridLayoutJson)
    await refetchGridLayouts(dispatch)
}

const saveGridLayoutAs = (
    gridLayoutId: string,
    newName: string,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch) => {
        await globals.getApi().saveGridLayoutAs(gridLayoutId, newName)
        await refetchGridLayouts(dispatch)
    }
}

const updateGridLayout = (
    gridLayoutId: string,
    configurationJson: string,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch) => {
        await saveAndRefetchGridLayouts(dispatch, gridLayoutId, configurationJson)
    }
}

const setActiveGridLayout = (gridLayoutId: string): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch) => {
        await globals.getApi().setActiveGridLayout(gridLayoutId)
        await refetchGridLayouts(dispatch)
    }
}

const deleteGridLayout = (gridLayoutId: string): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch) => {
        await globals.getApi().deleteGridLayout(gridLayoutId)
        await refetchGridLayouts(dispatch)
    }
}

const updateGridFilters = (
    gridLayoutName: GridLayoutIdentifier,
    filters: CompositeFilterDescriptor,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const activeGridLayoutCopy = JSON.parse(
            JSON.stringify(getActiveGridLayout(getState().gridLayouts.items, gridLayoutName)),
        ) as GridLayout
        activeGridLayoutCopy.configurationJson.dataState!.filter = filters
        await saveAndRefetchGridLayouts(
            dispatch,
            activeGridLayoutCopy.id,
            JSON.stringify(activeGridLayoutCopy.configurationJson),
        )
    }
}

export interface ColumnWithOrder {
    field: string
    orderIndex: number | undefined
}

const updateGridColumnsOrder = (
    gridLayoutName: GridLayoutIdentifier,
    updatedColumns: ColumnWithOrder[],
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const activeGridLayoutCopy = JSON.parse(
            JSON.stringify(getActiveGridLayout(getState().gridLayouts.items, gridLayoutName)),
        ) as GridLayout

        updatedColumns.forEach((updatedColumn) => {
            const columnDefinition = activeGridLayoutCopy.configurationJson.columns.find(
                (x) => x.field === updatedColumn.field,
            )
            if (columnDefinition) {
                columnDefinition.orderIndex = updatedColumn.orderIndex
            }
        })
        await saveAndRefetchGridLayouts(
            dispatch,
            activeGridLayoutCopy.id,
            JSON.stringify(activeGridLayoutCopy.configurationJson),
        )
    }
}

const setGridDataSet = (
    gridLayoutName: GridLayoutIdentifier,
    dataState: State,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const activeGridLayoutCopy = JSON.parse(
            JSON.stringify(getActiveGridLayout(getState().gridLayouts.items, gridLayoutName)),
        ) as GridLayout
        activeGridLayoutCopy.configurationJson.dataState = dataState
        await saveAndRefetchGridLayouts(
            dispatch,
            activeGridLayoutCopy.id,
            JSON.stringify(activeGridLayoutCopy.configurationJson),
        )
    }
}

const setGridFilteringEnabled = (
    gridLayoutName: GridLayoutIdentifier,
    filteringEnabled: boolean,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const activeGridLayoutCopy = JSON.parse(
            JSON.stringify(getActiveGridLayout(getState().gridLayouts.items, gridLayoutName)),
        ) as GridLayout
        activeGridLayoutCopy.configurationJson.filtersEnabled = filteringEnabled
        await saveAndRefetchGridLayouts(
            dispatch,
            activeGridLayoutCopy.id,
            JSON.stringify(activeGridLayoutCopy.configurationJson),
        )
    }
}

export default {
    getGridLayoutData,
    saveGridLayoutAs,
    updateGridLayout,
    setActiveGridLayout,
    setGridFilteringEnabled,
    updateGridFilters,
    updateGridColumnsOrder,
    setGridDataSet,
    deleteGridLayout,
}
