import { GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import React from 'react'
import GridDropDownCell from './GridCellComponents/GridDropDownCell'
import { SelectionState } from './KendoGridCustom'

interface CellRenderProps {
    originalProps: GridCellProps
    td: React.ReactElement<HTMLTableCellElement>
    enterEdit: (dataItem: any, field: string | undefined) => void
    editField: string | undefined
    invalidFields?: string[]
    invalidText?: string
}

interface RowRenderProps {
    originalProps: GridRowProps
    tr: React.ReactElement<HTMLTableRowElement>
    exitEdit: () => void
    editField: string | undefined
}

export const getItemEditedHandler = <T extends { id: string }>(
    items: T[],
    setItems: (items: T[]) => void,
    isInvalidEditInPlace?: (event: GridItemChangeEvent) => boolean,
) => {
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field || ''

        if (isInvalidEditInPlace?.(event)) {
            // editing for this particular cell is invalid, so don't accept the input
            return
        }

        const newList = items.map((item) => {
            if (item.id === event.dataItem.id) {
                return {
                    ...item,
                    [field]: event.value,
                }
            }
            return item
        })
        setItems(newList)
    }
    return itemEditedHandler
}

const enterEditHandler = <T extends { id: string }>(
    dataItem: T,
    items: T[],
    field: string | undefined,
    setItems: (items: T[]) => void,
    editField: string,
    setSelectedRowsState: (selectionState: SelectionState) => void,
) => {
    const newList = items.map((item) => ({
        ...item,
        [editField]: item.id === dataItem.id ? field : undefined,
    }))
    setItems(newList)
    setSelectedRowsState({ [dataItem.id]: true })
}

export const getEditableGridDropdownCellRenderer = <T extends { id: string }>(
    editField: string,
    items: T[],
    setItems: (items: T[]) => void,
    setSelectedRowsState: (state: SelectionState) => void,
) => {
    const enterHandler = (dataItem: T, field: string | undefined) => {
        return enterEditHandler(dataItem, items, field, setItems, editField, setSelectedRowsState)
    }

    // component constructor function
    const customCellRender = (gridCellProps: GridCellProps, type: { [key in string]: string }) => (
        <GridDropDownCell {...gridCellProps} type={type} enterEdit={enterHandler} editField={editField} />
    )
    return customCellRender
}

export const getCustomCellRenderer = <T extends { id: string }>(
    editField: string,
    items: T[],
    setItems: (items: T[]) => void,
    setSelectedRowsState: (state: SelectionState) => void,
    options?: { readonlyFields?: string[]; invalidFields?: string[]; invalidText?: string },
) => {
    const enterHandler = (dataItem: T, field: string | undefined) => {
        if (options?.readonlyFields?.includes(field || '')) {
            // not an editable fields, so don't initiate editing
            return null
        }
        return enterEditHandler(dataItem, items, field, setItems, editField, setSelectedRowsState)
    }
    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender
            originalProps={propsThe}
            td={td}
            enterEdit={enterHandler}
            editField={editField}
            invalidFields={options?.invalidFields || []}
            invalidText={options?.invalidText || ''}
        />
    )
    return customCellRender
}

const exitEditHandler = <T extends { id: string }>(
    items: T[],
    setItems: (items: T[]) => void,
    editField: string,
    numericColumns: string[],
) => {
    // convert to the appropriate type before allowing exit edit
    let anyFailedConversions = false
    const updatedItems = items.map((item) => {
        const updatedItem = { ...item, [editField]: undefined }
        numericColumns.forEach((col) => {
            const convertedValue = parseFloat(updatedItem[col] || '0')
            if (Number.isNaN(convertedValue)) {
                anyFailedConversions = true
            }
            ;(updatedItem as { [key: string]: any })[col] = convertedValue
        })
        return updatedItem
    })

    if (anyFailedConversions) {
        // don't allow exit edit (ie, don't set the 'editField' to undefined)
        return
    }

    setItems(updatedItems)
}

export const getCustomRowRenderer = <T extends { id: string }>(
    editField: string,
    items: T[],
    setItems: (items: T[]) => void,
    options?: { numericColumns?: string[] },
) => {
    const exitHandler = () => {
        return exitEditHandler(items, setItems, editField, options?.numericColumns || [])
    }
    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitHandler} editField={editField} />
    )

    return customRowRender
}

export const CellRender = (props: CellRenderProps) => {
    const dataItem = props.originalProps.dataItem
    const cellField = props.originalProps.field
    const inEditField = dataItem[props.editField || '']
    let additionalProps: any = {}
    if (cellField && cellField === inEditField) {
        additionalProps = {
            ref: (td: any) => {
                const input = td && td.querySelector('input')
                const activeElement = document.activeElement

                if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                    return
                }

                if (input.type === 'checkbox') {
                    input.focus()
                } else {
                    input.select()
                }
            },
        }
    } else {
        additionalProps = {
            onClick: (e: React.MouseEvent<HTMLElement>) => {
                const target = e.target as HTMLElement
                if (target.nodeName !== 'TD') {
                    // if another type, it must already be in edit mode
                    // possibly a dropdown list that the user is trying to open.
                    return
                }
                props.enterEdit(dataItem, cellField)
            },
        }
    }
    if (props.invalidFields?.includes(cellField || '')) {
        const styling: React.CSSProperties = {
            backgroundColor: '#DC3545',
        }
        additionalProps.style = styling
        additionalProps.title = props.invalidText || 'Invalid input'
    }

    const clonedProps: any = { ...props.td.props, ...additionalProps }
    return React.cloneElement(props.td, clonedProps, <>{props.td.props.children}</>)
}

export const RowRender = (props: RowRenderProps) => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            props.exitEdit()
        },
    }
    return React.cloneElement(props.tr, { ...trProps }, <>{props.tr.props.children}</>)
}
