import { ColorPicker, ColorPickerProps } from '@progress/kendo-react-inputs'
import React, { useState } from 'react'
import './KendoStyleOverrides.css'

/**
 * Customized Kendo React color picker.  Hides the default down-arrow, in favor of just clicking directly on the color (like in v5).  Reduced padding, margins, etc for a cleaner look.
 * @param props
 * @returns
 */
const ColorPickerCustom = (props: ColorPickerProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <span
            onBlur={(e: React.FocusEvent<HTMLElement>) => {
                const relatedTarget = e.relatedTarget as HTMLElement | undefined
                const parentMask = relatedTarget?.closest('.k-colorgradient')
                if (!parentMask) {
                    // they clicked anywhere except on the color picker, so close it
                    setIsOpen(false)
                }
            }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                const className = (e.target as HTMLElement).className
                if (className === 'k-color-preview-mask') {
                    // is the little color preview icon, so toggle the open state
                    setIsOpen(!isOpen)
                }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className={props.className}
            title="Select Color"
        >
            <ColorPicker open={isOpen} view="gradient" {...props} />
        </span>
    )
}

export default ColorPickerCustom
