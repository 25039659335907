import { sum } from 'lodash'
import { SleepQualityTypeNames } from 'types/EnumTypes'
import { SleepPeriods, SleepRules } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputNumericFormRow, InputSelectFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormRow from 'views/Common/Form/FormRows/FormRow'
import FormSection from 'views/Common/Form/FormSection'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'
import PWSSleepRuleDetailsPeriods from './PWSSleepRuleDetailsPeriods'
import SleepFormula from './SleepFormula.svg'

interface PWSSleepRulesGridProps {
    sleepRule: SleepRules
    onChange: (e: FormControlChangeEvent) => void
    onChangeSleepPeriods: (sleepPeriods: SleepPeriods[]) => void
    validated: boolean
}

export const isPwsSleepRuleValid = (rule: SleepRules) => {
    const commonFieldsAreValid =
        rule.name.length > 0 &&
        rule.sleepPeriods.length > 0 &&
        sum(rule.sleepPeriods.map((sp) => sp.percentOfSleep)) === 100 &&
        rule.durationS !== null &&
        rule.durationE !== null &&
        rule.durationS >= 0 &&
        rule.durationE >= 0

    if (!commonFieldsAreValid) {
        return false
    }

    if (rule.durationType === 'Fixed') {
        return rule.durationFixed > 0
    }

    // formula
    return rule.durationN > 0 && rule.durationP > 0
}

const PWSSleepRuleDetails = ({ sleepRule, validated, onChange, onChangeSleepPeriods }: PWSSleepRulesGridProps) => {
    return (
        <FormSection>
            <InputTextFormRow
                labelText="Name"
                fieldId="name"
                subText="A unique name to identify this rule"
                value={sleepRule.name}
                onChange={onChange}
                required
                validated={validated}
                invalidText="Enter a name"
            />
            <InputSelectFormRow
                labelText="Sleep Quality"
                fieldId="quality"
                subText="The quality of planned work sleep"
                value={sleepRule.quality}
                options={Object.values(SleepQualityTypeNames)}
                onChange={onChange}
            />
            <InputNumericFormRow
                labelText="Start Exclude (S)"
                fieldId="durationS"
                subText="The duration from the start of event to avoid sleep"
                value={sleepRule.durationS}
                minValue={0}
                onChange={onChange}
                required
                validated={validated}
                invalidText="Enter a value of 0 or greater"
            />
            <InputNumericFormRow
                labelText="End Exclude (E)"
                fieldId="durationE"
                subText="The duration from the end of event to avoid sleep"
                value={sleepRule.durationE}
                minValue={0}
                onChange={onChange}
                required
                validated={validated}
                invalidText="Enter a value of 0 or greater"
            />
            <InputSelectFormRow
                labelText="Duration Type"
                fieldId="durationType"
                subText="The quality of planned work sleep"
                value={sleepRule.durationType}
                options={[
                    { key: 'FormulaCDP', value: 'Formula' },
                    { key: 'Fixed', value: 'Fixed' },
                ]}
                onChange={onChange}
            />

            <IndentedBlock headingText="">
                {sleepRule.durationType === 'Fixed' && (
                    <InputNumericFormRow
                        labelText="Fixed Duration"
                        fieldId="durationFixed"
                        subText="The total duration of sleep on a work day"
                        value={sleepRule.durationFixed}
                        required
                        minValue={1}
                        onChange={onChange}
                        validated={validated}
                        invalidText="Enter a value greater than 0"
                    />
                )}
                {sleepRule.durationType === 'FormulaCDP' && (
                    <>
                        <InputNumericFormRow
                            labelText="Number of Sleep Opportunities (N)"
                            fieldId="durationN"
                            subText="How many sleep opportunities are required"
                            value={sleepRule.durationN}
                            minValue={1}
                            onChange={onChange}
                            required
                            validated={validated}
                            invalidText="Enter a value of 1 or greater"
                        />
                        <InputNumericFormRow
                            labelText="Proportion of Opportunity is Sleeping (P)"
                            fieldId="durationP"
                            subText="Proportion of opportunity sleeping"
                            value={sleepRule.durationP}
                            minValue={0.01}
                            onChange={onChange}
                            required
                            validated={validated}
                            invalidText="Enter a value greater than 0"
                        />

                        <FormRow
                            labelText="Work Duration (D)"
                            subText="The duration of the work event that triggered the rule"
                        />

                        <FormRow
                            labelText="Sleep Duration Formula"
                            subText="The formula to calculate the sleep duration"
                        >
                            <div className="d-flex justify-content-center">
                                {/* svg generated here: https://editor.codecogs.com/ */}
                                <img src={SleepFormula} alt="formula" />
                            </div>
                        </FormRow>
                    </>
                )}
            </IndentedBlock>
            <FormRow
                labelText="Sleep Periods"
                subText="Divides the event into separate periods, with a percent of total sleep duration applied to each period"
            >
                <PWSSleepRuleDetailsPeriods
                    sleepPeriods={sleepRule.sleepPeriods}
                    updateSleepPeriods={onChangeSleepPeriods}
                    validated={validated}
                />
            </FormRow>
        </FormSection>
    )
}

export default PWSSleepRuleDetails
