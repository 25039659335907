import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { BaseInputProps } from './FormControlBase'

export interface FormControlFileInputProps extends BaseInputProps {
    value?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    onClick?: React.MouseEventHandler<HTMLOrSVGElement>
    disabled?: boolean
    readOnly?: boolean
}

const FormControlFileInput = (props: FormControlFileInputProps) => {
    return (
        <>
            <Form.Control
                role="file"
                aria-label={props.id || ''}
                type="file"
                value={props.value?.trimStart()}
                onChange={props.onChange}
                onClick={props.onClick}
                id={props.id}
                name={props.id}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
                style={props.onClick ? { cursor: 'pointer' } : undefined}
            />
            {props.invalidText && <Form.Control.Feedback type="invalid">{props.invalidText}</Form.Control.Feedback>}
        </>
    )
}

export default FormControlFileInput
