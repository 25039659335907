import {
    BaseDeterminationOnImportTypeNames,
    getRecordKeyValues,
    KronosLocationFilterTypeNames,
    ScheduleTypeNames,
} from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    InputFileFormRow,
    InputNumericFormRow,
    InputSelectFormRow,
    InputTextFormRow,
    MultiColumnFormRow,
    MultiColumnFormRowProps,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'

interface DataSourceKronosProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceKronos = (props: DataSourceKronosProps) => {
    const filterLocationsMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Locations',
        subText: 'Include events with specific locations',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: props.profile.locationFilterType,
                    onChange: props.onChange,
                    id: 'locationFilterType',
                    options: getRecordKeyValues(KronosLocationFilterTypeNames),
                },
            },
            {
                colType: 'textInput',
                inputTextProps: {
                    value: props.profile.locationFilterList,
                    onChange: props.onChange,
                    id: 'locationFilterList',
                },
            },
        ],
    }

    return (
        <>
            <InputSelectFormRow
                labelText="Schedule Type"
                subText="The types of schedules to load into a scenario"
                value={props.profile.scheduleType}
                onChange={props.onChange}
                fieldId="scheduleType"
                options={getRecordKeyValues(ScheduleTypeNames)}
            />

            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <SwitchFormRow
                labelText="Combine Schedules With Operating Dates"
                subText="Schedules with identical events but operating on different dates will be combined"
                value={props.profile.combineSchedulesWithOperatingDates}
                onChange={props.onChange}
                fieldId="combineSchedulesWithOperatingDates"
            />

            <IndentedBlock
                headingText="Hub Turns"
                subheadingText="How consecutive duties should be combined into a single hub turn duty"
            >
                <InputNumericFormRow
                    labelText="Maximum Gap"
                    subText="Duties that occur within the defined duration will be combined into a single duty."
                    value={props.profile.hubTurnsMaximumGap}
                    onChange={props.onChange}
                    fieldId="hubTurnsMaximumGap"
                    minValue={0}
                    invalidText="Enter a value of 0 or greater"
                />

                <InputTextFormRow
                    labelText="Locations"
                    subText="The locations where hub turns will occur"
                    value={props.profile.hubTurnsLocations}
                    onChange={props.onChange}
                    fieldId="hubTurnsLocations"
                />
            </IndentedBlock>

            <IndentedBlock headingText="Filters" subheadingText="Which events to include in the schedules">
                {props.profile.locationFilterType !== 'IncludeAll' ? (
                    <MultiColumnFormRow {...filterLocationsMultiColumn} />
                ) : (
                    <InputSelectFormRow
                        labelText="Locations"
                        subText="Include events with specific locations"
                        value={props.profile.locationFilterType}
                        onChange={props.onChange}
                        fieldId="locationFilterType"
                        options={getRecordKeyValues(KronosLocationFilterTypeNames)}
                    />
                )}
            </IndentedBlock>

            <InputFileFormRow
                labelText="Kronos File"
                fieldId="dataFieldPath1"
                subText="Local path to a Kronos file (.rpt, .asc)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceKronos
