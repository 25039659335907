import { Switch } from '@progress/kendo-react-inputs'
import { ChangeEvent, FormEvent, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

export type SaveAsDialogProps = {
    itemName: string
    globalDefaultValue: boolean
    typeDescription: 'Layout' | 'Profile'
    saveAsCallback: (itemName: string, global: boolean) => void
    closeCallback: (status: DialogResultEnum) => void
}

const DialogContent = ({
    itemName: itemNameInitial,
    globalDefaultValue: globalDefaultInitial,
    typeDescription,
    saveAsCallback,
    closeCallback,
}: SaveAsDialogProps) => {
    const [itemName, setItemName] = useState(itemNameInitial)
    const [globalSwitchEnabled, setGlobalSwitchEnabled] = useState(globalDefaultInitial)

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
        saveAsCallback(itemName, globalSwitchEnabled)
        closeCallback(DialogResultEnum.Completed)
    }

    return (
        <>
            <Form noValidate onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Custom {typeDescription}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtItemName">Name</FormLabelCustom>
                                <Form.Control
                                    id="txtItemName"
                                    autoComplete="off"
                                    type="text"
                                    placeholder={`A unique name for this ${typeDescription}`}
                                    value={itemName}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setItemName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a {typeDescription} name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <div className="d-flex mt-3 justify-content-between">
                                    <FormLabelCustom>Global</FormLabelCustom>
                                    <Switch
                                        defaultChecked={globalSwitchEnabled}
                                        onChange={(e) => setGlobalSwitchEnabled(e.value)}
                                    />
                                </div>
                                <p>{`Save this ${typeDescription} so all Users can access`}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom isLarge variant="secondary" onClick={() => closeCallback(DialogResultEnum.Cancelled)}>
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const SaveAsDialog = (props: SaveAsDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <DialogContent {...props} />
        </ModalWrapper>
    )
}
export default SaveAsDialog
