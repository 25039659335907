import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Logout(props: { logoutHandler: () => void }) {
    const navigate = useNavigate()
    useEffect(() => {
        const logout = async () => {
            await props.logoutHandler()
            navigate('/', { replace: true })
        }
        logout()
    }, [props, navigate])

    return null
}

export default Logout
