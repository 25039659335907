import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import ModalWrapper, { ModalWrapperClassName } from 'views/Common/GenericDialogs/ModalWrapper'
import classes from './SimpleDialog.module.css'

const SimpleDialog = (props: {
    dialogClass?: ModalWrapperClassName
    /**
     * Is it an error dialog?  Changes the header color.
     */
    isError?: boolean
    /**
     * Text in the header of the dialog
     */
    headerText: string
    /**
     * Body of the dialog
     */
    children?: React.ReactNode
    /**
     * Callback when the dialog is closed
     */
    closeCallback: () => void
    /**
     * Optional override of footer buttons
     */
    getFooterButtons?: () => React.ReactNode

    noScroll?: boolean
}) => {
    const [hide, setHide] = useState(false)

    const closeHandler = () => {
        setHide(true)
        props.closeCallback()
    }

    /**
     * Controls the footer buttons to show
     * @returns
     */
    const getFooterButtons = (): React.ReactNode => {
        return (
            <ButtonCustom isLarge variant="secondary" onClick={closeHandler}>
                Close
            </ButtonCustom>
        )
    }

    const footerButtons = props.getFooterButtons ? props.getFooterButtons() : getFooterButtons()
    const overflowY = props.noScroll ? 'hidden' : 'auto'

    return (
        <ModalWrapper className={props.dialogClass} hide={hide} closeCallback={props.closeCallback}>
            <>
                <Modal.Header closeButton className={props.isError ? classes.error : ''}>
                    <Modal.Title>{props.headerText}</Modal.Title>
                </Modal.Header>
                {props.children && (
                    <Modal.Body key="dialogBody" style={{ overflowY }}>
                        {props.children}
                    </Modal.Body>
                )}
                <Modal.Footer>{footerButtons}</Modal.Footer>
            </>
        </ModalWrapper>
    )
}

export default SimpleDialog
