import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import useWindowSize from 'hooks/useWindowSize'
import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'

const DialogLayout = (props: {
    children: React.ReactNode
    headerContent: ReactElement | string
    footerContent: ReactNode
    onClose: () => void
    width?: number
    height?: number
    onHeightChange?: (newHeight: number) => void
    onWidthChange?: (newHeight: number) => void
    windowResizeDelayMs?: number
}) => {
    // use custom windowHeight hook to detect when the browser size changes
    // then send that back up to where the grid is configured
    const [windowWidth, windowHeight] = useWindowSize(props.windowResizeDelayMs)
    const getDialogWidth = useCallback(() => {
        let width = props.width ? props.width : undefined
        if (!width) {
            width = windowWidth > 1300 ? 1200 : windowWidth - 100
        }
        return width
    }, [props.width, windowWidth])
    const getDialogHeight = useCallback(() => {
        let height = props.height ? props.height : undefined
        if (!height) {
            height = windowHeight - 100
        }
        return height
    }, [props.height, windowHeight])
    const [dialogWidth, setDialogWidth] = useState(getDialogWidth())
    const [dialogHeight, setDialogHeight] = useState(getDialogHeight())
    const [contentWidth, setContentWidth] = useState(dialogWidth - 100)
    const [contentHeight, setContentHeight] = useState(dialogHeight - 200)

    useEffect(() => {
        setDialogWidth(getDialogWidth())
        setDialogHeight(getDialogHeight())

        setContentWidth(dialogWidth - 100)
        setContentHeight(dialogHeight - 200)

        // height resize callback
        if (props.onHeightChange) {
            props.onHeightChange(contentHeight)
        }

        // width resize callback
        if (props.onWidthChange) {
            props.onWidthChange(contentWidth)
        }
    }, [
        props,
        windowHeight,
        windowWidth,
        dialogWidth,
        dialogHeight,
        contentWidth,
        contentHeight,
        getDialogWidth,
        getDialogHeight,
    ])

    return (
        <>
            <Dialog title={props.headerContent} onClose={props.onClose} width={dialogWidth} height={dialogHeight}>
                <>{props.children}</>
                <DialogActionsBar>{props.footerContent}</DialogActionsBar>
            </Dialog>
        </>
    )
}

export default DialogLayout
