import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DialogPosition {
    x: number
    y: number
}

// note: not all dialogs are controlled with this state.
export type DisplayedDialog =
    | 'none'
    | 'profile-autosleep-pws-sleeprules-grid'
    | 'profile-autosleep-pws-sleeprules-details'
    | 'profile-autosleep-pws-eventrules-grid'
    | 'profile-autosleep-pws-eventrules-details'

interface DialogState {
    displayedDialog: DisplayedDialog
    position: DialogPosition
    checkboxesScrollPosition: number
    gridLayoutDialogSearchText: string
}

const initialState: DialogState = {
    position: { x: 0, y: 0 },
    checkboxesScrollPosition: 0,
    gridLayoutDialogSearchText: '',
    displayedDialog: 'none',
}

const dialogReducer = createSlice({
    name: 'modalDialogs',
    initialState,
    reducers: {
        setDisplayedDialog(state, action: PayloadAction<DisplayedDialog>) {
            state.displayedDialog = action.payload
        },

        setPosition(state, action: PayloadAction<DialogPosition>) {
            state.position = action.payload
        },

        setScrollPosition(state, action: PayloadAction<number>) {
            state.checkboxesScrollPosition = action.payload
        },

        setGridLayoutSearchText(state, action: PayloadAction<string>) {
            state.gridLayoutDialogSearchText = action.payload
        },

        reset(state) {
            state.position = initialState.position
            state.checkboxesScrollPosition = initialState.checkboxesScrollPosition
            state.gridLayoutDialogSearchText = initialState.gridLayoutDialogSearchText
        },
    },
})

export default dialogReducer.reducer
export const dialogActions = dialogReducer.actions
