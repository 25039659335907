import useGridLayout from 'hooks/useGridLayout'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import globals from 'services/global/globals'
import { toast } from 'react-toastify'
import { getColumnsFromGridLayout } from 'services/utilities/kendoGridColumnsHelpers'
import { handleApiError } from 'services/utilities/toastrUtils'
import TemplateDetail from 'types/TemplateDetail'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import IconButton from 'views/Common/Buttons/IconButton'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import PageLayout from 'views/Common/Layout/PageLayout'
import DeleteConfirmationDialog from '../Components/DeleteConfirmationDialog'
import GridToolbarButton from '../Components/GridToolbarButton'

const TemplateList = () => {
    const navigate = useNavigate()
    const [configHeight, setConfigHeight] = useState(780)
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const [showDialog, setShowDialog] = useState(<></>)
    const [gridData, setGridData] = useState<TemplateDetail[]>([])
    const [activeGridLayout] = useGridLayout('templates')
    const templateApi = useCallback(() => globals.getApi().getTemplateApi(), [])
    const baseUrl = '/settings/templates'
    const gridColumns = useMemo(() => getColumnsFromGridLayout({ gridLayout: activeGridLayout }), [activeGridLayout])

    useEffect(() => {
        const getDataAndSetLinks = async () => {
            try {
                const data = await templateApi().getTemplateList()
                setGridData(data)
            } catch (err: any) {
                handleApiError(err)
            }
        }
        getDataAndSetLinks()
    }, [templateApi])

    const handleAddClick = () => {
        navigate(`${baseUrl}/new`)
    }
    const handleCopyClick = () => {
        navigate(`${baseUrl}/${selectedIds[0]}/copy`)
    }

    const refresh = async () => {
        const data = await templateApi().getTemplateList()
        setGridData(data)
        setSelectedRowsState({})
    }
    const deleteData = async () => {
        try {
            await templateApi().deleteTemplate(selectedIds)
        } catch (err: any) {
            toast.error(err)
        }
        await refresh()
    }
    const handleDeleteClick = () => {
        setShowDialog(
            <DeleteConfirmationDialog
                topic="Template"
                numRows={selectedIds.length}
                onCloseDialog={(dialogResult: DialogResultEnum) => {
                    if (dialogResult === DialogResultEnum.Completed) {
                        deleteData()
                    }
                    setShowDialog(<></>)
                }}
            />,
        )
    }
    const handleSetDefaultClick = async () => {
        if (selectedIds.length === 1) {
            const selectedTemplate = gridData.find((i) => i.id === selectedIds[0])
            if (selectedTemplate && !selectedTemplate.isDefault) {
                selectedTemplate.isDefault = true
                await templateApi().updateTemplate(selectedTemplate)
                toast.success(selectedTemplate.name + ' is now default template')
                await refresh()
            }
        }
    }
    const selectedIds = getSelectedIds<string>(selectedRowsState)
    return (
        <>
            <PageLayout
                headingContent="Templates"
                buttons={
                    <>
                        <IconButton
                            tooltip="Set Default"
                            aria-label="set-default-template"
                            toolbarLeftMargin
                            icon="bi-check-square-fill"
                            disabled={selectedIds.length !== 1}
                            onClick={handleSetDefaultClick}
                        />
                        <GridToolbarButton
                            items={gridData}
                            selectedIds={selectedIds}
                            onAddClick={handleAddClick}
                            onCopyClick={handleCopyClick}
                            onDeleteClick={handleDeleteClick}
                        />
                    </>
                }
                onMainContentHeightChange={setConfigHeight}
            >
                <KendoGridCustom
                    gridLayoutName="templates"
                    height={`${configHeight}px`}
                    data={gridData}
                    columns={gridColumns}
                    setColumnVisibility={(newColumnState: KendoGridColumn[]) => newColumnState}
                    selectedRowsState={selectedRowsState}
                    onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                />
            </PageLayout>
            {showDialog}
        </>
    )
}

export default TemplateList
