/**
    Custom override of axios where we can take override requests that are intended for SFC (desktop) and route to the window.safteFastConsole object.
 */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import SessionControl from './SessionControl'

type AxiosMethodType = 'get' | 'post' | 'put' | 'delete'

class Axios {
    private instance = axios.create()
    private publicApiCalls = ['/Api/Users/PasswordReset']

    constructor(private sessionControl: SessionControl) {}

    getAxiosErrorMessage = (error: AxiosError): string => {
        let msg = ''
        if (error.response) {
            if (error.response.data) {
                if (typeof error.response.data === 'string') {
                    msg = error.response.data
                } else {
                    msg = JSON.stringify(error.response.data)
                }
            } else {
                msg = error.response.statusText
            }
        } else if (error.message) {
            msg = error.message
        } else {
            msg = error.toString()
        }
        return msg
    }

    handleCustomErrors = (error: AxiosError) => {
        // these custom status codes are coded in the API
        if (error.response?.status === 480) {
            this.sessionControl.setSessionExpired(error.message)
        }

        if (error.response?.status === 482) {
            this.sessionControl.setPasswordExpired()
        }
    }

    axiosRequest = async (
        method: AxiosMethodType,
        url: string,
        requestConfig?: AxiosRequestConfig,
        requestData?: any,
    ): Promise<AxiosResponse<any>> => {
        try {
            switch (method) {
                case 'get':
                    return await this.instance.get(url, requestConfig)
                case 'post':
                    return await this.instance.post(url, requestData, requestConfig)
                case 'put':
                    return await this.instance.put(url, requestData, requestConfig)
                case 'delete':
                    return await this.instance.delete(url, requestConfig)
                default:
                    throw new Error(`HTTP method ${method} is not implemented for SFC`)
            }
        } catch (e: any) {
            const error: AxiosError = e
            const isPublicApiCall = this.publicApiCalls.find((publicUrl) => url.includes(publicUrl))
            if (!isPublicApiCall) {
                this.handleCustomErrors(error)
            }
            throw Error(this.getAxiosErrorMessage(error))
        }
    }

    get = async (url: string, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
        this.axiosRequest('get', url, requestConfig)

    put = async (url: string, requestData?: any, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
        this.axiosRequest('put', url, requestConfig, requestData)

    post = async (url: string, requestData?: any, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
        this.axiosRequest('post', url, requestConfig, requestData)

    delete = async (
        url: string,
        requestData?: any,
        requestConfig?: AxiosRequestConfig,
    ): Promise<AxiosResponse<any>> => {
        let appliedRequestConfig = requestConfig
        if (requestData) {
            if (!appliedRequestConfig) {
                appliedRequestConfig = {}
            }
            appliedRequestConfig.data = requestData
        }
        return this.axiosRequest('delete', url, appliedRequestConfig)
    }

    getSessionControl = (): SessionControl => {
        return this.sessionControl
    }
}

export default Axios
