import tinycolor from 'tinycolor2'
import { DetailData, DetailDataTime, DetailDataWithPosition } from 'types/DetailItem'
import { Palette, RightAxisType } from 'types/interfaces'
import {
    DataKey,
    EffectivenessLineClass,
    EffectivenessLineThicknessClass,
    GraphBackgroundColor,
    GraphBackgroundRectangleOptions,
    GraphColors,
    XScale,
    YScale,
} from './EffectivenessGraphTypes'

export const rightAxesPhaseSeries = {
    scale: [-12, 12],
    tickValues: [-12, -9, -6, -3, 0, 3, 6, 9, 12],
}

export const rightAxes = [
    {
        type: 'SleepReservoir' as RightAxisType,
        scale: [50, 100],
        label: 'Sleep Reservoir',
    },
    {
        type: 'CircadianPhase' as RightAxisType,
        scale: rightAxesPhaseSeries.scale,
        label: 'Circadian Phase',
        tickValues: rightAxesPhaseSeries.tickValues,
    },
    {
        type: 'GoalPhase' as RightAxisType,
        scale: rightAxesPhaseSeries.scale,
        label: 'Goal Phase',
        tickValues: rightAxesPhaseSeries.tickValues,
    },
    {
        type: 'OutOfPhase' as RightAxisType,
        scale: rightAxesPhaseSeries.scale,
        label: 'Out Of Phase',
        tickValues: rightAxesPhaseSeries.tickValues,
    },
    {
        type: 'SleepIntensity' as RightAxisType,
        scale: [0, 100],
        label: 'Sleep Intensity',
    },
    {
        type: 'LapseIndex' as RightAxisType,
        scale: [0, 10],
        label: 'Lapse Index',
    },
    {
        type: 'Workload' as RightAxisType,
        scale: [-15, 100],
        label: 'Workload',
    },
    {
        type: 'BAC' as RightAxisType,
        label: 'Blood Alcohol Concentration',
    },
    {
        type: 'Blood Alcohol Concentration' as RightAxisType,
        label: 'Standard Deviation',
    },
]

const DefaultThresholds: Palette[] = [
    { threshold: 90, color: '#D3FABB' },
    { threshold: 77, color: '#FFFBC1' },
    { threshold: 65, color: '#FFDA96' },
    { threshold: 0, color: '#F2BFBE' },
]

/**
 * This takes in Effectiveness thresholds/colors to set the editor color bands.
 */
const getGraphEffectivenessBackgroundColors = (thresholds: Palette[]): GraphColors => {
    // some defaults in case this is a null schedule, for displaying an empty graph

    const thresholdsToUse = thresholds.length ? thresholds : DefaultThresholds

    // threshold must be in descending order, so validate now.
    let previous = thresholdsToUse[0].threshold
    for (let i = 1; i < thresholdsToUse.length - 1; i++) {
        const current = thresholdsToUse[i].threshold
        if (current > previous) {
            throw Error('Thresholds are out of order!')
        }
        previous = current
    }

    const direction = {
        high: 'decrease', // Gradient becomes "less green" as effectiveness goes down
        medium: 'increase',
        mediumLow: 'increase',
        low: 'increase', // Gradient becomes "more red" as effectiveness goes down
    }

    interface LevelKey {
        name: keyof typeof direction
    }

    const levels: LevelKey[] = [{ name: 'high' }, { name: 'medium' }, { name: 'mediumLow' }, { name: 'low' }]

    const lightenAmounts = {
        daylight: [11, 5],
        twilight: [8, 2],
        dark: [6, 0],
    }

    interface EffectsKey {
        name: keyof typeof lightenAmounts
    }

    const periods: EffectsKey[] = [{ name: 'daylight' }, { name: 'twilight' }, { name: 'dark' }]

    let thresholdsRgb: Palette[] = []
    // desired format is [ { color: '#foobar', threshold: 0 }, { color: '#....', threshold: 30 }, etc ]
    if (thresholdsToUse[0].color) {
        // thresholds format is already correct, so just copy into our working array
        thresholdsRgb = thresholdsToUse.slice()
    } else {
        // thresholds format is SFWeb, and needs to be massaged
        thresholdsRgb = thresholdsToUse.map((item) => ({
            color: '#' + tinycolor(item.color).toHex(),
            threshold: item.threshold,
        }))
    }

    if (thresholdsRgb.length < 4) {
        throw Error(
            `Unsupported effectiveness palette configuration, ${thresholdsRgb.join(
                ',',
            )}.  4 or more levels are required.`,
        )
    }

    thresholdsRgb.sort((o1, o2) => o2.threshold - o1.threshold)

    const graphColors: GraphColors = {
        high: thresholdsRgb[0].color,
        medium: thresholdsRgb[1].color,
        mediumLow: thresholdsRgb[2].color,
        low: thresholdsRgb[3].color,
        graphBackground: [],
    }

    periods.forEach((period) => {
        const lighteningAmount = lightenAmounts[period.name]

        const backgroundColor: GraphBackgroundColor = {
            name: period.name,
            levels: [],
        }

        levels.forEach((levelObj) => {
            let gradientStart
            let gradientEnd
            if (direction[levelObj.name] === 'increase') {
                gradientStart = tinycolor(graphColors[levelObj.name]).lighten(lighteningAmount[0]).toString()
                gradientEnd = tinycolor(graphColors[levelObj.name]).lighten(lighteningAmount[1]).toString()
            } else {
                gradientStart = tinycolor(graphColors[levelObj.name]).lighten(lighteningAmount[1]).toString()
                gradientEnd = tinycolor(graphColors[levelObj.name]).lighten(lighteningAmount[0]).toString()
            }

            backgroundColor.levels.push({
                name: levelObj.name,
                gradientEndColor: gradientEnd,
                gradientStartColor: gradientStart,
            })
        })
        graphColors.graphBackground.push(backgroundColor)
    })

    return graphColors
}

const createGraphBackgroundColorConfigurations = (
    yScale: YScale,
    palette: Palette[],
): GraphBackgroundRectangleOptions[] => {
    function yScaleDiff(a: number, b: number) {
        return yScale(a) - yScale(b)
    }

    const paletteToUse = palette.length ? palette : DefaultThresholds

    return [
        {
            level: 'high',
            yFunction() {
                return 0
            },
            heightFunction: yScale.bind(this, paletteToUse[0].threshold),
        },
        {
            level: 'medium',
            yFunction: yScale.bind(this, paletteToUse[0].threshold),
            heightFunction: yScaleDiff.bind(this, paletteToUse[1].threshold, paletteToUse[0].threshold),
        },
        {
            level: 'mediumLow',
            yFunction: yScale.bind(this, paletteToUse[1].threshold),
            heightFunction: yScaleDiff.bind(this, paletteToUse[2].threshold, paletteToUse[1].threshold),
        },
        {
            level: 'low',
            yFunction: yScale.bind(this, paletteToUse[2].threshold),
            heightFunction: yScaleDiff.bind(this, 0, paletteToUse[2].threshold),
        },
    ]
}

// Controls how far is the graph from the left side of the window
export const GraphX = 60

/**
 * Measure how far from the left edge of the screen the graph is positioned.  This takes into account padding/margins
 * that `GraphX` does not.
 */
export const getGraphXOffset = () => (document.querySelector('.js-graph-rect') as HTMLElement).getBoundingClientRect().x

/**
 * graphY dictates how tall the date section at the top is, and it indicates the top level of the graph below that
 * date section; it also impacts some other magic numbers around here.
 */
export const GraphY = 18

// This is related to graphY which determines how much height is available.
export const KeyAreaMagicVerticalOffset = -20

export const DataKeys: DataKey[] = [
    {
        type: 'crewing',
        label: 'Work',
        position: 0,
        hasSeparateRow: true,
        isEditable: true,
    },
    {
        type: 'notCrewing',
        label: '',
        position: 0,
        hasSeparateRow: false,
        isEditable: true,
    },
    {
        type: 'marker',
        label: 'Marker',
        position: 2,
        hasSeparateRow: true,
        isEditable: true,
    },
    {
        type: 'criticalMarker',
        label: '',
        position: 2,
        hasSeparateRow: false,
        isEditable: true,
    },
    {
        type: 'autoMarker',
        label: '',
        position: 2,
        hasSeparateRow: false,
        isEditable: false,
    },
    {
        type: 'criticalAutoMarker',
        label: '',
        position: 2,
        hasSeparateRow: false,
        isEditable: false,
    },
    {
        type: 'sleep',
        label: 'Sleep',
        position: 1,
        hasSeparateRow: true,
        isEditable: false,
    },
    {
        type: 'explicitSleep',
        label: '',
        position: 1,
        hasSeparateRow: false,
        isEditable: false,
    },
]
// dimensions selected to allow space for pinned dashboard on the right.
export const PinnedDashboardWidth = 270

export const PrintDimensions = {
    width: 1050,
    height: 800,
}
export const PrintDimensionsPinned = {
    width: 750,
    height: 800,
}

export const getZoomBarHeight = (isBothMode: boolean) => (isBothMode ? 30 : 40)

export const getSvgContainerHeight = (
    isPrintPreview: boolean,
    isBothMode: boolean,
    hasPinnedDashboard: boolean,
    graphContainer: HTMLDivElement,
    overallGraphHeight: number,
) => {
    if (isPrintPreview) {
        return hasPinnedDashboard ? PrintDimensionsPinned.height : PrintDimensions.height
    }

    if (isBothMode) {
        // fixed height for both mode
        return 250
    }

    const minGraphViewHeight = 300
    const calculatedHeight = overallGraphHeight - getZoomBarHeight(isBothMode)
    return Math.max(minGraphViewHeight, calculatedHeight)
}
export const XAxisLabelHeight = 30

export const getKeyItemHeight = (isBothView: boolean) => (isBothView ? 20 : 40)
export const getKeyHeight = (isBothView: boolean) => {
    const keyItemHeight = isBothView ? 20 : 40
    const keyHeight = keyItemHeight * DataKeys.filter((x) => x.hasSeparateRow).length
    return keyHeight
}

export const getPlotAreaHeight = (
    isPrintPreview: boolean,
    isBothMode: boolean,
    hasPinnedDashboard: boolean,
    graphContainer: HTMLDivElement,
    overallGraphHeight: number,
) => {
    return (
        getSvgContainerHeight(isPrintPreview, isBothMode, hasPinnedDashboard, graphContainer, overallGraphHeight) -
        getKeyHeight(isBothMode) -
        XAxisLabelHeight -
        getZoomBarHeight(isBothMode)
    )
}

export interface FilteredLineSection {
    lineType: EffectivenessLineClass
    lineThicknessOptional?: EffectivenessLineThicknessClass
    filter: (d: DetailData) => boolean
}

/**
 * Get an array of dates where the UTC offset has changed from the previous minute in the schedule
 * but the location has not changed.  We assume that this means it is a switch to or from DST.
 * Typcially this will be an array of 0 or 1.  Not likely to have more than 1 DST change in a single schedule.
 */
export const findDstChangeTimes = (detailData: DetailDataTime[]): Date[] => {
    let previousDataPoint: DetailDataTime | null = null
    return detailData.reduce((sum: Date[], cur: DetailDataTime) => {
        if (!previousDataPoint) {
            // we must be on the first data point, so we can't assume this is a dst change
            previousDataPoint = cur
            return sum
        }

        if (previousDataPoint.locationName === cur.locationName && previousDataPoint.tz !== cur.tz) {
            sum.push(cur.utcTime)
        }

        previousDataPoint = cur
        return sum
    }, [])
}

/**
 * Map the sleepData (from the server) into a screen pixel indexed array.  This typically will result in a loss of data,
 * but we preserve "boundary minutes", which are considered the most important.
 */
export const getDashboardScaledDetailData = (sleepData: DetailData[], xScale: XScale) => {
    const dataArray: DetailDataWithPosition[] = []
    let currentPixelPosition = 0

    // const currentSavedTime =
    // app.dashboardInspectorDatePosition !== 0 ? app.dashboardInspectorDatePosition.getTime() : null
    const currentSavedTime = null

    // Create data lookup of activity based on pixel location in graph.
    // All pixel values in range must be mapped

    sleepData.forEach((dataItem) => {
        let keepCurrentData = false
        if (currentSavedTime != null && dataItem.utcTime.getTime() === currentSavedTime) {
            keepCurrentData = true
        }
        // get the pixel position for this minute.  Multiple minutes need to be squashed into the same pixel position.
        const thisDatumPixelPosition = Math.round(xScale(dataItem.utcTime.getTime()))
        // dataItem.pixelPos = thisDatumPixelPosition
        // take the detail data item and add some extra fields needed
        const dataObj: DetailDataWithPosition = { ...dataItem, pixelPos: thisDatumPixelPosition }
        //     {
        //         activity: dataItem.crewing === true ? 'Crewing' : dataItem.activity,
        //         labels: this.getDetailItemLabelsString(dataItem),
        //     },
        //     dataItem,
        // )
        // if anything saved in fdashboard inspector or coming in or out of print preview with saved dashboard time make to keep this data SFC-2427

        if (keepCurrentData) {
            dataArray[currentPixelPosition] = dataObj
            currentPixelPosition++
            // return false
            return
        }
        if (currentPixelPosition <= thisDatumPixelPosition) {
            if (dataItem.isNewActivityBoundary === true) {
                if (!dataArray[currentPixelPosition]) {
                    // this is a boudary minute, and nothing is in the current pixel position,
                    // so use this minute data.
                    dataArray[currentPixelPosition] = dataObj
                    currentPixelPosition++
                } else if (!dataArray[currentPixelPosition].isNewActivityBoundary === true) {
                    // the current pixel position has already been filled, but this is a boundary point, so use it instead
                    // unless the previous one was also a boundary point.  Then leave the one already there (assumption!)
                    dataArray[currentPixelPosition] = dataObj
                }
            } else {
                while (currentPixelPosition < thisDatumPixelPosition) {
                    dataArray[currentPixelPosition] = dataObj
                    currentPixelPosition += 1
                }
            }
        }
    })
    return dataArray
}

export { getGraphEffectivenessBackgroundColors, createGraphBackgroundColorConfigurations }
