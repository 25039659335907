const IndentedBlock = (props: { headingText: string; subheadingText?: string; children: React.ReactNode }) => {
    return (
        <div style={{ marginBottom: 10 }}>
            <h6 style={{ fontWeight: '500' }}>{props.headingText}</h6>
            {props.subheadingText && <p>{props.subheadingText}</p>}
            <div style={{ marginLeft: 40, marginTop: 20 }}>{props.children}</div>
        </div>
    )
}

export default IndentedBlock
