import { ChangeEvent, FormEvent, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { globalActions } from 'store/globalStore'
import { RootState } from 'store/store'
import { StationEditable } from 'types/interfaces'
import MetaData from 'types/Metadata'
import { OperationMode } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputSelectFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormRow from 'views/Common/Form/FormRows/FormRow'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'

interface StationDetailsProps {
    setEditStation: (station: StationEditable | undefined) => void
    onCloseComponent: (result: DialogResultEnum) => void
    station: StationEditable | undefined
    operationMode: OperationMode
}

interface ErrDataStruct {
    path: string
    type: string
    message: string
}
interface ErrorResponseStruct {
    errorCode: string
    description: string
    data: ErrDataStruct[]
}
const StationDetails = (props: StationDetailsProps) => {
    const api = globals.getApi()
    const [validatedForm, setValidatedForm] = useState(false)
    const { setEditStation, onCloseComponent } = props
    const [station, setStation] = useState(props.station)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const [countryCodes] = useState(metadata.countryCodesList.slice().sort())
    const [regionCodes] = useState(metadata.regionsCodesList.slice().sort())

    const dispatch = useDispatch()

    const onCancelClick = () => {
        setEditStation(undefined)
        onCloseComponent(DialogResultEnum.Cancelled)
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        // Custom validations

        if (station?.codeIATA === '' && station?.codeICAO === '') {
            invalid = true
        }

        if (invalid) {
            return
        }

        let errFlag = false

        dispatch(globalActions.showLoadingModal())
        setTimeout(async () => {
            try {
                if (station !== undefined) {
                    if (props.operationMode === 'Create' || props.operationMode === 'Copy') {
                        await api.getStationsApi().addStation(station)
                    } else {
                        await api.getStationsApi().editStation(station)
                    }
                }
            } catch (err: any) {
                // old error display
                // handleApiError(err)

                // new error display
                errFlag = true

                // Backend error/validation handling. Keeping it here for now as we only have the new error/validation structure set up
                // for stations. Once we expand it to other components, we can move it to handleApiError.
                const errObj = JSON.parse(err.message) as ErrorResponseStruct

                const list = errObj.data.map((msg: ErrDataStruct) => {
                    return <li key={msg.type}>{msg.message}</li>
                })
                const errMessageFormatted = (
                    <>
                        <h5>{errObj.description}</h5>
                        <br />
                        <ul>{list}</ul>
                    </>
                )
                toast.error(errMessageFormatted)
            } finally {
                dispatch(globalActions.hideLoadingModal())

                if (!errFlag) {
                    props.onCloseComponent(DialogResultEnum.Completed)
                }
            }
        }, 0)
    }

    const title = `${props.operationMode} Station`
    const footer = <FormFooter disabledSave={false} onCancel={() => onCancelClick()} />

    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            <FormSection title="">
                <InputTextFormRow
                    labelText="IATA"
                    subText="Official IATA code for this Station"
                    invalidText="Enter an IATA code and/or ICAO Code"
                    value={station?.codeIATA}
                    fieldId="codeIATA"
                    validated={validatedForm}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setStation((prev) => ({ ...prev!, codeIATA: e.target.value }))
                    }
                    onValidation={() => {
                        return station?.codeIATA !== '' || station?.codeICAO !== ''
                    }}
                />

                <InputTextFormRow
                    labelText="ICAO"
                    subText="Official ICAO code for this Station"
                    invalidText="Enter an IATA code and/or ICAO Code"
                    value={station?.codeICAO}
                    fieldId="codeICAO"
                    validated={validatedForm}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setStation((prev) => ({ ...prev!, codeICAO: e.target.value }))
                    }
                    onValidation={() => {
                        return station?.codeIATA !== '' || station?.codeICAO !== ''
                    }}
                />

                <InputTextFormRow
                    labelText="Aliases"
                    subText="Codes that this Station may be known as"
                    fieldId="codeAliases"
                    value={station?.codeAliases}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setStation((prev) => ({ ...prev!, codeAliases: e.target.value }))
                    }
                />

                <InputTextFormRow
                    labelText="Name"
                    subText="Descriptive Name for this station"
                    invalidText="Enter a Name for this Station"
                    value={station?.cityName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setStation((prev) => ({ ...prev!, cityName: e.target.value }))
                    }
                    required
                />

                <InputSelectFormRow
                    labelText="Country"
                    subText="Official country code for this Station"
                    invalidText="Select a Country Code"
                    value={station?.codeCountry}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setStation((prev) => ({ ...prev!, codeCountry: e.target.value }))
                    }
                    options={countryCodes}
                />
                <InputSelectFormRow
                    labelText="Region"
                    subText="Official region code for this Station"
                    invalidText="Select a Region Code"
                    value={station?.codeRegion}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setStation((prev) => ({ ...prev!, codeRegion: e.target.value }))
                    }
                    options={regionCodes}
                />

                <FormRow labelText="Location" subText="Latitude and Longitude">
                    <Row>
                        <Col className="mt-3">
                            <Form.Group>
                                <Form.Control
                                    type="number"
                                    min={-90}
                                    max={90}
                                    step="any"
                                    value={station?.latitude}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setStation((prev) => ({
                                            ...prev!,
                                            latitude: parseFloat(e.target.value),
                                        }))
                                    }
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Enter a Latitude (-90 to 90)
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="mt-3">
                            <Form.Group>
                                <Form.Control
                                    type="number"
                                    min={-180}
                                    max={180}
                                    step="any"
                                    value={station?.longitude}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setStation((prev) => ({
                                            ...prev!,
                                            longitude: parseFloat(e.target.value),
                                        }))
                                    }
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Enter a Longitude (-180 to 180)
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </FormRow>
            </FormSection>
        </FormPage>
    )
}

export default StationDetails
