import ButtonCustom from '../Buttons/ButtonCustom'
import DialogLayout from '../Layout/DialogLayout'

interface InformationDialogProps {
    headerText?: string
    message: string | JSX.Element | null
    onClose?: () => void
}
const InformationDialog = (props: InformationDialogProps) => (
    <DialogLayout
        width={500}
        height={200}
        headerContent={props.headerText ?? 'Information'}
        footerContent={
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '1200px',
                    margin: 'auto',
                    paddingRight: '20px',
                }}
            >
                <ButtonCustom isLarge type="submit" variant="primary" onClick={() => props.onClose?.()}>
                    Close
                </ButtonCustom>
            </div>
        }
        onClose={() => props.onClose?.()}
    >
        <>{props.message}</>
    </DialogLayout>
)

export default InformationDialog
