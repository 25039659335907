import { parseStation, Station, StationEditable } from 'types/interfaces'
import { PagedSearchRequest } from 'types/PagedSearchRequest'
import Axios from '../axios/axios-sfc'
import SFApiBase from './sfApiBase'

class SFStationsApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    getStationMatches = async (searchString: string): Promise<Station[]> => {
        const data = (
            await this.axios.get(
                this.baseUrl + `/Api/Stations/GetStationsByPartialTextMatch?term=${encodeURIComponent(searchString)}`,
                this.getConfig(),
            )
        ).data as Array<any>
        return data.map(parseStation)
    }

    getAllStations = async (startIndex: number): Promise<Array<any>> => {
        let totalCount = 0
        const data = (
            await this.axios
                .get(this.baseUrl + `/Api/Stations/GetAllStations/${startIndex}`, this.getConfig())
                .then((response) => {
                    totalCount = response.data.totalCount
                    return response
                })
        ).data.res as Array<any>
        const parsedResults = data.map(parseStation)
        return [parsedResults, totalCount]
    }

    getUtcOffsetAtTime = async (stationCode: string, timeUtc: Date): Promise<number> => {
        const offset = (
            await this.axios.get(
                this.baseUrl +
                    `/Api/Stations/GetUtcOffsetMinutes?stationCode=${encodeURIComponent(
                        stationCode,
                    )}&time=${timeUtc.getTime()}`,
                this.getConfig(),
            )
        ).data
        return offset as number
    }
    addStation = async (station: StationEditable): Promise<string> => {
        const response = (await this.axios.post(this.baseUrl + '/Api/Stations/AddStation', station, this.getConfig()))
            .data as string
        return response
    }
    editStation = async (station: StationEditable): Promise<string> => {
        const response = (await this.axios.put(this.baseUrl + '/Api/Stations/EditStation', station, this.getConfig()))
            .data as string
        return response
    }
    deleteStations = async (stationIds: Array<number>): Promise<string> => {
        const response = (
            await this.axios.delete(this.baseUrl + '/Api/Stations/DeleteStations', stationIds, this.getConfig())
        ).data as string
        return response
    }
    search = async (request: PagedSearchRequest): Promise<Array<any>> => {
        let totalCount = 0
        const data = (
            await this.axios
                .get(this.baseUrl + '/Api/Stations/Search', { ...this.getConfig(), params: request })
                .then((response) => {
                    totalCount = response.data.totalCount
                    return response
                })
        ).data.items as Array<any>
        const parsedResults = data.map(parseStation)
        return [parsedResults, totalCount]
    }
}

export default SFStationsApi
