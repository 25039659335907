import { isNumber } from 'lodash'
import { ChangeEvent, useState } from 'react'
import { Form } from 'react-bootstrap'
import { BaseInputProps, buildEventNumeric, FormControlChangeEvent } from './FormControlBase'

export interface FormControlNumericInputProps extends BaseInputProps {
    value?: number
    onChange: (e: FormControlChangeEvent) => void
    min?: number
    max?: number
    step?: number
    validated?: boolean
    style?: React.CSSProperties
}

const FormControlNumericInput = (props: FormControlNumericInputProps) => {
    const [hasBeenTouched, setHasBeenTouched] = useState(false)
    const min = props.min ? props.min : 0

    const isInvalid =
        props.required &&
        (Number.isNaN(props.value) ||
            !isNumber(props.value) ||
            props.value < min ||
            (props.max !== undefined && props.value > props.max))

    return (
        <>
            <Form.Control
                style={props.style}
                role="spinbutton"
                aria-label={props.id}
                type="number"
                value={props.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.onChange(buildEventNumeric(e))
                }}
                onBlur={() => setHasBeenTouched(true)}
                id={props.id}
                name={props.id}
                min={min}
                {...(props.step && { step: props.step })}
                {...(props.max && { max: props.max })}
                required={props.required}
                isInvalid={props.required && (hasBeenTouched || props.validated) && isInvalid}
            />
            {props.invalidText && <Form.Control.Feedback type="invalid">{props.invalidText}</Form.Control.Feedback>}
        </>
    )
}

export default FormControlNumericInput
