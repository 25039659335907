import useDetailPageParams from 'hooks/useDetailPageParams'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { fetchAndSetTemplateFromId } from 'store/actions/templateActions'
import { useAppDispatch } from 'store/store'
import ProfilesSelectorPanel from 'views/Settings/Profiles/ProfilesSelectorPanel'
import TemplateSelectorTool from 'views/Settings/Templates/TemplateSelectorTool'

const ScenarioDetailPage = () => {
    const { id } = useDetailPageParams()
    const dispatch = useAppDispatch()
    // need actual scenario with template id
    const [scenario, setScenario] = useState<any>({ templateId: id })
    useEffect(() => {
        // TEMPORARY CODE THAT JUST LOADS THE TEMPLATE FROM THE URL PARAM ID
        // eg: http://localhost:8200/editscenario/297498b0-17c7-4ab3-bf09-076c2a848c31
        // loads template with id 297498b0-17c7-4ab3-bf09-076c2a848c31
        const asyncCall = async () => {
            try {
                await dispatch(fetchAndSetTemplateFromId(scenario.templateId))
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        asyncCall()
    }, [dispatch, scenario.templateId])

    return (
        <>
            <h1>Scenario / Template</h1>
            <TemplateSelectorTool
                onSelected={(templateId: string) => {
                    setScenario({ ...scenario, templateId })
                }}
                onSave={() => {}}
            />
            <ProfilesSelectorPanel />
        </>
    )
}

export default ScenarioDetailPage
