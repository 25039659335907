import { SvgIcon } from '@progress/kendo-react-common'
import { gearIcon } from '@progress/kendo-svg-icons'
import { To, useNavigate } from 'react-router-dom'
import { OperationMode } from './FormControlBase'

export interface FormControlGearIconLinkProps {
    onClick?: React.MouseEventHandler<HTMLOrSVGElement>
    link?: To
    data?: any
    operationMode?: OperationMode
}

const FormControlGearIconLink = (props: FormControlGearIconLinkProps): JSX.Element => {
    const navigate = useNavigate()
    const { link, data } = props
    const OnClick = props.onClick
        ? props.onClick
        : () => {
              navigate(link!, { state: { data, operationMode: props.operationMode } })
          }
    return <SvgIcon icon={gearIcon} size="large" onClick={OnClick} style={{ cursor: 'pointer' }} />
}

export default FormControlGearIconLink
