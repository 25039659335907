import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { canReorderDown, canReorderUp, MoveDirection } from 'services/utilities/arrayUtils'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonColumns from 'views/Common/Buttons/IconButtonColumns'
import IconButtonFilter from 'views/Common/Buttons/IconButtonFilter'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'

interface Props {
    items: { id: string }[]
    selectedIds: string[]
    onAddClick?: () => void
    onCopyClick?: () => void
    onDeleteClick?: () => void
    onColumnPickerClick?: () => void
    onFilterClick?: () => void
    onMoveClick?: (direction: MoveDirection) => void
}
const GridToolbarButton = ({
    items,
    selectedIds,
    onAddClick,
    onCopyClick,
    onDeleteClick,
    onColumnPickerClick,
    onFilterClick,
    onMoveClick,
}: Props) => {
    const [idList, setIdList] = useState<number[] | string[]>()
    useEffect(() => {
        setIdList(selectedIds)
    }, [selectedIds])
    const isCopyDisabled = idList?.length !== 1 || items?.length === 0
    const isDeleteDisabled = idList?.length === 0 || items?.length === 0
    const isMoveUpDisabled = !canReorderUp(selectedIds, items)
    const isMoveDownDisabled = !canReorderDown(selectedIds, items)
    return (
        <>
            <IconButton
                tooltip="Add"
                aria-label="add-profile"
                toolbarLeftMargin
                icon="bi-file-plus"
                onClick={() => onAddClick?.()}
            />
            <IconButton
                tooltip="Copy"
                aria-label="copy-profile"
                toolbarLeftMargin
                icon="bi-files"
                disabled={isCopyDisabled}
                onClick={() => onCopyClick?.()}
            />
            <IconButton
                tooltip="Delete"
                aria-label="delete-profile"
                toolbarLeftMargin
                icon="bi-trash"
                disabled={isDeleteDisabled}
                onClick={() => onDeleteClick?.()}
            />
            {onMoveClick && (
                <IconButton
                    tooltip="Move Up"
                    disabled={isMoveUpDisabled}
                    onClick={() => {
                        onMoveClick?.('up')
                    }}
                    icon="bi-arrow-up-square"
                    toolbarLeftMargin
                />
            )}
            {onMoveClick && (
                <IconButton
                    tooltip="Move Down"
                    disabled={isMoveDownDisabled}
                    onClick={() => {
                        onMoveClick?.('down')
                    }}
                    icon="bi-arrow-down-square"
                    toolbarLeftMargin
                />
            )}
            <SeperatorVertical />
            {onColumnPickerClick && <IconButtonColumns onClick={() => onColumnPickerClick?.()} />}
            {onFilterClick && (
                <IconButtonFilter filterEnabled={true} filterIsActive={true} onClick={() => onFilterClick?.()} />
            )}
            <EllipsisDropdown>
                {onAddClick ? (
                    <EllipsisDropdownItem onClick={() => onAddClick?.()}>
                        <ItemWithIcon bootstrapIconClass="bi-file-plus">Add</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
                {onCopyClick ? (
                    <EllipsisDropdownItem disabled={isCopyDisabled} onClick={() => onCopyClick?.()}>
                        <ItemWithIcon bootstrapIconClass="bi-files">Copy</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
                {onDeleteClick ? (
                    <EllipsisDropdownItem disabled={isDeleteDisabled} onClick={() => onDeleteClick?.()}>
                        <ItemWithIcon bootstrapIconClass="bi-trash">Delete</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
                {onMoveClick ? (
                    <EllipsisDropdownItem
                        disabled={isMoveUpDisabled}
                        onClick={() => {
                            onMoveClick?.('up')
                        }}
                    >
                        <ItemWithIcon bootstrapIconClass="bi-arrow-up-square">Move Up</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
                {onMoveClick ? (
                    <EllipsisDropdownItem
                        disabled={isMoveDownDisabled}
                        onClick={() => {
                            onMoveClick?.('down')
                        }}
                    >
                        <ItemWithIcon bootstrapIconClass="bi-arrow-down-square">Move Down</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
                {onColumnPickerClick || onFilterClick ? <Dropdown.Divider /> : <></>}
                {onColumnPickerClick ? (
                    <EllipsisDropdownItem onClick={() => onColumnPickerClick?.()}>
                        <ItemWithIcon bootstrapIconClass="bi-layout-three-columns">Columns</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
                {onFilterClick ? (
                    <EllipsisDropdownItem onClick={() => onFilterClick?.()}>
                        <ItemWithIcon bootstrapIconClass="bi-funnel">Filtering</ItemWithIcon>
                    </EllipsisDropdownItem>
                ) : (
                    <></>
                )}
            </EllipsisDropdown>
        </>
    )
}

export default GridToolbarButton
