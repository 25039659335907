import { Switch } from '@progress/kendo-react-inputs'
import { BaseInputProps } from './FormControlBase'

export interface FormControlSwitchProps extends BaseInputProps {
    value: boolean
    onChange: (e: any) => void
}

const FormControlSwitch = (props: FormControlSwitchProps) => {
    return <Switch id={props.id} name={props.id} checked={props.value} onChange={props.onChange} />
}

export default FormControlSwitch
