import { getRecordKeyValues, ScheduleTypeNames } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    InputFileFormRow,
    InputSelectFormRow,
    MultiColumnFormRow,
    MultiColumnFormRowProps,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'
import CommonDataSourceParameters from './CommonDataSourceParameters'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface DataSourceJeppesenCtfProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceJeppesenCtf = (props: DataSourceJeppesenCtfProps) => {
    const filterDateStartMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Date Start',
        subText: 'Include events starting on or after',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.dateStartFilterEnable,
                    onChange: props.onChange,
                    id: 'dateStartFilterEnable',
                },
            },
            {
                colType: 'datePickerInput',
                colWidth: 6,
                datePickerProps: {
                    value: props.profile.dateStartFilter,
                    onChange: props.onChange,
                    id: 'dateStartFilter',
                    required: true,
                },
            },
        ],
    }

    const filterDateEndMultiColumn: MultiColumnFormRowProps = {
        labelText: 'Date End',
        subText: 'Include events ending on or before',
        columns: [
            {
                colType: 'switchInput',
                colWidth: 1,
                switchProps: {
                    value: props.profile.dateEndFilterEnable,
                    onChange: props.onChange,
                    id: 'dateEndFilterEnable',
                },
            },
            {
                colType: 'datePickerInput',
                colWidth: 6,
                datePickerProps: {
                    value: props.profile.dateEndFilter,
                    onChange: props.onChange,
                    id: 'dateEndFilter',
                    required: true,
                },
            },
        ],
    }

    return (
        <>
            <InputSelectFormRow
                labelText="Schedule Type"
                subText="The types of schedules to load into a scenario"
                value={props.profile.scheduleType}
                onChange={props.onChange}
                fieldId="scheduleType"
                options={getRecordKeyValues(ScheduleTypeNames)}
            />

            <CommonDataSourceParameters profile={props.profile} onChange={props.onChange} />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />

            <IndentedBlock headingText="Filters" subheadingText="Which events to include in the schedules">
                {props.profile.dateStartFilterEnable ? (
                    <MultiColumnFormRow {...filterDateStartMultiColumn} />
                ) : (
                    <SwitchFormRow
                        labelText="Date Start"
                        subText="Include events starting on or after"
                        value={props.profile.dateStartFilterEnable}
                        onChange={props.onChange}
                        fieldId="dateStartFilterEnable"
                    />
                )}

                {props.profile.dateEndFilterEnable ? (
                    <MultiColumnFormRow {...filterDateEndMultiColumn} />
                ) : (
                    <SwitchFormRow
                        labelText="Date End"
                        subText="Include events ending on or before"
                        value={props.profile.dateEndFilterEnable}
                        onChange={props.onChange}
                        fieldId="dateEndFilterEnable"
                    />
                )}
            </IndentedBlock>

            <InputFileFormRow
                labelText="Jeppesen CTF File"
                fieldId="dataFieldPath1"
                subText="Local path to a CTF file (.ctf)"
                value={props.profile.dataFieldPath1}
                onChange={props.onChange}
                disabled={true} // Temporarily disabled
            />
        </>
    )
}

export default DataSourceJeppesenCtf
