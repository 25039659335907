import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getHeaderCellClass, isTagColumn } from 'services/utilities/kendoGridColumnsHelpers'
import { getFilterFields } from 'services/utilities/kendoGridUtils'
import { GridLayoutState } from 'store/gridLayoutStore'
import { RootState } from 'store/store'
import { GridLayout, GridLayoutIdentifier, KendoGridColumn } from 'types/GridLayout'
import { ColumnMenuWithFilter } from 'views/Common/Kendo/CustomColumnMenu'

const getActiveGridLayoutOptional = (
    configs: GridLayout[] | undefined,
    gridId?: GridLayoutIdentifier,
): GridLayout | undefined => {
    if (!configs || configs.length === 0) {
        return undefined
    }
    return getActiveGridLayout(configs, gridId)
}

export const getActiveGridLayout = (configs: GridLayout[], gridId?: GridLayoutIdentifier): GridLayout => {
    const appliedGridId = gridId || configs?.[0]?.gridId
    if (!appliedGridId) {
        throw new Error('No gridId found!')
    }
    const filteredConfigs = configs.filter((x) => x.gridId === appliedGridId)
    const activeConfig =
        filteredConfigs.find((x) => x.active) || filteredConfigs.find((x) => x.accessLevel === 'System')
    if (!activeConfig) {
        throw new Error(`No active grid configuration found of type "${appliedGridId}"`)
    }
    return activeConfig
}

const getTagNameFromColumn = (column: KendoGridColumn) => column.field!.split('.')[1]

const filterOutNonExistentTags = (column: KendoGridColumn, tagNames: string[]) => {
    if (!isTagColumn(column)) {
        return true
    }

    // is a tag
    const tagName = getTagNameFromColumn(column)
    return tagNames.includes(tagName)
}

const getColumnsWithTags = (columns: KendoGridColumn[], tagNames: string[], filteredFields: string[]) => {
    const updatedColumns = JSON.parse(JSON.stringify(columns)) as KendoGridColumn[]
    tagNames.sort()
    tagNames.forEach((tagName) => {
        const tagField = `tagValues.${tagName}`
        if (!updatedColumns.find((x) => x.field === tagField)) {
            updatedColumns.push({
                field: tagField,
                title: `${tagName}*`,
                filter: 'text',
                headerClassName: getHeaderCellClass(tagField, filteredFields),
                columnMenu: ColumnMenuWithFilter,
                hide: true,
                width: 100,
            })
        }
    })

    return updatedColumns.filter((x) => filterOutNonExistentTags(x, tagNames))
}

/**
 * Main hook for getting grid layouts
 * @param gridName
 * @returns
 */
const useGridLayout = (gridName: GridLayoutIdentifier, tagNames?: string[]): [GridLayout | undefined, GridLayout[]] => {
    const { items } = useSelector<RootState, GridLayoutState>((x) => x.gridLayouts)
    const filteredGridLayout = items.filter((x) => x.gridId === gridName)
    const activeGridLayout = getActiveGridLayoutOptional(filteredGridLayout, gridName)
    const activeGridLayoutCustomized = useMemo(() => {
        if (!activeGridLayout) {
            return undefined
        }
        const gridLayoutCopy = JSON.parse(JSON.stringify(activeGridLayout))
        if (tagNames) {
            const filterFields = getFilterFields(gridLayoutCopy.configurationJson.dataState?.filter?.filters || [])
            gridLayoutCopy.configurationJson.columns = getColumnsWithTags(
                gridLayoutCopy.configurationJson.columns,
                tagNames,
                filterFields,
            )
        }
        return gridLayoutCopy
    }, [activeGridLayout, tagNames])

    return [activeGridLayoutCustomized, filteredGridLayout]
}

export default useGridLayout
