import { useState } from 'react'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import {
    FormControlChangeEvent,
    OperationMode,
    updateObjectWithFormEvent,
} from 'views/Common/Form/FormControls/FormControlBase'
import { DrillDownFormRow, InputNumericFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import ProfileCommonFields from '../ProfileCommonFields'
import WorkloadFactorsDialog from './WorkloadFactorsDialog'
import WorkloadRulesDialog from './WorkloadRulesDialog'

interface ProfileWorkloadParametersProps {
    operationMode: OperationMode
    enableCommonFields: boolean
}

const ProfileWorkloadDetailsPanel = ({ operationMode, enableCommonFields }: ProfileWorkloadParametersProps) => {
    const [showWorkloadFactorsDialog, setShowWorkloadFactorsDialog] = useState<boolean>(false)
    const [showWorkloadRulesDialog, setShowWorkloadRulesDialog] = useState<boolean>(false)

    const profile = useAppSelector((state) => state.profile.profileWorkload)
    const dispatch = useAppDispatch()
    if (!profile) return null
    const { id, name, description, workloadFactors, workloadRules, scaleMaximum, decayPeriod } = profile

    const onChange = (e: FormControlChangeEvent) => {
        const updated = updateObjectWithFormEvent(profile, e)
        dispatch(profileActions.setProfileByType({ profile: updated, type: 'Workload', isModified: true }))
    }

    return (
        <>
            {enableCommonFields && (
                <FormSection title="Details">
                    <ProfileCommonFields nameField={name} descriptionField={description} onChange={onChange} />
                </FormSection>
            )}
            <FormSection title="Parameters">
                <DrillDownFormRow
                    labelText="Factors"
                    subText="Customized workload factors and weights"
                    data={workloadFactors}
                    operationMode={operationMode}
                    onClick={() => setShowWorkloadFactorsDialog(true)}
                    value={`${workloadFactors?.length} Factor${workloadFactors?.length === 1 ? '' : 's'}`}
                />

                <DrillDownFormRow
                    labelText="Rules"
                    subText="Rules to define workload triggers"
                    data={workloadRules}
                    operationMode={operationMode}
                    onClick={() => setShowWorkloadRulesDialog(true)}
                    value={`${workloadRules?.length} Rule${workloadRules?.length === 1 ? '' : 's'}`}
                />

                <InputNumericFormRow
                    labelText="Scale Maximum"
                    subText="The virtual maximum workload value"
                    value={scaleMaximum}
                    onChange={onChange}
                    fieldId="scaleMaximum"
                    minValue={0}
                    invalidText="Enter a value of 0 or greater"
                    required={true}
                />

                <InputNumericFormRow
                    labelText="Decay Period"
                    subText="The number of hours for a single factor to decay"
                    value={decayPeriod}
                    onChange={onChange}
                    fieldId="decayPeriod"
                    minValue={0}
                    invalidText="Enter a value of 0 or greater"
                    required={true}
                />
            </FormSection>

            {showWorkloadFactorsDialog && (
                <WorkloadFactorsDialog profileId={id} onClose={() => setShowWorkloadFactorsDialog(false)} />
            )}
            {showWorkloadRulesDialog && (
                <WorkloadRulesDialog profileId={id} onClose={() => setShowWorkloadRulesDialog(false)} />
            )}
        </>
    )
}

export default ProfileWorkloadDetailsPanel
