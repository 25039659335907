import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { BaseInputProps } from './FormControlBase'

export interface FormControlTextAreaInputProps extends BaseInputProps {
    value?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const FormControlTextAreaInput = (props: FormControlTextAreaInputProps) => {
    return (
        <>
            <Form.Control
                as="textarea"
                rows={3}
                value={props.value?.trimStart()}
                onChange={props.onChange}
                id={props.id}
                name={props.id}
            />
            {props.invalidText && <Form.Control.Feedback type="invalid">{props.invalidText}</Form.Control.Feedback>}
        </>
    )
}

export default FormControlTextAreaInput
