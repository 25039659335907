import useSettingValue from 'hooks/useSettingValue'
import { FormEvent, memo, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import globals from 'services/global/globals'
import { globalActions } from 'store/globalStore'
import { SettingConsts } from 'types/SystemSetting'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

export type RenameScenarioDialogProps = {
    scenarioId?: number
    readonly?: boolean
    scenarioName?: string
    closeCallback: (dialogResult: DialogResultEnum) => void
}

const RenameScenarioDialogContent = (props: RenameScenarioDialogProps) => {
    const api = globals.getApi()
    const dispatch = useDispatch()
    const [scenarioName, setScenarioName] = useState(`${props.scenarioName}`)
    const [validated, setValidated] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const scenarioNameCaption = useSettingValue(SettingConsts.general.account_Project_Name)
    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidated(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            await api.renameScenario(props.scenarioId!, scenarioName)
            props.closeCallback(DialogResultEnum.Completed)
        } catch (err: any) {
            setErrorMessage(err.message)
        } finally {
            dispatch(globalActions.hideLoadingModal())
        }
    }
    const scenarioNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScenarioName(e.target.value)
    }

    return (
        <Form noValidate validated={validated} onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Rename Scenario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Control
                        name="scenarioName"
                        autoFocus
                        disabled={props.readonly}
                        autoComplete="off"
                        type="text"
                        placeholder="Enter a name for the scenario"
                        value={scenarioName}
                        onChange={scenarioNameChangeHandler}
                        required
                    />
                    <Form.Control.Feedback type="invalid">Please provide a name for the scenario</Form.Control.Feedback>
                    {scenarioNameCaption && <Form.Text className="text-muted">{scenarioNameCaption}</Form.Text>}
                </Form.Group>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom isLarge type="submit" variant="primary" disabled={props.readonly}>
                    Save
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const RenameScenarioDialog = (props: RenameScenarioDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <RenameScenarioDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(RenameScenarioDialog)
