import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ColorPalettes } from 'types/interfaces'

interface DefaultPalettesState {
    palettes: ColorPalettes | null
    isFetching: boolean
}

const initialState: DefaultPalettesState = {
    palettes: null,
    isFetching: false,
}

const palettesReducer = createSlice({
    name: 'palettes',
    initialState,
    reducers: {
        setPalettes(state, action: PayloadAction<ColorPalettes>) {
            state.palettes = action.payload
            state.isFetching = false
        },
        setIsFetching(state) {
            state.isFetching = true
        },
        clearPalettes(state) {
            state.palettes = null
        },
    },
})

export default palettesReducer.reducer
export const palettesActions = palettesReducer.actions
