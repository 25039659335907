import { useParams } from 'react-router-dom'
import { OperationMode } from 'views/Common/Form/FormControls/FormControlBase'

export interface DetailPageParams {
    id: string
    operationMode: OperationMode
}
const useDetailPageParams = (): DetailPageParams => {
    const { id, operation } = useParams<{ id?: string; operation?: string }>()
    if (!id) {
        throw new Error('id is required in url parameters for useDetailPageParams')
    }
    let operationMode: OperationMode = 'Create'
    if (id === undefined || id === 'new') {
        operationMode = 'Create'
    } else if (operation?.toLowerCase() === 'copy') {
        operationMode = 'Copy'
    } else if ((id && !operation) || operation?.toLowerCase() === 'edit') {
        operationMode = 'Edit'
    }

    return { id, operationMode }
}

export default useDetailPageParams
