import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Input, NumericTextBox, Switch } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import { SystemSetting } from 'types/SystemSetting'
import FormRow from 'views/Common/Form/FormRows/FormRow'

interface Props {
    items: SystemSetting[]
    onChange?: (setting: SystemSetting) => void
    testId?: string
}
const Settings = ({ items, onChange, testId }: Props) => {
    const [state, setState] = useState<SystemSetting[]>([])
    useEffect(() => {
        setState(items)
    }, [items])

    function handlInputChange(name: string, value: string) {
        setState(
            state.map((element) => {
                if (element.name === name) {
                    element.value = value
                    onChange?.(element)
                    return element
                }
                return element
            }),
        )
    }
    const displayItem = (item: SystemSetting) => {
        let result = <></>
        switch (item.settingDefinitionType) {
            case 'Text':
                result = (
                    <Input
                        value={item.value}
                        required={item.required}
                        onChange={(e) => handlInputChange(item.name, e.value)}
                    />
                )
                break
            case 'Number':
                result = (
                    <NumericTextBox
                        defaultValue={Number(item.value)}
                        format="n"
                        onChange={(e) => handlInputChange(item.name, e.value === null ? '0' : e.value.toString())}
                    />
                )
                break
            case 'Boolean':
                result = (
                    <Switch
                        defaultChecked={item.value.toLocaleLowerCase() === 'true'}
                        onChange={(e) => handlInputChange(item.name, e.value.toString())}
                    />
                )
                break
            case 'Select':
                result = (
                    <DropDownList
                        data={item.options}
                        defaultValue={item.value}
                        onChange={(e) => handlInputChange(item.name, e.value)}
                    />
                )
                break
            default:
                break
        }
        return result
    }
    return (
        <>
            {state.map((item) => (
                <FormRow key={item.name} labelText={item.displayName} fieldId={item.name} subText={item.description}>
                    <div className="mt-4" data-testid={testId ? testId + item.name : undefined}>
                        {displayItem(item)}
                    </div>
                </FormRow>
            ))}
        </>
    )
}

export default Settings
