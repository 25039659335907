import { GridCellProps } from '@progress/kendo-react-grid'
import { getPaletteWithParameters } from 'hooks/usePalettes'
import { Duty } from 'types/HazardClassCase'
import { ColorPalettes, PaletteType } from 'types/interfaces'
import Scenario from 'types/Scenario'
import Schedule from 'types/Schedule'
import ScheduleEvent from 'types/ScheduleEvent'
import {
    getBlankNode,
    getHazardClassPatternDutyMetricField,
    getReportDrilldownMetricField,
    getScenarioMetricField,
    getScheduleEventMetricField,
    getScheduleMetricField,
    PalettedValue,
} from 'views/Common/Palette/PalettedMetric'
import CustomValueCell from './CustomValueCell'

const GenerateCell = (cellField: PalettedValue, props: GridCellProps) =>
    CustomValueCell(props, cellField.node, {
        color: cellField.foreColor,
        backgroundColor: cellField.backColor,
        borderTop: '1px solid #ddd',
    })

const FormattedScenarioMetricCell = (props: GridCellProps, palettes: ColorPalettes) => {
    const metricName = props.field!.split('.')[1]
    const scenario = props.dataItem as Scenario

    const scenarioPalette = getPaletteWithParameters(scenario.scenarioParameters, palettes)
    const cellField = getScenarioMetricField(scenario, metricName, scenarioPalette)
    return GenerateCell(cellField, props)
}

const FormattedScheduleMetricCell = (props: GridCellProps, palettes: ColorPalettes) => {
    const metricName = props.field!.split('.')[1]
    const schedule = props.dataItem as Schedule
    const scenarioPalette = getPaletteWithParameters(schedule.scenarioParameters, palettes)

    const cellField = getScheduleMetricField(schedule, metricName, scenarioPalette)
    return GenerateCell(cellField, props)
}

const FormattedEventMetricCell = (schedule: Schedule, props: GridCellProps, palettes: ColorPalettes) => {
    const metricName = props.field!
    const scheduleEvent = props.dataItem as ScheduleEvent

    if (metricName.includes('Critical') && !scheduleEvent.hasCriticalTime()) {
        return GenerateCell(getBlankNode(), props)
    }
    if (scheduleEvent.isDutyRollup()) {
        return GenerateCell(getBlankNode(), props)
    }
    const classCrewing = `${scheduleEvent.crewing ? 'labelCrewing' : ''}`
    // use the spread operator on props to add
    // onto any already existing css classes
    // that may be present
    const newProps = {
        ...props,
        className: classCrewing,
    }
    const cellField = getScheduleEventMetricField(palettes, scheduleEvent, metricName)
    return GenerateCell(cellField, newProps)
}

const FormattedReportDrilldownMetricCell = (
    props: GridCellProps,
    paletteType: PaletteType,
    palettes: ColorPalettes,
) => {
    const metricName = props.field!
    const reportDrilldownRecord = props.dataItem

    if (!reportDrilldownRecord.scenario) {
        throw Error('report drilldown record is missing the scenario object')
    }
    const scenario = reportDrilldownRecord.scenario as Scenario
    const scenarioPalette = getPaletteWithParameters(scenario.scenarioParameters, palettes)
    const cellField = getReportDrilldownMetricField(
        reportDrilldownRecord.scenario,
        scenarioPalette,
        metricName,
        paletteType,
        reportDrilldownRecord,
    )
    return GenerateCell(cellField, props)
}

const FormattedHazardClassPatternDutyMetricCell = (
    props: GridCellProps,
    paletteType: PaletteType,
    palettes: ColorPalettes,
) => {
    const metricName = props.field!
    const duty = props.dataItem as Duty

    const scenarioPalette = getPaletteWithParameters(
        { criterionLevel: duty.criterionLevel, percentBelowCriterionThreshold: null },
        palettes,
    )
    const cellField = getHazardClassPatternDutyMetricField(duty, metricName, paletteType, scenarioPalette)
    return GenerateCell(cellField, props)
}

export {
    FormattedReportDrilldownMetricCell,
    FormattedScheduleMetricCell,
    FormattedScenarioMetricCell,
    FormattedEventMetricCell,
    FormattedHazardClassPatternDutyMetricCell,
}
