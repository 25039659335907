import { Col, Form, Row } from 'react-bootstrap'
import classes from '../Form.module.css'

export interface FormRowProps {
    labelText: string
    labelColWidth?: number
    subText?: string
    invalidText?: string
    fieldId?: string
    children?: React.ReactNode
}

const FormRow = (props: FormRowProps) => {
    const labelColWidth = props.labelColWidth || 5
    return (
        <Form.Group className="mb-3">
            <Row>
                <Col xs={labelColWidth}>
                    <Form.Label className={classes.label} id={props.fieldId}>
                        {props.labelText}
                    </Form.Label>
                    <br />
                    <small>{props.subText}</small>
                </Col>
                <Col>
                    {props.children}
                    {props.invalidText && (
                        <Form.Control.Feedback type="invalid">{props.invalidText}</Form.Control.Feedback>
                    )}
                </Col>
            </Row>
        </Form.Group>
    )
}

export default FormRow
