import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import classes from './RichDropdown.module.css'

const CustomDropdown = ({
    open,
    displayText,
    scrollableContent,
    fixedHeaderContent,
    fixedFooterContent,
    footerDivider,
    headerDivider,
    scrollingSectionStyling,
}: {
    open?: boolean
    displayText: string
    scrollableContent: JSX.Element
    fixedHeaderContent?: JSX.Element
    fixedFooterContent?: JSX.Element
    headerDivider?: boolean
    footerDivider?: boolean
    scrollingSectionStyling?: {
        maxHeight?: string
    }
}) => {
    return (
        <div className="d-flex rich-dropdown">
            <DropdownButton
                as={ButtonGroup}
                role="combobox"
                size="sm"
                defaultShow={open}
                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                title={displayText}
                variant="white"
                className={classes.dropdown}
                align="end"
            >
                {fixedHeaderContent && fixedHeaderContent}
                {headerDivider && <Dropdown.Divider />}
                <div className={classes.dropdownScrollingSection} style={scrollingSectionStyling}>
                    {scrollableContent}
                </div>
                {footerDivider && <Dropdown.Divider />}
                {fixedFooterContent && fixedFooterContent}
            </DropdownButton>
        </div>
    )
}

export default CustomDropdown
