import { DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns'
import {
    GridColumnMenuFilter,
    GridColumnMenuFilterUIProps,
    GridColumnMenuProps,
    GridColumnProps,
} from '@progress/kendo-react-grid'
import { useState } from 'react'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { OrderableColumn } from 'types/GridLayout'

export interface KendoGridColumn extends GridColumnProps, OrderableColumn {
    headerClassName?: string
    cellClassName?: string
    hide?: boolean
    children?: KendoGridColumn[]
}

export const ColumnMenuWithFilter = (props: GridColumnMenuProps) => {
    const customDateFilter = props.column.filter === 'date' ? DateColumnCustomFilter : undefined
    return <GridColumnMenuFilter {...props} expanded hideSecondFilter filterUI={customDateFilter} />
}

/**
 * Custom ui for filtering dates because there seems to be no way to change the date format
 * in the default date filter widget.
 * @param props
 * @returns
 */
const DateColumnCustomFilter = (props: GridColumnMenuFilterUIProps) => {
    const [operator, setOperator] = useState<string | Function | undefined>(props.firstFilterProps.operator)
    const [time, setTime] = useState<Date | undefined>(props.firstFilterProps.value)

    const fireChangeEvent = (
        updatedTime: Date,
        updatedOperator: string | Function,
        synthEvent: React.SyntheticEvent<any, Event>,
    ) => {
        const evt = {
            value: updatedTime,
            operator: updatedOperator!,
            syntheticEvent: synthEvent,
        }
        props.firstFilterProps.onChange(evt)
    }

    return (
        <>
            <DropDownList
                dataItemKey="operator"
                textField="text"
                data={props.firstFilterProps.operators}
                value={props.firstFilterProps.operators.find((x) => x.operator === operator)}
                onChange={(e: DropDownListChangeEvent) => {
                    setOperator(e.value.operator)
                    if (time) {
                        fireChangeEvent(time, e.value.operator, e.syntheticEvent)
                    }
                }}
            />
            <DateTimePicker
                defaultValue={props.firstFilterProps.value}
                format={dateTimeFormatting.getKendoDateTimeFormat()}
                onChange={(e: DateTimePickerChangeEvent) => {
                    if (e.value && operator) {
                        setTime(e.value)
                        fireChangeEvent(e.value, operator, e.syntheticEvent)
                    }
                }}
            />
        </>
    )
}
