import { cloneDeep } from 'lodash'
import palettesActions from 'store/actions/palettesActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import { ColorPalettes } from 'types/interfaces'

export interface UsePaletteOptions {
    criterionLevel: number
    percentBelowCriterionThreshold: number
}

const usePalettes = (): ColorPalettes | null => {
    const dispatch = useAppDispatch()
    dispatch(palettesActions.fetchPalettes())
    return useAppSelector((state) => state.palettes.palettes)
}

export const getPaletteWithParameters = (
    {
        percentBelowCriterionThreshold,
        criterionLevel,
    }: { percentBelowCriterionThreshold: number | null; criterionLevel: number | null },
    palettes: ColorPalettes,
) => {
    const paletteCopy = cloneDeep(palettes)
    if (percentBelowCriterionThreshold) {
        paletteCopy.percentage[0].threshold = percentBelowCriterionThreshold
    }
    if (criterionLevel) {
        paletteCopy.effectiveness[1].threshold = criterionLevel
    }
    paletteCopy.hasParameterSet = true
    return paletteCopy
}

export default usePalettes
