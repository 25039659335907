interface FormSectionProps {
    title?: any
    children: React.ReactNode
}

const FormSection = (props: FormSectionProps) => {
    return (
        <>
            {props.title != null && <h5 style={{ marginTop: '10px' }}>{props.title}</h5>}
            <div
                style={{
                    backgroundColor: '#FAFAFA',
                    padding: '20px 20px 20px 20px',
                }}
            >
                {props.children}
            </div>
        </>
    )
}

export default FormSection
