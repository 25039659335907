import { ChangeEvent, FormEvent, memo, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import { RootState } from 'store/store'
import { User, UserEditable } from 'types/interfaces'
import MetaData from 'types/Metadata'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

export interface UserDialogProps {
    user: UserEditable
    closeCallback: (dialogResult: DialogResultEnum, updatedUser?: User) => void
}

const UserDialogContent = (props: UserDialogProps) => {
    const [validatedForm, setValidatedForm] = useState(false)
    const [user, setUser] = useState(props.user)
    const api = globals.getApi()

    const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()

        try {
            const updatedUser = user.id === 0 ? await api.createUser(user) : await api.updateUser(user)
            props.closeCallback(DialogResultEnum.Completed, updatedUser)
        } catch (err: any) {
            setValidatedForm(true)
            handleApiError(err)
        }
    }

    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const customFields = metadata.userCustomFields?.split(',').map((value) => value.split(':')[1]) ?? []

    return (
        <>
            <Form noValidate validated={validatedForm} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>{user.id === 0 ? 'Create a new User' : 'Edit User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <FormLabelCustom htmlFor="txtName">Full Name</FormLabelCustom>
                        <Form.Control
                            id="txtName"
                            autoFocus
                            name="name"
                            type="text"
                            placeholder="Full Name"
                            value={user.name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setUser((previous) => ({ ...previous, name: e.target.value }))
                            }
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide the user&apos;s name
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <FormLabelCustom htmlFor="txtEmail">Email Address</FormLabelCustom>
                        <Form.Control
                            id="txtEmail"
                            name="email"
                            type="email"
                            placeholder="Email Address"
                            value={user.loginId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setUser((previous) => ({ ...previous, loginId: e.target.value }))
                            }
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide the user&apos;s email
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <FormLabelCustom htmlFor="ddlRole">Role</FormLabelCustom>
                        <Form.Select
                            id="ddlRole"
                            name="role"
                            value={user.isAdministrator ? 'Administrator' : 'General'}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                setUser((previous) => ({
                                    ...previous,
                                    isAdministrator: e.target.value === 'Administrator',
                                }))
                            }
                        >
                            <option value="General">General</option>
                            <option value="Administrator">Administrator</option>
                        </Form.Select>
                    </Form.Group>

                    {customFields[0] && (
                        <Form.Group>
                            <FormLabelCustom htmlFor="txtField1">{customFields[0]}</FormLabelCustom>
                            <Form.Control
                                id="txtField1"
                                name="field1"
                                type="text"
                                placeholder={customFields[0]}
                                value={user.field1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setUser((previous) => ({ ...previous, field1: e.target.value }))
                                }
                            />
                        </Form.Group>
                    )}

                    {customFields[1] && (
                        <Form.Group>
                            <FormLabelCustom htmlFor="txtField2">{customFields[1]}</FormLabelCustom>
                            <Form.Control
                                id="txtField2"
                                name="field2"
                                type="text"
                                placeholder={customFields[1]}
                                value={user.field2}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setUser((previous) => ({ ...previous, field2: e.target.value }))
                                }
                            />
                        </Form.Group>
                    )}

                    {customFields[2] && (
                        <Form.Group>
                            <FormLabelCustom htmlFor="txtField3">{customFields[2]}</FormLabelCustom>
                            <Form.Control
                                id="txtField3"
                                name="field3"
                                type="text"
                                placeholder={customFields[2]}
                                value={user.field3}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setUser((previous) => ({ ...previous, field3: e.target.value }))
                                }
                            />
                        </Form.Group>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const UserDialog = (props: UserDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <UserDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(UserDialog)
