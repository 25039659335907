import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns'
import useSettingValue from 'hooks/useSettingValue'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Alert, Col, Form, Modal, Row } from 'react-bootstrap'
import globals from 'services/global/globals'
import { SettingConsts } from 'types/SystemSetting'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

export type SaveAsDialogProps = {
    scheduleId: number
    scheduleName: string
    scenarioName: string
    closeCallback: (status: DialogResultEnum) => void
    saveAsConfirmed: (newScheduleId: number) => void
}

const ScheduleSaveAsDialogContent = (props: SaveAsDialogProps) => {
    const [scheduleName, setScheduleName] = useState(props.scheduleName)
    const [scenarioName, setScenarioName] = useState(props.scenarioName)
    const [scenariosList, setScenariosList] = useState<string[]>([])
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const scheduleNameCaption = useSettingValue(SettingConsts.general.account_Schedule_Name)
    const api = globals.getApi()

    useEffect(() => {
        const getScenariosList = async () => {
            try {
                setScenariosList(await api.getScenarioNamesList())
            } catch (err: any) {
                setErrorMessage(err.message)
            }
        }

        getScenariosList()
    }, [api])

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidatedForm(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            const newScheduleId = await api.duplicateSchedule(props.scheduleId, true, scheduleName, scenarioName)
            props.saveAsConfirmed(newScheduleId)
        } catch (err: any) {
            setErrorMessage(err.message)
        }
    }

    return (
        <>
            <Form id="scheduleSaveAsForm" noValidate validated={validatedForm} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule Save As...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtScheduleName">New Name</FormLabelCustom>
                                <Form.Control
                                    id="txtScheduleName"
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Enter a new name for the schedule"
                                    value={scheduleName}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setScheduleName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a schedule name
                                </Form.Control.Feedback>
                                {scheduleNameCaption && (
                                    <Form.Text className="text-muted">{scheduleNameCaption}</Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom>Scenario Name</FormLabelCustom>
                                <ComboBox
                                    style={{ width: '100%' }}
                                    value={scenariosList.includes(scenarioName) ? scenarioName : undefined}
                                    data={scenariosList}
                                    allowCustom
                                    required
                                    placeholder="Select an existing Scenario or type a new name"
                                    onChange={(e: ComboBoxChangeEvent) => {
                                        // Only allow saving to the users list of scenarios
                                        if (scenariosList.includes(e.value)) {
                                            setScenarioName(e.value)
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const ScheduleSaveAsDialog = (props: SaveAsDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <ScheduleSaveAsDialogContent {...props} />
        </ModalWrapper>
    )
}
export default ScheduleSaveAsDialog
