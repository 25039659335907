import { Dispatch, ThunkAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import globals from 'services/global/globals'
import { globalActions } from 'store/globalStore'
import { RootState } from 'store/store'

/**
 * fetch reporting meta data (used by query builder tool, and other places) into redux
 * @returns
 */
const getReportMetaData = (): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        if (state.app.reportingMetadata) {
            // already loaded into redux
            return
        }
        const metadata = await globals.getApi().getReportingApi().getReportsConfigMetadata()
        dispatch(globalActions.setReportingMetadata(metadata))
    }
}

export default { getReportMetaData }
