import { selectProfileByType } from 'store/actions/profileActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getRecordKeyValues, InputTypeNames } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import {
    FormControlChangeEvent,
    OperationMode,
    updateObjectWithFormEvent,
} from 'views/Common/Form/FormControls/FormControlBase'
import { InputSelectFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import ProfileCommonFields from '../ProfileCommonFields'
import DataSourceAltitude from './DataSourceAltitudePanel'
import DataSourceCrewControlPairings from './DataSourceCrewControlPairingsPanel'
import DataSourceCrewControlRosters from './DataSourceCrewControlRostersPanel'
import DataSourceCrewTracLIN from './DataSourceCrewTracLINPanel'
import DataSourceCrewTracPRG from './DataSourceCrewTracPRGPanel'
import DataSourceFasPairings from './DataSourceFasPairingsPanel'
import DataSourceFosPairings from './DataSourceFosPairingsPanel'
import DataSourceFujitsu from './DataSourceFujitsuPanel'
import DataSourceJeppesenCtf from './DataSourceJeppesenCtfPanel'
import DataSourceJeppesen from './DataSourceJeppesenPanel'
import DataSourceKronos from './DataSourceKronosPanel'
import DataSourceSafteFastCSV from './DataSourceSafteFastCSVPanel'
import DataSourceSafteFastXML from './DataSourceSafteFastXMLPanel'
import DataSourceZulu from './DataSourceZuluPanel'
import DataSourceS3Rus from './DataSourceS3RusPanel'

interface DataSourceDetailsPanelProps {
    validatedForm: boolean
    id?: string
    enableCommonFields: boolean
    operationMode: OperationMode
}

const DataSourceDetailsPanel = ({
    id,
    validatedForm,
    enableCommonFields,
    operationMode,
}: DataSourceDetailsPanelProps) => {
    const dispatch = useAppDispatch()

    const idToUse = operationMode === 'Copy' ? 'new' : id
    const profile = useAppSelector((state) => selectProfileByType(state, 'DataSource', idToUse)) as ProfileDataSource
    if (!profile) return null

    const onChange = (e: FormControlChangeEvent) => {
        const updated = updateObjectWithFormEvent(profile, e)
        dispatch(profileActions.setProfileByType({ profile: updated, type: 'DataSource', isModified: true }))
    }

    return (
        <>
            {enableCommonFields && (
                <FormSection title="Details">
                    <ProfileCommonFields
                        nameField={profile.name}
                        descriptionField={profile.description}
                        onChange={onChange}
                    />
                </FormSection>
            )}

            <FormSection title="Data Source">
                <InputSelectFormRow
                    labelText="Data Source Type"
                    subText="The type of Data Source"
                    value={profile.dataSourceType}
                    onChange={onChange}
                    fieldId="dataSourceType"
                    options={getRecordKeyValues(InputTypeNames)}
                />
            </FormSection>

            <FormSection>
                {profile.dataSourceType === 'SAFTEFASTCSV' ? (
                    <DataSourceSafteFastCSV onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}
                {profile.dataSourceType === 'SAFTEFASTXML' ? (
                    <DataSourceSafteFastXML onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}
                {profile.dataSourceType === 'AltitudePairings' ? (
                    <DataSourceAltitude onChange={onChange} profile={profile} />
                ) : null}
                {profile.dataSourceType === 'FASPairings' ? (
                    <DataSourceFasPairings onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}
                {profile.dataSourceType === 'CrewControlCSV' ? (
                    <DataSourceCrewControlRosters onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}
                {profile.dataSourceType === 'FOSPairings' ? (
                    <DataSourceFosPairings onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}
                {profile.dataSourceType === 'Fujitsu' ? (
                    <DataSourceFujitsu onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'Zulu' ? (
                    <DataSourceZulu onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'Jeppesen' ? (
                    <DataSourceJeppesen onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'CrewControlPairings' ? (
                    <DataSourceCrewControlPairings
                        onChange={onChange}
                        profile={profile}
                        validatedForm={validatedForm}
                    />
                ) : null}

                {profile.dataSourceType === 'JeppesenCtf' ? (
                    <DataSourceJeppesenCtf onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'Kronos' ? (
                    <DataSourceKronos onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'CrewTracPRG' ? (
                    <DataSourceCrewTracPRG onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'CrewTracLIN' ? (
                    <DataSourceCrewTracLIN onChange={onChange} profile={profile} validatedForm={validatedForm} />
                ) : null}

                {profile.dataSourceType === 'S3RUS' ? <DataSourceS3Rus onChange={onChange} profile={profile} /> : null}
            </FormSection>
        </>
    )
}

export default DataSourceDetailsPanel
