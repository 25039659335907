import useSettingValue from 'hooks/useSettingValue'
import { ChangeEvent, FormEvent, memo, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import globals from 'services/global/globals'
import { SettingConsts } from 'types/SystemSetting'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import ProgressBar from 'views/Common/Widget/ProgressBar'

/**
 * Separate conent component from the dialog component to avoid re-rendering the entire dialog during state changes.
 * @param props
 * @returns
 */
const ImportScenarioDialogContent = (props: { closeCallback: (status: DialogResultEnum) => void }) => {
    const [uploadProgress, setUploadProgress] = useState(0)
    const [fileUploadPath, setFileUploadPath] = useState('Browse to your schedules file...')
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [validated, setValidated] = useState(false)
    const api = globals.getApi()
    const processingApi = api.getProcessingApi()
    const scenarioNameCaption = useSettingValue(SettingConsts.general.account_Project_Name)
    const inputTypes = useSettingValue(SettingConsts.general.schedules_Input_Types).split(',')

    const uploadProgressFeedbackHandler = (progressEvent: any) => {
        const progressAmount = (progressEvent.loaded as number) / (progressEvent.total as number)
        setUploadProgress(progressAmount)
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()
        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidated(true)
            // keep the form open, let the user fix the issues
            return
        }

        const formData = new FormData(form)

        try {
            setUploadProgress(1)
            await processingApi.importScenario(formData, uploadProgressFeedbackHandler)
            props.closeCallback(DialogResultEnum.Completed)
        } catch (err: any) {
            setUploadProgress(0)
            setErrorMessage(err.message)
        }
    }

    return (
        <Form id="importScenarioDialog" noValidate validated={validated} onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Import Scenario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <FormLabelCustom htmlFor="txtScenarioName">Scenario Name</FormLabelCustom>
                    <Form.Control
                        id="txtScenarioName"
                        name="scenarioName"
                        autoFocus
                        autoComplete="off"
                        type="text"
                        placeholder="Scenario Name"
                        required
                    />
                    <Form.Control.Feedback type="invalid">Please enter a scenario name</Form.Control.Feedback>
                    {scenarioNameCaption && <Form.Text className="text-muted">{scenarioNameCaption}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <FormLabelCustom>Schedules File Selector</FormLabelCustom>
                    <Form.Control
                        type="file"
                        id="fileUpload"
                        name="schedulesFile"
                        placeholder={fileUploadPath}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFileUploadPath(e.target.value)
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Please select a file</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <FormLabelCustom>File Type</FormLabelCustom>
                    <Form.Select name="fileType">
                        {inputTypes.map((x) => (
                            <option key={x} value={x}>
                                {x}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <FormLabelCustom>Non-Crewing Event Code Filter</FormLabelCustom>
                    <div className={simpleDialogClasses.checkBoxContainer + ' mb-2'}>
                        <Form.Check
                            type="radio"
                            id="radioEventCodesIgnore"
                            name="rdoEventCodeFilter"
                            label="Ignore these:"
                            defaultChecked
                            value="ignore"
                        />
                        <Form.Check
                            type="radio"
                            id="radioEventCodesInclude"
                            name="rdoEventCodeFilter"
                            label="Include only these:"
                            value="include"
                        />
                    </div>
                    <Form.Control
                        id="txtEventCodeFilter"
                        name="eventCodesFilter"
                        type="text"
                        placeholder="Comma-separated event codes. Eg: training,vacation"
                    />
                </Form.Group>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            </Modal.Body>

            <Modal.Footer>
                {uploadProgress > 0 && (
                    <ProgressBar textDescription="File upload progress" progressValue={uploadProgress} />
                )}
                <ButtonCustom isLarge type="submit" variant="primary" disabled={uploadProgress > 0}>
                    Import
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => {
                        props.closeCallback(DialogResultEnum.Cancelled)
                    }}
                    disabled={uploadProgress > 0}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const ImportScenarioDialog = (props: { closeCallback: (status: DialogResultEnum) => void }) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <ImportScenarioDialogContent closeCallback={props.closeCallback} />
        </ModalWrapper>
    )
}

export default memo(ImportScenarioDialog)
