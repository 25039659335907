import { Builder, BuilderProps, Config, ImmutableTree, Query, Utils as QbUtils } from '@react-awesome-query-builder/ui'
import { useMemo, useState } from 'react'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import {
    convertQueryBuilderToRAConfiguration,
    convertRAToQueryBuilder,
    getConfig,
} from 'services/queryBuilder/queryBuilderService'
import { ReportItemDefinition } from 'types/ReportingMetadata'
import { QueryBuilderValue } from 'types/Reports'

const renderBuilder = (builderProps: BuilderProps) => (
    <div className="query-builder-container">
        <div className="query-builder qb-lite">
            <Builder {...builderProps} />
        </div>
    </div>
)

export interface QueryDialogProps {
    query?: QueryBuilderValue
    reportDataItems: ReportItemDefinition[]
    setQuery: (query: QueryBuilderValue) => void
}

const QueryBuilder = (props: QueryDialogProps) => {
    const convertedQuery = useMemo(() => convertQueryBuilderToRAConfiguration(props.query), [props.query])
    const config = useMemo(() => getConfig(props.reportDataItems), [props.reportDataItems])
    const [query, setQuery] = useState(() => {
        return {
            tree: QbUtils.checkTree(QbUtils.loadTree(convertedQuery), config),
            config,
        }
    })

    // set appropriate date/time format for datetime fields
    Object.keys(config.fields).forEach((key) => {
        const field = config.fields[key]
        if (field.type === 'datetime') {
            field.fieldSettings = {
                dateFormat: dateTimeFormatting.getAppDateFormat(),
                timeFormat: dateTimeFormatting.getAppTimeFormat(),
                valueFormat: 'YYYY-MM-DD HH:mm',
            }
        } else if (field.type === 'date') {
            field.fieldSettings = {
                dateFormat: dateTimeFormatting.getAppDateFormat(),
                valueFormat: 'YYYY-MM-DD',
            }
        } else if (field.type === 'time') {
            field.fieldSettings = {
                timeFormat: dateTimeFormatting.getAppTimeFormat(),
                valueFormat: 'HH:mm',
            }
        }
    })

    return (
        <Query
            {...config}
            value={query.tree}
            onChange={(immutableTree: ImmutableTree, updatedConfig: Config) => {
                setQuery((prevState) => ({ ...prevState, tree: immutableTree, config: updatedConfig }))
                props.setQuery(convertRAToQueryBuilder(immutableTree, updatedConfig, props.reportDataItems))
            }}
            renderBuilder={renderBuilder}
        />
    )
}

export default QueryBuilder
