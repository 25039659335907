import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import DialogLayout from 'views/Common/Layout/DialogLayout'

interface Props {
    topic: string
    message?: string
    numRows: number
    onCloseDialog: (result: DialogResultEnum) => void
}
const DeleteConfirmationDialog = ({ topic, message, numRows, onCloseDialog }: Props) => {
    return (
        <DialogLayout
            width={500}
            height={250}
            headerContent={`Delete ${topic}?`}
            footerContent={
                <FormFooter
                    onCancel={() => onCloseDialog(DialogResultEnum.Cancelled)}
                    onOk={() => onCloseDialog(DialogResultEnum.Completed)}
                />
            }
            onClose={() => onCloseDialog(DialogResultEnum.Cancelled)}
        >
            {message && <div style={{ marginBottom: '15px' }}>{message}</div>}
            <div>{`Are you sure you want to delete the ${numRows} selected ${topic}${numRows > 1 ? 's' : ''}?`}</div>
        </DialogLayout>
    )
}

export default DeleteConfirmationDialog
