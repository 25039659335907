import { AxiosRequestConfig } from 'axios'
import * as sessionToken from './sessionToken'

class SFApiBase {
    protected readonly baseUrl: string
    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL ?? ''
    }

    getConfig = (): AxiosRequestConfig => {
        return {
            headers: {
                Authorization: sessionToken.getSessionToken() ?? '',
            },
        }
    }
}

export default SFApiBase
