import { FormEvent, useEffect, useRef, useState } from 'react'
import Autosuggest, {
    ChangeEvent,
    InputProps,
    RenderInputComponent,
    RenderInputComponentProps,
    SuggestionsFetchRequestedParams,
} from 'react-autosuggest'
import globals from 'services/global/globals'
import { Station } from 'types/interfaces'
import theme from './StationAutoSuggestInput.module.css'

export enum InputStateEnum {
    Invalid,
    Valid,
    Unknown,
}

export type AutoSuggestTheme = {
    container?: string
    suggestion?: string
    suggestionHighlighted?: string
    suggestionsContainer?: string
    suggestionsContainerOpen?: string
}

const StationAutoSuggestInput = (props: {
    id: string
    name: string
    placeholder: string
    value: string
    themeOverrides?: AutoSuggestTheme
    disabled?: boolean
    isInvalidMessage?: string
    includeUtcOffsetForTime?: Date
    onChange: (newValue: string, utcOffset?: number) => void
}) => {
    const api = globals.getApi()
    const stationsApi = api.getStationsApi()
    const [typedValue, setTypedValue] = useState('')
    const [currentValue, setCurrentValue] = useState(props.value)
    const [suggestions, setSuggestions] = useState<ReadonlyArray<Station>>([])
    const [lastMatch, setLastMatch] = useState<string>(currentValue.toLowerCase())
    const [validityState, setValidityState] = useState<InputStateEnum>(InputStateEnum.Unknown)
    const autoSuggestRef = useRef<Autosuggest<Station, any>>(null)

    // when the typed value changes, fetch remote suggestions (throttled)
    useEffect(() => {
        const timer = setTimeout(async () => {
            if (!typedValue || typedValue.length < 2) {
                return
            }
            const fetchedSuggestions = await stationsApi.getStationMatches(typedValue)
            setSuggestions(fetchedSuggestions)
            const typedValueLower = typedValue.toLowerCase()
            const matchingStation = fetchedSuggestions.find(
                (x) => x.codeIATA!.toLowerCase() === typedValueLower || x.codeICAO!.toLowerCase() === typedValueLower,
            )

            if (matchingStation && typedValue !== lastMatch) {
                let utcOffset: number | undefined
                if (props.includeUtcOffsetForTime) {
                    // fetch utc offset
                    utcOffset = await stationsApi.getUtcOffsetAtTime(typedValue, props.includeUtcOffsetForTime)
                }

                setValidState(InputStateEnum.Valid)
                // notify the containing component that the value has changed to a correct station code
                props.onChange(typedValue, utcOffset)
                setLastMatch(typedValue)
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [lastMatch, setLastMatch, props, typedValue, stationsApi])

    const onSuggestionsFetchRequested = async (params: SuggestionsFetchRequestedParams) => {
        setTypedValue(params.value)
    }

    const setValidState = (state: InputStateEnum) => {
        autoSuggestRef.current!.input!.setCustomValidity(state === InputStateEnum.Valid ? '' : 'invalid')
        setValidityState(state)
    }

    /**
     * User has selected something
     * @param e
     * @param params
     */
    const onChangeHandler = (e: FormEvent<HTMLElement>, params: ChangeEvent) => {
        if (e.currentTarget!.tagName === 'LI') {
            // user has selected an exact match so make valid
            setValidState(InputStateEnum.Valid)
            // update state to trigger useEffect to update the calling component
            setTypedValue(params.newValue)
        } else {
            // user is typing something so mark invalid
            setValidState(InputStateEnum.Invalid)
        }
        setCurrentValue(params.newValue)
    }

    const onSuggestionsClearRequested = () => setSuggestions([])
    const getSuggestionValue = (suggestion: Station): string => suggestion.code
    const renderSuggestion = (suggestion: Station) => <span>{suggestion.description}</span>

    const inputProps: InputProps<Station> = {
        id: props.id,
        name: props.name,
        disabled: props.disabled,
        value: currentValue,
        placeholder: props.placeholder,
        onChange: onChangeHandler,
        className: 'form-control',
    }

    /**
     * Customize the input element to include optional validation feedback, etc.
     * @param inputProps
     * @returns
     */
    const renderInputComponent: RenderInputComponent = (inputProps2: RenderInputComponentProps): React.ReactNode => {
        // mimicking bootstrap border colors
        let borderColor = 'rgb(206, 212, 218)'
        const boxShadow = 'none' // avoids mixing in wrong bootstrap box shadow (glowing outline)
        if (validityState === InputStateEnum.Invalid) {
            borderColor = '#dc3545'
        } else if (validityState === InputStateEnum.Valid) {
            borderColor = '#28a745'
        }
        return (
            <>
                <input {...inputProps2} style={{ borderColor, boxShadow }} />
                {props.isInvalidMessage && <div className="invalid-feedback">{props.isInvalidMessage}</div>}
            </>
        )
    }

    const overriddenTheme = { ...theme }

    // if theme overrides are provided, use them.  Those are basically names of css classes
    // other than the defaults.  Haven't found any other way to override specific styles (like width)
    if (props.themeOverrides) {
        if (props.themeOverrides.container) {
            overriddenTheme.container = theme[props.themeOverrides.container]
        }
        if (props.themeOverrides.suggestion) {
            overriddenTheme.suggestion = theme[props.themeOverrides.suggestion]
        }
        if (props.themeOverrides.suggestionHighlighted) {
            overriddenTheme.suggestionHighlighted = theme[props.themeOverrides.suggestionHighlighted]
        }
        if (props.themeOverrides.suggestionsContainer) {
            overriddenTheme.suggestionsContainer = theme[props.themeOverrides.suggestionsContainer]
        }
        if (props.themeOverrides.suggestionsContainerOpen) {
            overriddenTheme.suggestionsContainerOpen = theme[props.themeOverrides.suggestionsContainerOpen]
        }
    }

    return (
        <Autosuggest
            ref={autoSuggestRef}
            theme={overriddenTheme}
            renderInputComponent={renderInputComponent}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            focusInputOnSuggestionClick={false}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    )
}

export default StationAutoSuggestInput
