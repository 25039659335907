import SFApi from '../api/sfApi'

const setApi = (api: SFApi) => {
    const w = window as any
    w.sfapi = api
}

const getApi = (): SFApi => {
    return (window as any).sfapi as SFApi
}

export default { setApi, getApi }
