import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import {
    fetchAndSetTemplateFromId,
    saveTemplateAndUpdatedProfiles,
    setTemplateFromCopy,
} from 'store/actions/templateActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { TemplateSaveOptions } from 'types/TemplateDetail'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ProfilesSelectorPanel from '../Profiles/ProfilesSelectorPanel'
import TemplateSaveConfirmationDialog from './TemplateSaveConfirmationDialog'

const TemplateDetailPage = () => {
    const { id, operationMode } = useDetailPageParams()
    const dispatch = useAppDispatch()
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const navigate = useNavigate()
    const template = useAppSelector((rs) => rs.profile.template)
    const templateChanged = useAppSelector((rs) => rs.profile.templateModified)

    // Set the profile state based on the operation mode
    useEffect(() => {
        const init = async () => {
            try {
                if (operationMode === 'Copy') {
                    await dispatch(setTemplateFromCopy(id))
                } else {
                    await dispatch(fetchAndSetTemplateFromId(operationMode === 'Create' ? 'new' : id))
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        init()
    }, [dispatch, id, operationMode])

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        if (!template) {
            throw Error('Template is not defined')
        }
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            return
        }

        if (operationMode === 'Create') {
            try {
                await globals.getApi().getTemplateApi().createTemplate(template)
            } catch (error: any) {
                toast.error(error.message)
                return
            }
            toast.success('Template has been Created.')
            navigate('/settings/templates')
        } else {
            setShowConfirm(true)
        }
    }

    const handleModifyConfirmResult = async (dialogResult: DialogResultEnum, mode?: TemplateSaveOptions) => {
        if (!template) {
            return
        }
        if (dialogResult === DialogResultEnum.Completed) {
            try {
                await dispatch(saveTemplateAndUpdatedProfiles(mode!))
            } catch (error: any) {
                toast.error(error.message)
                return
            }
            if (mode === 'save') {
                toast.success('Template has been Updated.')
            } else {
                toast.success('Template has been Created.')
            }

            navigate('/settings/templates')
        }
        setShowConfirm(false)
    }

    const title = operationMode === 'Create' ? 'Add Template' : 'Edit Template'
    const footer = <FormFooter disabledSave={!templateChanged} onCancel={() => navigate('/settings/templates')} />

    if (!template) {
        return <></>
    }

    const updateTemplate = (e: FormControlChangeEvent) => {
        dispatch(profileActions.setTemplate({ template: updateObjectWithFormEvent(template, e), modified: true }))
    }

    return (
        <>
            <FormPage
                headingContent={title}
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <FormSection title="Details">
                    <InputTextFormRow
                        labelText="Name"
                        fieldId="name"
                        subText="A unique name for this template"
                        value={template.name}
                        onChange={updateTemplate}
                        required
                        invalidText="Enter a unique name"
                    />
                    <InputTextAreaFormRow
                        labelText="Description"
                        fieldId="description"
                        subText="A description for this template"
                        value={template.description}
                        onChange={updateTemplate}
                    />
                </FormSection>

                <ProfilesSelectorPanel />
            </FormPage>

            {showConfirm && <TemplateSaveConfirmationDialog closeCallback={handleModifyConfirmResult} />}
        </>
    )
}

export default TemplateDetailPage
