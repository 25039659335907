import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputFileFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface DataSourceS3RusProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
}
const DataSourceS3Rus = (props: DataSourceS3RusProps) => {
    return (
        <InputFileFormRow
            labelText="S3RUS Pairings File"
            fieldId="dataFieldPath1"
            subText="Local path to a pairings file (.txt)"
            value={props.profile.dataFieldPath1}
            onChange={props.onChange}
            disabled={true} // Temporarily disabled
        />
    )
}

export default DataSourceS3Rus
