import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { useMemo } from 'react'
import { BaseInputProps } from './FormControlBase'

export interface FormControlTimePickerProps extends BaseInputProps {
    value?: Date
    onChange?: (e: any) => void
}

const FormControlTimePicker = (props: FormControlTimePickerProps) => {
    const getTimeValue = useMemo(() => {
        if (props.value) {
            const newDate = new Date(props.value)
            return new Date(`2000-01-01 ${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:00`)
        }
        return new Date('2000-01-01 00:00:00')
    }, [props.value])

    const steps = {
        hour: 1,
        minute: 15,
    }

    const handleChange = (event: TimePickerChangeEvent) => {
        if (event.value) {
            const newDate = new Date(Date.UTC(2000, 0, 1, event.value.getHours(), event.value.getMinutes(), 0))
            props.onChange?.({ target: { name: props.id, value: newDate } })
        }
    }
    return (
        <TimePicker
            format="HH:mm"
            steps={steps}
            onChange={handleChange}
            value={getTimeValue}
            id={props.id}
            name={props.id}
        />
    )
}

export default FormControlTimePicker
