import { Link } from 'react-router-dom'
import { HazardClass } from 'types/HazardClass'
import DashboardThumbnailControlButtons from 'views/Common/Buttons/DashboardThumbnailControlButtons'
import HazardClassPanel from './HazardClassPanel'
import classes from './InsightsDashboardThumbnail.module.css'

const InsightsDashboardThumbnail = ({
    isRefreshing,
    hazardClass,
    isDraggingHazardClassId,
    onEditClick,
    onCopyClick,
    onDeleteClick,
    onRefreshClick,
}: {
    isRefreshing: boolean
    hazardClass: HazardClass
    isDraggingHazardClassId: number
    onEditClick: () => void
    onCopyClick: () => void
    onDeleteClick: () => void
    onRefreshClick: () => void
}) => {
    return (
        <Link
            to={`/insights/${hazardClass.id}`}
            className="reportsInsightsDashboardThumbnail mb-2 "
            style={{
                pointerEvents: isDraggingHazardClassId === hazardClass.id ? 'none' : 'all',
                opacity: isDraggingHazardClassId === hazardClass.id ? '0.9' : '1',
                position: 'relative',
            }}
        >
            <div className={classes.container}>
                <DashboardThumbnailControlButtons
                    isRefreshing={isRefreshing}
                    onClickBeginRefresh={onRefreshClick}
                    itemType="Insights"
                    showRefreshButton={hazardClass.requiresRecalculation}
                    showOverrideInfoButton={hazardClass.overrideDashboardScenarioSelection}
                    onEditClick={onEditClick}
                    onCopyClick={onCopyClick}
                    onDeleteClick={onDeleteClick}
                />
                <p className={classes.title}>{hazardClass.name}</p>
                <HazardClassPanel hazardClass={hazardClass} />
            </div>
        </Link>
    )
}

export default InsightsDashboardThumbnail
