import Axios from 'services/axios/axios-sfc'
import TemplateDetail from 'types/TemplateDetail'
import { NIL } from 'uuid'
import SFApiBase from './sfApiBase'

class SFTemplateApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    getTemplateList = async (): Promise<Array<TemplateDetail>> =>
        (await this.axios.get(`${this.baseUrl}/Api/Template`, this.getConfig())).data as Array<TemplateDetail>

    getTemplate = async (id: string): Promise<TemplateDetail> => {
        return (await this.axios.get(this.baseUrl + `/Api/Template/${id}`, this.getConfig())).data as TemplateDetail
    }

    createTemplate = async (template: TemplateDetail) => {
        const templateForCreation = { ...template, id: NIL }
        await this.axios.post(this.baseUrl + '/Api/Template', templateForCreation, this.getConfig())
    }

    updateTemplate = async (template: TemplateDetail) => {
        await this.axios.put(this.baseUrl + '/Api/Template', template, this.getConfig())
    }

    copyTemplate = async (id: string): Promise<TemplateDetail> => {
        return (await this.axios.get(this.baseUrl + `/Api/Template/Copy/${id}`, this.getConfig()))
            .data as TemplateDetail
    }

    deleteTemplate = async (templateIds: string[]) => {
        try {
            await this.axios.delete(this.baseUrl + '/Api/Template', templateIds, this.getConfig())
        } catch (e: any) {
            const error = JSON.parse(e.message)
            throw error.description
        }
    }
}

export default SFTemplateApi
