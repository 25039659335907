import { useState } from 'react'
import { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'

const useKendoGridSelectionState = () => {
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)
    return [selectedRowsState, setSelectedRowsState, selectedIds] as const
}

export default useKendoGridSelectionState
