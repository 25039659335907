import { GridCellProps } from '@progress/kendo-react-grid'
import { ChangeEvent } from 'react'
import { getRecordKeyValues, getRecordValue } from 'types/EnumTypes'
import FormControlSelectInput from 'views/Common/Form/FormControls/FormControlSelectInput'

interface GridDropDownCellProps extends GridCellProps {
    type: {
        [key in string]: string
    }
    enterEdit?: (dataItem: any, field: string | undefined) => void
    editField?: string
}

function GridDropDownCell(props: GridDropDownCellProps) {
    const dataItem = props.dataItem
    const cellField = props.field || ''
    const inEditField = dataItem[props.editField || '']
    const dataValue = dataItem[cellField] ?? ''

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        props.onChange?.({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e,
            value: e.target.value,
        })
    }

    return cellField && cellField === inEditField ? (
        <td>
            <FormControlSelectInput
                value={dataValue}
                options={getRecordKeyValues(props.type)}
                onChange={handleChange}
            />
        </td>
    ) : (
        <td onClick={() => props.enterEdit?.(dataItem, cellField)}>{getRecordValue(props.type, dataValue)}</td>
    )
}

/**
 * Reduce a data object array to an object to work with the dropdown.
 */
export const convertToDropdownDataFormat = (data: { id: string; name: string }[]) => {
    return data.reduce((acc, curr) => {
        acc[curr.id] = curr.name
        return acc
    }, {} as { [key: string]: string })
}

export default GridDropDownCell
